import React from "react";
import Login from "../components/auth/Login";
import { useTheme } from "@mui/material";

const LoginPage = ({ darkMode }) => {
  const theme = useTheme();
  return (
    <div>
      <Login />
    </div>
  );
};

export default LoginPage;
