import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useTheme,
  TableSortLabel,
  useMediaQuery,
  CircularProgress,
  Skeleton,
  Grid,
  Card,
  CardContent,
  Tooltip
} from "@mui/material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { formatDistanceToNow } from 'date-fns';
import { People, TrendingUp, CalendarToday, PersonAdd } from '@mui/icons-material';
import { AreaChart, Area, ResponsiveContainer, LineChart, Line, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';

const Analytics = ({ darkMode }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { apiCall } = useApiWithAuth();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('last_active_time');
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    activeUsers: 0,
    newUsersThisMonth: 0,
    averageLoginFrequency: 0,
    peakActivityTime: ''
  });
  const [userActivityData, setUserActivityData] = useState({
    dailyActive: [],
    userTypes: [],
    growthTrend: [],
    loginFrequency: [],
    activeData: []
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch both users and stats data concurrently
        const [usersData, statsData] = await Promise.all([
          apiCall('get', '/users'),
          apiCall('get', '/users/stats')
        ]);
        
        setRows(usersData);
        setStats(statsData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiCall]);

  useEffect(() => {
    const processUserData = () => {
      // Process user types for donut chart
      const userTypes = [
        { name: 'Active Users', value: stats.activeUsers, color: 'emerald' },
        { name: 'Inactive Users', value: stats.totalUsers - stats.activeUsers, color: 'rose' }
      ];

      // Create growth trend data (last 30 days)
      const today = new Date();
      const last30Days = Array.from({length: 30}, (_, i) => {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        return date.toISOString().split('T')[0];
      }).reverse();

      const dailyCounts = last30Days.map(date => {
        const count = rows.filter(user => 
          user.created_at.split('T')[0] === date
        ).length;
        console.log('Daily Counts Data:', { date: date, "New Users": count });
        return {
          date: date,
          "New Users": count
        };
      });

      // Add login frequency data (example data - adjust based on your actual data)
      const loginFrequency = Array.from({length: 7}, (_, i) => {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        return {
          date: date.toISOString().split('T')[0],
          "Logins": rows.filter(user => 
            user.last_active_time.split('T')[0] === date.toISOString().split('T')[0]
          ).length
        };
      }).reverse();

      // Add active vs inactive ratio
      const activeData = [
        { name: 'Active', value: stats.activeUsers },
        { name: 'Inactive', value: stats.totalUsers - stats.activeUsers }
      ];

      setUserActivityData({
        userTypes,
        growthTrend: dailyCounts,
        loginFrequency,
        activeData
      });
    };

    if (rows.length > 0) {
      processUserData();
    }
  }, [rows, stats]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTimeAgo = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = rows.sort((a, b) => {
    if (orderBy === 'name') {
      return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    } else {
      const dateA = new Date(a[orderBy]);
      const dateB = new Date(b[orderBy]);
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    }
  });

  const skeletonRows = Array.from(new Array(rowsPerPage));

  const StatCard = ({ title, value, icon, tooltip, chartType, chartData }) => {
    const renderChart = () => {
      console.log('Chart Type:', chartType);
      console.log('Chart Data:', chartData);

      if (!chartData || chartData.length === 0) {
        console.log('No chart data available');
        return null;
      }

      const commonChartProps = {
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        height: 50
      };

      switch (chartType) {
        case 'line':
          // Determine the correct dataKey based on the data structure
          const dataKey = chartData[0]?.hasOwnProperty('Logins') ? 'Logins' : 'New Users';
          
          return (
            <ResponsiveContainer width="100%" height={50}>
              <LineChart data={chartData} margin={commonChartProps.margin}>
                <Line
                  type="monotone"
                  dataKey={dataKey}
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                  isAnimationActive={true}
                />
              </LineChart>
            </ResponsiveContainer>
          );

        case 'area':
          return (
            <ResponsiveContainer width="100%" height={50}>
              <AreaChart data={chartData} margin={commonChartProps.margin}>
                <Area
                  type="monotone"
                  dataKey="New Users"
                  stroke={theme.palette.primary.main}
                  fill={theme.palette.primary.light}
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          );

        case 'pie':
          return (
            <ResponsiveContainer width="100%" height={50}>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={15}
                  outerRadius={25}
                >
                  {chartData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`}
                      fill={index === 0 ? theme.palette.success.main : theme.palette.error.light}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          );

        default:
          return null;
      }
    };

    return (
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ width: '100%' }}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                {title}
              </Typography>
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}>
                {loading ? (
                  <Skeleton width={60} />
                ) : (
                  <>
                    {value.toString().split('/')[0]}
                    {value.toString().includes('/') && (
                      <>
                        <Box 
                          component="span" 
                          sx={{ 
                            fontSize: '1.25rem', 
                            color: 'text.secondary',
                            marginTop: '4px'
                          }}
                        >
                          /{value.toString().split('/')[1]}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Box sx={{ width: '100%', mt: 2, minHeight: 50 }}>
                {renderChart()}
              </Box>
            </Box>
            <Tooltip title={tooltip}>
              <Box 
                sx={{ 
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: theme.palette.primary.main,
                  zIndex: 1  // Ensure icon stays on top
                }}
              >
                {icon}
              </Box>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box className="fade-in p-4 pt-20 pb-20" sx={{ backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg" disableGutters>
        {/* Stats Grid */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
          Summary Analytics
        </Typography>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Active Users"
              value={`${stats.activeUsers}/${stats.totalUsers}`}
              icon={<TrendingUp fontSize="large" />}
              tooltip="Users active in the last 30 days"
              chartType="line"
              chartData={userActivityData.loginFrequency}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="New Users This Month"
              value={`${stats.newUsersThisMonth}/${stats.totalUsers}`}
              icon={<PersonAdd fontSize="large" />}
              tooltip="Users who joined this month"
              chartType="line"
              chartData={userActivityData.growthTrend}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title="Avg. Login Frequency"
              value={`${stats.averageLoginFrequency}/month`}
              icon={<CalendarToday fontSize="large" />}
              tooltip="Average logins per user per month"
              chartType="line"
              chartData={userActivityData.loginFrequency}
            />
          </Grid>
        </Grid>

        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
          User Activity Log
        </Typography>

        {/* Table Container */}
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>

                {!isSmallScreen ? (
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    <TableSortLabel
                      active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={(event) => handleRequestSort(event, 'email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                ) : null}

                <TableCell sx={{ fontWeight: 'bold' }}>
                  <TableSortLabel
                    active={orderBy === 'last_active_time'}
                    direction={orderBy === 'last_active_time' ? order : 'asc'}
                    onClick={(event) => handleRequestSort(event, 'last_active_time')}
                  >
                    Last Login
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? skeletonRows.map((_, index) => (
                    <TableRow key={index}>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      {!isSmallScreen ? <TableCell><Skeleton variant="text" /></TableCell> : null}
                      <TableCell><Skeleton variant="text" /></TableCell>
                    </TableRow>
                  ))
                : sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      {!isSmallScreen ? <TableCell>{row.email}</TableCell> : null}
                      <TableCell>{formatTimeAgo(row.last_active_time)}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: theme.palette.text.primary,
          }}
        />

        

        
      </Container>
    </Box>
  );
};

export default Analytics;