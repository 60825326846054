import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Divider,
  TablePagination,
  useTheme,
  Grid,
  useMediaQuery,
  Chip,
  Button,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  ArrowForward as ArrowForwardIcon,
  AccessTime as AccessTimeIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import historyData from "../data/historyData";

const columns = [
  { id: "name", label: "Task Name", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "completionDate", label: "Completion Date", minWidth: 170 },
  { id: "duration", label: "Duration", minWidth: 170 },
];

const statusColors = {
  Completed: "success",
  "In Progress": "primary",
  Pending: "warning",
  Failed: "error",
};

const borderColor = {
  Completed: "#4caf50",
  "In Progress": "#3f51b5",
  Pending: "#ff9800",
  Failed: "#f44336",
};

const History = ({ darkMode }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15); // Set default rows per page to 15
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("completionDate");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 0); // Simulate loading dela
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (id) => {
    navigate(`/taskdetail/${id}`);
  };

  const sortedData = [...historyData].sort((a, b) => {
    if (orderBy === "completionDate") {
      return order === "desc"
        ? new Date(b[orderBy]) - new Date(a[orderBy])
        : new Date(a[orderBy]) - new Date(b[orderBy]);
    }
    return order === "desc"
      ? b[orderBy].localeCompare(a[orderBy])
      : a[orderBy].localeCompare(b[orderBy]);
  });

  const statusSummary = [
    { label: "Completed", value: "100" },
    { label: "Running", value: "5" },
    { label: "Paused", value: "10" },
    { label: "Failed", value: "2" },
  ];

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Grid container spacing={2} className="mb-4">
          {statusSummary.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <Paper
                elevation={0}
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: "8px",
                  boxShadow: "none",
                }}
              >
                <Box sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
                  <Box className="flex flex-col items-center">
                    <Typography variant="overline" className="font-medium">
                      {item.label}
                    </Typography>
                    <Typography variant="h3" className="font-bold">
                      {item.value}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Divider className="my-5" />

        {isSmallScreen ? (
          <Grid container spacing={2} className="custom-scrollbar">
            {loading
              ? Array.from(new Array(5)).map((_, index) => (
                  <Grid item xs={12} key={index}>
                    <Skeleton variant="rectangular" height={118} />
                  </Grid>
                ))
              : sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((task) => (
                    <Grid item xs={12} key={task.id}>
                      <Paper
                        onClick={() => handleRowClick(task.id)}
                        style={{
                          borderLeft: `6px solid ${borderColor[task.status]}`,
                        }}
                      >
                        <Box sx={{ padding: 2 }}>
                          <Typography variant="h6">{task.name}</Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                color: theme.palette.text.secondary,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AccessTimeIcon
                                fontSize="small"
                                style={{ marginRight: "5px" }}
                              />
                              {task.duration}
                            </Typography>
                            {task.status !== "In Progress" &&
                              task.status !== "Pending" && (
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: theme.palette.text.secondary,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <EventIcon
                                    fontSize="small"
                                    style={{ marginRight: "5px" }}
                                  />
                                  {task.completionDate}
                                </Typography>
                              )}
                          </div>
                          {task.status === "In Progress" && (
                            <LinearProgress
                              color="primary"
                              style={{
                                marginTop: "10px",
                                height: "6px",
                                borderRadius: "3px",
                              }}
                            />
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
          </Grid>
        ) : (
          <div
            sx={{ width: "100%", marginBottom: "50px", paddingTop: "20px" }}
            className="custom-scrollbar"
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                      sortDirection={orderBy === column.id ? order : false}
                      onClick={handleSort(column.id)}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from(new Array(10)).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={5}>
                          <Skeleton variant="rectangular" height={40} />
                        </TableCell>
                      </TableRow>
                    ))
                  : sortedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((task) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={task.id}
                          onClick={() => handleRowClick(task.id)}
                          sx={{
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? "white"
                                : "transparent",
                          }}
                        >
                          {columns.map((column) => {
                            const value = task[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  backgroundColor:
                                    theme.palette.mode === "light"
                                      ? "white"
                                      : "transparent",
                                  borderLeft:
                                    column.id === "name"
                                      ? `4px solid ${theme.palette[statusColors[task.status]].main}`
                                      : "none",
                                  paddingLeft:
                                    column.id === "name" ? "8px" : "inherit",
                                }}
                              >
                                {column.id === "actions" ? (
                                  <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    endIcon={<ArrowForwardIcon />}
                                  >
                                    View Task
                                  </Button>
                                ) : column.id === "status" ? (
                                  <Chip
                                    label={value}
                                    color={statusColors[value]}
                                  />
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{
                color: theme.palette.mode === "dark" ? "white" : "black",
              }}
              rowsPerPageOptions={[10, 15, 25, 100]}
              component="div"
              count={historyData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Container>
    </Box>
  );
};

export default History;
