import React from "react";
import { Button, Box, Typography, Container, Paper } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest, sharePointRequest } from "../../msalConfig";
import { ReactComponent as Logo } from "../../assets/images/nucleus-icon.svg";
import LogoutButton from "../common/LogoutButton";

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      // Perform the initial login
      const loginResponse = await instance.loginPopup(loginRequest);
      console.log("Login response", loginResponse);
  
      const email = loginResponse.account.username;
      const entra_id = loginResponse.account.idTokenClaims.oid;
  
      // Create or update user in your backend
      await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/create/${loginResponse.account.username}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: loginResponse.account.name,
            entra_id: entra_id,
            email: email
          }),
        },
      );
  
      // Acquire SharePoint token
      try {
        const sharePointResponse = await instance.acquireTokenSilent({
          ...sharePointRequest,
          account: loginResponse.account
        });
        console.log("SharePoint token acquired silently", sharePointResponse);
        // You can now use sharePointResponse.accessToken for SharePoint API calls
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // If silent token acquisition fails, fall back to interactive method
          const sharePointResponse = await instance.acquireTokenPopup(sharePointRequest);
          console.log("SharePoint token acquired interactively", sharePointResponse);
          // You can now use sharePointResponse.accessToken for SharePoint API calls
        } else {
          throw error;
        }
      }
  
    } catch (error) {
      console.error("Login error:", error);
      // Handle login error (show user-friendly message, etc.)
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgb(30, 30, 30)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "30%", // Use half the height for vertical centering
        }}
      >
        <Logo
          style={{
            width: "60px",
            height: "60px",
            fill: "#FF9800", // Primary theme color (orange)
          }}
        />
        <Typography variant="h6" component="div" sx={{ color: "#FFFFFF" }}>
          NUCLEUS{" "}
          <span style={{ color: "#FF9800", fontWeight: "bold" }}>AI</span>
        </Typography>
      </Box>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Paper
          elevation={10}
          sx={{
            padding: 6,
            borderRadius: 3,
            width: "90%",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            Welcome back!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            size="large"
            sx={{
              mt: 4,
              paddingLeft: 4, // Adjust the padding values as needed
              paddingRight: 4,
              paddingTop: 1, // Optional: adjust the vertical padding
              paddingBottom: 1, // Optional: adjust the vertical padding
              fontSize: "1rem", // Optional: adjust the font size
            }}
          >
            Sign in with SSO
          </Button>
        </Paper>
      </Container>
      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: "rgba(255, 255, 255, 0.5)" }}
        >
          Nucleus AI, a Fusion92 product.
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <LogoutButton /> {/* Add the LogoutButton here */}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
