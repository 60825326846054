import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import SourceListItem from "./SourceListItem";
import { useApiWithAuth } from "@/hooks/useApiWithAuth";

const CollapsibleSourceList = ({
  sources,
  selectedItem,
  lastSyncedAt,
  pagesSynced,
  setSources,
  setError,
  itemType,
}) => {
  const [groupedSources, setGroupedSources] = useState({});
  const [openStates, setOpenStates] = useState({});
  const [isDeletingGroup, setIsDeletingGroup] = useState({});
  const { apiCall } = useApiWithAuth();

  useEffect(() => {
    const grouped = sources.reduce((acc, source) => {
      const siteName = source.rag_sources.site_name || "Other Sources";
      if (!acc[siteName]) {
        acc[siteName] = [];
      }
      acc[siteName].push(source);
      return acc;
    }, {});
    setGroupedSources(grouped);

    // Initialize open states
    const initialOpenStates = Object.keys(grouped).reduce((acc, siteName) => {
      acc[siteName] = true; // Set to true to have them open by default
      return acc;
    }, {});
    setOpenStates(initialOpenStates);
  }, [sources]);

  const handleToggle = (siteName) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [siteName]: !prevState[siteName],
    }));
  };
  const handleDeleteGroup = async (siteName, siteSources) => {
    setIsDeletingGroup((prev) => ({ ...prev, [siteName]: true }));

    try {
      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/batch-delete-sources`,
        {
          sources: siteSources.map((source) => ({
            id: source.id,
            sourceId: source.source_id,
            assistantId: source.assistant_id,
          })),
        },
      );

      if (response.success) {
        setSources((prev) =>
          prev.filter(
            (s) =>
              !siteSources.some((source) => source.source_id === s.source_id),
          ),
        );
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error(`Error deleting sources in ${siteName}:`, error);
      setError(`Error deleting sources in ${siteName}`);
    } finally {
      setIsDeletingGroup((prev) => ({ ...prev, [siteName]: false }));
    }
  };
  return (
    <List className="fade-in">
      {Object.entries(groupedSources).map(([siteName, siteSources]) => (
        <React.Fragment key={siteName}>
          <ListItem>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <IconButton onClick={() => handleToggle(siteName)} size="small">
                {openStates[siteName] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">{siteName}</Typography>
                }
                secondary={`${siteSources.length} item${siteSources.length !== 1 ? "s" : ""}`}
                sx={{ flex: 1 }}
              />
              <IconButton
                edge="end"
                aria-label="delete group"
                onClick={() => handleDeleteGroup(siteName, siteSources)}
                disabled={isDeletingGroup[siteName]}
                size="small"
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          </ListItem>
          <Collapse in={openStates[siteName]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {siteSources.map((source, index) => (
                <ListItem key={index} sx={{ pl: 4 }}>
                  <FolderIcon sx={{ mr: 2 }} />
                  <SourceListItem
                    source={source}
                    selectedItem={selectedItem}
                    lastSyncedAt={lastSyncedAt}
                    pagesSynced={pagesSynced}
                    setSources={setSources}
                    setError={setError}
                    itemType={itemType}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

export default CollapsibleSourceList;
