// src/components/ThemeContext.js
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useCustomTheme from '../hooks/useCustomTheme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { theme, handleThemeChange, darkMode } = useCustomTheme();

  return (
    <ThemeContext.Provider value={{ darkMode, handleThemeChange, theme }}>
      <MuiThemeProvider theme={theme}>
          {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useThemeContext = () => useContext(ThemeContext);
