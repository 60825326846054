import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import { useAuth } from "@context/AuthContext";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import { useMediaQuery } from "@mui/material";

const InviteUsersDialog = ({
  open,
  onClose,
  chatId,
  clientId,
  chatOwnerEmail,
  darkMode,
  fetchChats,
}) => {
  const [emails, setEmails] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const { acquireToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const { apiCall } = useApiWithAuth();
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [chatMembers, setChatMembers] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleShare = async () => {
    setIsSubmitting(true); // Disable button and show loader
    try {
      const invites = await apiCall(
        "post",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/invite/users`,
        {
          chatId: chatId,
          expiresIn: "8h",
          clientId: clientId,
          permission: "engage",
          userEmails: emails,
          chatOwnerEmail: chatOwnerEmail,
        },
      );
      setFeedbackMessage(invites.message || "Invitations sent successfully");
      setShowFeedback(true);
      setEmails([]);
      fetchChatMembers();
      fetchChats();
    } catch (error) {
      setFeedbackMessage("Failed to send invitations. Please try again.");
      setShowFeedback(true);
    } finally {
      setIsSubmitting(false); // Re-enable button after operation
    }
  };

  const fetchChatMembers = async () => {
    try {
      const data = await apiCall(
        "get",
        `${process.env.REACT_APP_API_URL}/api/chats/members/${chatId}`,
      );
      console.log("Data received from API call:", data);

      setChatMembers(data);
    } catch (error) {
      console.error("Error fetching chat members:", error);
    }
  };

  useEffect(() => {
    if (chatId) {
      fetchChatMembers();
    }
  }, [chatId]);

  const fetchEmails = useCallback(
    async (query) => {
      if (query.length > 1) {
        try {
          setIsLoading(true);
          console.log("Fetching emails for query:", query);
          const token = await acquireToken(undefined, 'id');
          const response = await fetch(
            `${process.env.REACT_APP_API_MESSAGE_URL}/api/users/find/email?query=${encodeURIComponent(query)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          if (!response.ok) {
            throw new Error("Failed to fetch emails");
          }
          const data = await response.json();
          setEmailOptions(data.map((item) => item.email));
        } catch (error) {
          console.error("Error fetching emails:", error);
          setEmailOptions([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setEmailOptions([]);
      }
    },
    [acquireToken],
  );

  useEffect(() => {
    if (emails.length > 0) {
      const lastEmail = emails[emails.length - 1];
      fetchEmails(lastEmail);
    }
  }, [emails, fetchEmails]);

  const sortedChatMembers = [...chatMembers].sort((a, b) => {
    if (!a.recipient_email || !b.recipient_email) return 0;
    return a.recipient_email.localeCompare(b.recipient_email);
  });

  const handleDeleteMember = async (email) => {
    try {
      setChatMembers((prevChatMembers) =>
        prevChatMembers.filter((member) => member.recipient_email !== email)
      );
  
      await apiCall(
        "delete",
        `${process.env.REACT_APP_API_URL}/api/chats/members/delete/${chatId}`,
        {
          email,
        },
      );
      fetchChats();
    } catch (error) {
      console.error("Error deleting chat member:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ "& .MuiPaper-root": { borderRadius: "6px" } }}
    >
      <DialogTitle sx={{ fontSize: "28px" }}>
        Invite Collaborators
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Typography variant="body2" mt={0} mb={2}>
          Invite others to collaborate on your chat by adding their email
          addresses. Easily manage access and share your chat with team members
          or collaborators.
        </Typography>
        <Box
          mb={2}
          mt={1}
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems={isSmallScreen ? "flex-end" : "center"}
        >
          <Autocomplete
            multiple
            freeSolo
            options={emailOptions}
            value={emails}
            onChange={(event, newValue) => setEmails(newValue)}
            onInputChange={(event, newInputValue) => {
              if (event?.type === "change") {
                fetchEmails(newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email Address"
                placeholder="Type to search emails"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{
              flexGrow: 1,
              mb: isSmallScreen ? 2 : 0,
              marginRight: isSmallScreen ? 0 : 2,
              width: "100%",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={!isSubmitting && <ShareIcon />}
            onClick={handleShare}
            sx={{
              height: "56px",
              minWidth: "120px",
              width: isSmallScreen ? "100%" : "",
              alignSelf: isSmallScreen ? "flex-end" : "center",
            }}
            disabled={isSubmitting} // Disable the button when submitting
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Invite"} {/* Show loader or button text */}
          </Button>
        </Box>

        {chatMembers.length > 0 && (
          <Box mt={2}>
            <hr
              style={{
                width: "100%",
                border: "none",
                borderBottom: darkMode
                  ? "1px dashed rgba(255,255,255,.25)"
                  : "1px dashed rgba(0,0,0,.25)",
                margin: "25px 0 0 0",
              }}
            />
            <Typography variant="h6" mt={2}>
              Current Collaborators
            </Typography>
            <Typography variant="body2" gutterBottom>
              Chat members can view and contribute to this chat.
            </Typography>
            <List sx={{ maxHeight: 200, overflow: "auto" }}>
              {sortedChatMembers.map((member, index) => (
                <ListItem
                  key={index}
                  sx={{
                    transition: "opacity 0.3s ease-in-out",
                    borderTop: darkMode
                      ? "1px dashed rgba(255,255,255,.25)"
                      : "1px dashed rgba(0,0,0,.25)",
                    borderBottom:
                      index === sortedChatMembers.length - 1
                        ? darkMode
                          ? "1px dashed rgba(255,255,255,.25)"
                          : "1px dashed rgba(0,0,0,.25)"
                        : "none",
                  }}
                >
                  <ListItemText
                    sx={{
                      opacity: member.member_type === "invited" ? 0.4 : 1,
                    }}
                    primary={member.recipient_email}
                  />
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => handleDeleteMember(member.recipient_email)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>

      <Snackbar
        open={showFeedback}
        autoHideDuration={6000}
        onClose={() => setShowFeedback(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowFeedback(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default InviteUsersDialog;
