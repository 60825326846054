import React, { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import PostsList from "../components/social/PostsList";

const Social = () => {
  return (
    <Box
      className="fade-in p-0"
      sx={{
        //minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Grid 
          container 
          justifyContent="center" 
          spacing={4} 
          mt={0} 
          pt={0} 
          sx={{
            //height: "100vh",
            //alignContent: "center",
          }}
        >
          <Grid item sx={{ paddingTop:'0px !important' }}>
            <PostsList />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Social;
