import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function InviteLinkHandler() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApiWithAuth();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleInvite = async () => {
      try {
        const { chatId, clientId } = await apiCall(
          "get",
          `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/invite/process?token=${token}`,
        );

        // Redirect to the chat
        navigate(`/chatgpt/${clientId}/chat/${chatId}`);
      } catch (err) {
        setError(err.message);
        setOpen(true); // Open the Snackbar
        setTimeout(() => {
          navigate("/"); // Navigate to home after showing the Snackbar
        }, 3000); // Adjust the timeout as needed
      }
    };

    handleInvite();
  }, [token, navigate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {error ? <div>Error: {error}</div> : <div>Processing invite...</div>}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Center the Snackbar
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default InviteLinkHandler;
