import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Collapse,
  useMediaQuery,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Divider,
  Popover,
  InputLabel,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { styled, useTheme } from "@mui/material/styles";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useUIContext } from "@context/UIContext";
import { useThemeContext } from "@context/ThemeContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useDispatch } from "react-redux";
import { setCurrentClient, clearMessages } from "@reducers/chatgptSlice";
import InviteUsersDialog from "../chatgpt/InviteUsersDialog";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import FolderIcon from "@mui/icons-material/Folder";
import InboxDialog from "./inboxDialog";
import InboxIcon from "./InboxIcon";
import NavIcon from "./NavIcon";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useMsal } from "@azure/msal-react";

const DeleteFolderDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete the folder and all of its contents.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        {/* <Button onClick={() => onConfirm("folder")} color="secondary">
          Delete Folder Only
        </Button> */}
        <Button
          onClick={() => onConfirm("all")}
          color="primary"
          variant="contained"
        >
          Delete Folder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TruncatedListItemText = styled(ListItemText)({
  flex: "0 1 90%",
  padding: "4px 8px",
  "& .MuiListItemText-primary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const drawerWidth = 300;

const RenameModal = ({ open, onClose, onSave, initialName }) => {
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const handleSave = () => {
    if (name.trim()) {
      onSave(name);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{}}>
      <DialogTitle sx={{ fontSize: "18px" }}>Rename</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          autoFocus
          margin="dense"
          label="New Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </DialogContent>
      <DialogActions
        sx={{
          padding: 2,
        }}
      >
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!name.trim()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChatSidebar = () => {
  const { darkMode } = useThemeContext();
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState("");
  const { apiCall } = useApiWithAuth();
  const location = useLocation();
  const [hoveredChatId, setHoveredChatId] = useState(null);
  const [folders, setFolders] = useState([]);
  const [chats, setChats] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const [hoveredFolderId, setHoveredFolderId] = useState(null);
  const [draggedChat, setDraggedChat] = useState(null);
  const chatRefs = useRef({});
  const [hoveredFolderIdForDrop, setHoveredFolderIdForDrop] = useState(null);
  const [chatId, setChatId] = useState(null);
  const {
    setUISettings,
    uiSettings,
    setFolders: setFoldersContext,
  } = useUIContext();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [menuAnchorEls, setMenuAnchorEls] = useState(null);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const theme = useTheme();
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [itemToRename, setItemToRename] = useState(null);
  const [newName, setNewName] = useState("");
  const [showChatOverlay, setShowChatOverlay] = useState(false);
  const [clients, setClients] = useState([]);
  const prevSelectedClientRef = useRef(selectedClient);
  const dispatch = useDispatch();
  const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = useState(false);
  const [shareChatId, setShareChatId] = useState(null);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [chatOwnerEmail, setChatOwnerEmail] = useState(null);
  const { accounts } = useMsal();
  const prevChatIdRef = useRef(chatId);
  const [fetched, setFetched] = useState(false);

  const fetchInvitations = useCallback(async () => {
    try {
      const invitationsData = await apiCall(
        "get",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/invitations`,
      );
      setInvitations(invitationsData);
    } catch (error) {
      console.error("Failed to fetch invitations:", error);
    }
  }, []);

  useEffect(() => {
    fetchInvitations();
  }, []);

  const handleInboxOpen = () => setInboxOpen(true);
  const handleInboxClose = () => setInboxOpen(false);

  const handleInvitationResponse = async (token, accept) => {
    try {
      if (accept) {
        navigate(`/invite/${token}`);
      } else {
        setInvitations(
          invitations.filter((invitation) => invitation.token !== token),
        );
        await apiCall(
          "delete",
          `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/invite/decline?token=${token}`,
        );
      }
      fetchInvitations();
    } catch (error) {
      console.error("Failed to respond to invitation:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiCall("get", "/clients");
        const shownClients = data.filter(
          (client) => client.name !== "CBOE" && client.name !== "BCBSM",
        );
        setClients(shownClients);
        const pathParts = location.pathname.split("/");
        shownClients.forEach((client) => {
          if (client.id === pathParts[2]) {
            dispatch(setCurrentClient(client));
          }
        });
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    setChatId(pathParts[4] || "");
    setSelectedClient(pathParts[2] || "");
  }, [location]);

  const fetchChats = useCallback(async () => {
    if (selectedClient) {
      try {
        const [chatsData, foldersData] = await Promise.all([
          apiCall("get", `/clients/${selectedClient}/chats`),
          apiCall("get", `/folders/${selectedClient}`),
        ]);

        setFetched(true);
        setChats(chatsData);
        setFoldersContext(foldersData);

        clients.forEach((client) => {
          if (client.id === selectedClient) {
            dispatch(setCurrentClient(client));
          }
        });

        setFolders((prevFolders) =>
          foldersData.map((folder) => ({
            ...folder,
            isOpen:
              folder.type === "shared" || folder.type === "tasks"
                ? true
                : prevFolders.find((f) => f.id === folder.id)?.isOpen || false,
          })),
        );
      } catch (error) {
        console.error("Failed to fetch chats or folders:", error);
      }
    }
  }, [selectedClient, apiCall, navigate, chatId]);

  useEffect(() => {
    if (
      prevSelectedClientRef.current !== selectedClient ||
      chatId !== prevChatIdRef.current
    ) {
      prevSelectedClientRef.current = selectedClient;
      prevChatIdRef.current = chatId;
      fetchChats();
    }
  }, [selectedClient, fetchChats, navigate]);

  useEffect(() => {
    const handleDragEnd = () => {
      setDraggedChat(null);
    };

    document.addEventListener("dragend", handleDragEnd);
    return () => {
      document.removeEventListener("dragend", handleDragEnd);
    };
  }, []);

  const handleSearch = useCallback(
    async (e) => {
      const searchTerm = e.target.value;
      if (searchTerm.trim()) {
        const data = await apiCall(
          "get",
          `/chats/search/${selectedClient}/${searchTerm}`,
        );
        setChats(data);
      } else {
        fetchChats();
      }
    },
    [apiCall, selectedClient, fetchChats],
  );

  const handleClientChange = (event) => {
    const newClientId = event.target.value;
    setChats([]);
    setFolders([]);
    setSelectedClient(newClientId);
    navigate(`/chatgpt/${newClientId}/chat`);
  };

  const handleCreateFolder = async () => {
    const newFolder = await apiCall("post", `/folders`, {
      name: "New Folder",
      clientId: selectedClient,
    });
    setFolders([...folders, newFolder]);
    setItemToRename({ id: newFolder.id, isFolder: true, name: newFolder.name });
    setRenameModalOpen(true);
  };

  const handleRenameFolder = async (folderId, newName) => {
    try {
      await apiCall("put", `/folders/${folderId}`, { name: newName });
      setFolders(
        folders.map((folder) =>
          folder.id === folderId ? { ...folder, name: newName } : folder,
        ),
      );
    } catch (error) {
      console.error("Failed to rename folder:", error);
    }
  };

  const handleDeleteFolder = async (folderId, deleteOption) => {
    try {
      if (deleteOption === "all") {
        // Delete folder and all its contents
        await apiCall("delete", `/folders/${folderId}?delete_contents=true`);
        setChats(chats.filter((chat) => chat.folder_id !== folderId));
      } else {
        // Delete only the folder
        await apiCall("delete", `/folders/${folderId}`);
        setChats(
          chats.map((chat) =>
            chat.folder_id === folderId ? { ...chat, folder_id: null } : chat,
          ),
        );
      }
      setFolders(folders.filter((folder) => folder.id !== folderId));
    } catch (error) {
      console.error("Failed to delete folder:", error);
    }
  };

  const handleFolderToggle = (folderId) => {
    setFolders(
      folders.map((folder) =>
        folder.id === folderId ? { ...folder, isOpen: !folder.isOpen } : folder,
      ),
    );
  };

  const handleChatSelect = (chatId) => {
    if (!itemToRename) {
      dispatch(clearMessages());
      navigate(`/chatgpt/${selectedClient}/chat/${chatId}`);
      // if (isSmallScreen) {
      //   setUISettings((prev) => ({ ...prev, chatSidebarOpen: false }));
      // }
    }
  };

  const handleSaveEdit = async (chatId, newName) => {
    await apiCall("put", `/chats/${chatId}`, {
      chat: {
        name: newName,
      },
    });

    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId ? { ...chat, name: newName } : chat,
      ),
    );
  };

  const handleDeleteChat = async (chatId) => {
    await apiCall("delete", `/chats/${chatId}`);
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
  };

  const updateFolder = async (chatId, folderId) => {
    try {
      await apiCall("put", `/chats/${chatId}`, {
        chat: {
          folder_id: folderId,
        },
      });

      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.id === chatId ? { ...chat, folder_id: folderId } : chat,
        ),
      );
    } catch (error) {
      console.error("Failed to update chat folder:", error);
    }
  };

  const handleDragStart = (e, chat) => {
    setDraggedChat(chat);

    const chatElement = chatRefs.current[chat.id];
    if (chatElement) {
      const rect = chatElement.getBoundingClientRect();
      const ghostElement = chatElement.cloneNode(true);

      ghostElement.style.width = `${rect.width}px`;
      ghostElement.style.backgroundColor = "#202123";
      ghostElement.style.position = "absolute";
      ghostElement.style.top = "-1000px";
      ghostElement.style.left = "-1000px";
      ghostElement.style.opacity = "0.8";

      document.body.appendChild(ghostElement);

      e.dataTransfer.setDragImage(ghostElement, 0, 0);

      setTimeout(() => {
        document.body.removeChild(ghostElement);
      }, 0);
    }

    e.dataTransfer.setData("text/plain", chat.id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (
      e.target.closest("[data-folder]") === null &&
      draggedChat &&
      draggedChat.folder_id !== null
    ) {
      setIsDragOver(true);
    } else {
      setIsDragOver(false);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if (e.target.closest("[data-folder]") === null) {
      setIsDragOver(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (
      e.target.closest("[data-folder]") === null &&
      draggedChat &&
      draggedChat.folder_id !== null
    ) {
      updateFolder(draggedChat.id, null);
    }
    setIsDragOver(false);
    setDraggedChat(null);
  };

  const handleFolderDragOver = (e, folderId, folderType) => {
    if (folderType === "tasks" || folderType === "shared") return;
    e.preventDefault();
    e.stopPropagation();
    if (draggedChat && draggedChat.folder_id !== folderId) {
      setHoveredFolderIdForDrop(folderId);
    }
  };

  const handleFolderDragLeave = (e, folderId, folderType) => {
    if (folderType === "tasks" || folderType === "shared") return;
    e.preventDefault();
    e.stopPropagation();
    if (hoveredFolderIdForDrop === folderId) {
      setHoveredFolderIdForDrop(null);
    }
  };

  const handleFolderDrop = (e, folderId, folderType) => {
    if (folderType === "tasks" || folderType === "shared") return;
    e.preventDefault();
    e.stopPropagation();
    if (draggedChat && draggedChat.folder_id !== folderId) {
      updateFolder(draggedChat.id, folderId);
    }
    setDraggedChat(null);
    setHoveredFolderIdForDrop(null);
  };

  const handleMenuOpen = (event, id) => {
    console.log("Menu Open Clicked", {
      id,
      currentTarget: event.currentTarget,
    });
    setMenuAnchorEls(event.currentTarget);
    setMenuOpenId(id);
  };

  const handleMenuClose = () => {
    setMenuAnchorEls(null);
    setMenuOpenId(null);
  };

  const handleMenuRename = (id, isFolder = false) => {
    const item = isFolder
      ? folders.find((f) => f.id === id)
      : chats.find((c) => c.id === id);
    setItemToRename({ id, isFolder, name: item.name });
    setNewName(item.name);
    setRenameModalOpen(true);
    handleMenuClose(id);
  };

  const handleMenuDelete = (id, isFolder = false) => {
    if (isFolder) {
      setFolderToDelete(id);
      setDeleteFolderDialogOpen(true);
    } else {
      handleDeleteChat(id);
    }
    handleMenuClose(id);
    navigate(`/chatgpt/${selectedClient}/chat`);
  };

  const handleDeleteFolderConfirm = (deleteOption) => {
    handleDeleteFolder(folderToDelete, deleteOption);
    setDeleteFolderDialogOpen(false);
    setFolderToDelete(null);
  };

  const handleRenameSave = async (newName) => {
    if (itemToRename && newName.trim()) {
      const { id, isFolder } = itemToRename;
      if (isFolder) {
        await handleRenameFolder(id, newName.trim());
      } else {
        await handleSaveEdit(id, newName.trim());
      }
    }
    closeRenameModal();
  };

  const closeRenameModal = () => {
    setRenameModalOpen(false);
    setItemToRename(null);
    setNewName("");
  };

  const handleOpenShareDialog = (chatId, chatOwnerEmail) => {
    setShareChatId(chatId);
    setInviteUsersDialogOpen(true);
    setChatOwnerEmail(chatOwnerEmail);

    setFolders((prevFolders) =>
      prevFolders.map((folder) =>
        folder.type === "shared"
          ? { ...folder, isOpen: true }
          : { ...folder, isOpen: folder.isOpen },
      ),
    );
  };

  return (
    <>
      <div className="flex h-full">
        <Drawer
          variant="permanent"
          anchor="left"
          open={true}
          sx={{
            width: drawerWidth,
            zIndex: 500,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              marginLeft: isSmallScreen ? "0px" : "0px",
              width: drawerWidth,
              transform: !uiSettings.chatSidebarOpen
                ? `translateX(-300px)`
                : "translateX(60px)",
              transition: `all ${theme.custom.animation.fast} ease-in-out`,
              boxSizing: "border-box",
              userSelect: "none",
              cursor: "default",
              backgroundColor: darkMode ? "#202123" : "#f2f2f2",
              overflowX: "hidden",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#373839",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#1a1a1a",
              },
            },
          }}
        >
          <div className="fade-in">
            <List
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              sx={{
                marginTop: 0,
                height: "100%",
                position: "relative",
                paddingTop: 0,
                ...(isDragOver && {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  transition: `all ${theme.custom.animation.fast} ease-in-out`,
                }),
              }}
            >
              {/* {isSmallScreen && (
              <Button
                onClick={() => {
                  navigate(`/dashboard`);
                }}
                startIcon={<KeyboardArrowLeftIcon />}
                variant="contained"
                size="small"
                color="primary"
                sx={{
                  position: "absolute",
                  justifyContent: "left",
                  borderColor: "secondary",
                  padding: "8px",
                  borderRadius: "4px",
                  right: "10px",
                  top: 0,
                  alignItems: "right",
                  alignSelf: "right",
                  alignContent: "right",
                  textAlign: "right",
                  margin: 0,
                  marginTop: "-50px",
                  marginRight: "6px",
                }}
              >
                Back to Nucleus
              </Button>
              )} */}

              <ListItem
                sx={{
                  mt: 1,
                  justifyContent: isSmallScreen ? "center" : "flex-end",
                }}
                className="fade-in"
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <NavIcon
                    title="New Chat"
                    onClick={() => {
                      navigate(`/chatgpt/${selectedClient}/chat`);
                      setUISettings({ chatSidebarOpen: false });
                    }}
                    icon={<AddCommentIcon />}
                  />
                  <NavIcon
                    title="New Folder"
                    onClick={handleCreateFolder}
                    icon={<CreateNewFolderIcon />}
                  />
                  <NavIcon
                    title="Search"
                    onClick={(event) => {
                      setSearchAnchorEl(event.currentTarget);
                    }}
                    icon={<SearchIcon />}
                  />
                  <InboxIcon
                    invitationCount={invitations.length}
                    onClick={handleInboxOpen}
                  />
                </Box>
              </ListItem>

              <Popover
                id="search-popover"
                anchorEl={searchAnchorEl}
                open={Boolean(searchAnchorEl)}
                onClose={() => setSearchAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: 50,
                }}
                sx={{
                  "& .MuiPopover-paper": {
                    transform: "translateX(-50px)",
                  },
                }}
              >
                <Box sx={{ p: 2 }}>
                  <TextField
                    placeholder="Search chats..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ color: "gray", mr: 1 }} />
                      ),
                    }}
                  />
                </Box>
              </Popover>

              <ListItem sx={{ mt: 1, mb: 1 }} className="fade-in">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select a Chat
                  </InputLabel>
                  <Select
                    value={
                      clients.some((client) => client.id === selectedClient)
                        ? selectedClient
                        : ""
                    }
                    onChange={handleClientChange}
                    inputProps={{ "aria-label": "Select client" }}
                    label="Select a Chat"
                    onClick={(event) => event.stopPropagation()}
                  >
                    {clients?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>

              {!fetched ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Box>
              ) : chats.length > 0 ? (
                <>
                  {folders.map((folder) => {
                    const hasSharedMessages = chats.some(
                      (chat) =>
                        chat.folder_id === folder.id &&
                        folder.type === "shared",
                    );

                    if (folder.type === "shared" && !hasSharedMessages) {
                      return null;
                    }

                    return (
                      <React.Fragment key={folder.id}>
                        <ListItem
                          dense
                          className="fade-in"
                          onDragOver={(e) =>
                            handleFolderDragOver(e, folder.id, folder.type)
                          }
                          onDragLeave={(e) =>
                            handleFolderDragLeave(e, folder.id, folder.type)
                          }
                          onDrop={(e) =>
                            handleFolderDrop(e, folder.id, folder.type)
                          }
                          data-folder={folder.id}
                          onMouseEnter={() => setHoveredFolderId(folder.id)}
                          onMouseLeave={() => setHoveredFolderId(null)}
                          sx={{
                            position: "relative",
                            borderTop: `1px solid`,
                            borderColor: darkMode
                              ? "#293038"
                              : "rgba(0, 0, 0, 0.12)",
                            backgroundColor:
                              hoveredFolderIdForDrop === folder.id
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            transition: `all ${theme.custom.animation.fast} ease-in-out`,
                          }}
                        >
                          {folder.type === "tasks" ? (
                            <FolderSpecialIcon />
                          ) : folder.type === "shared" && hasSharedMessages ? (
                            <FolderSharedIcon />
                          ) : (
                            <FolderIcon />
                          )}

                          <TruncatedListItemText
                            onClick={() => handleFolderToggle(folder.id)}
                            sx={{ minWidth: 18, cursor: "pointer" }}
                            primary={folder.name}
                          />
                          {/* <ListItemIcon
                              onClick={() => handleFolderToggle(folder.id)}
                              sx={{ minWidth: 18, cursor: "pointer" }}
                            >
                              {folder.isOpen ? <ExpandMore /> : <KeyboardArrowRight />}
                            </ListItemIcon> */}

                          <div
                            style={{
                              position: "absolute",
                              right: 8,
                              display: "flex",
                            }}
                          >
                            {(isSmallScreen || hoveredFolderId === folder.id) &&
                              folder.type !== "tasks" &&
                              folder.type !== "shared" && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuOpen(e, folder.id);
                                  }}
                                  size="small"
                                >
                                  <MoreHorizIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                              )}
                            <Menu
                              anchorEl={menuAnchorEls}
                              open={menuOpenId === folder.id}
                              onClose={handleMenuClose}
                              sx={{ width: 200, zIndex: 1300 }}
                              PaperProps={{
                                sx: {
                                  zIndex: 1300,
                                },
                              }}
                            >
                              {folder.type !== "tasks" &&
                                folder.type !== "shared" && (
                                  <MenuItem
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleMenuRename(folder.id, true);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DriveFileRenameOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Rename</ListItemText>
                                  </MenuItem>
                                )}
                              <Divider />
                              <MenuItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMenuDelete(folder.id, true);
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                              </MenuItem>
                            </Menu>
                          </div>
                        </ListItem>
                        <Collapse
                          in={folder.isOpen}
                          timeout={parseInt(theme.custom.animation.fast, 10)}
                          className="fade-in"
                          unmountOnExit
                          data-folder={folder.id}
                          onDragOver={(e) =>
                            handleFolderDragOver(e, folder.id, folder.type)
                          }
                          onDragLeave={(e) =>
                            handleFolderDragLeave(e, folder.id, folder.type)
                          }
                          onDrop={(e) =>
                            handleFolderDrop(e, folder.id, folder.type)
                          }
                          sx={{
                            backgroundColor:
                              hoveredFolderIdForDrop === folder.id
                                ? "rgba(255, 255, 255, 0.1)"
                                : "transparent",
                            transition: `all ${theme.custom.animation.fast} ease-in-out`,
                          }}
                        >
                          <List disablePadding>
                            {chats
                              .filter(
                                (chat) =>
                                  chat.folder_id === folder.id ||
                                  chat.owner_email !== accounts[0].username,
                              )
                              .map((chat) => (
                                <ListItem
                                  key={chat.id}
                                  dense
                                  onMouseEnter={() => setHoveredChatId(chat.id)}
                                  onMouseLeave={() => setHoveredChatId(null)}
                                  sx={{
                                    pl: 4,
                                    borderTop: `1px solid`,
                                    borderColor: darkMode
                                      ? "#293038"
                                      : "rgba(0, 0, 0, 0.12)",
                                    backgroundColor:
                                      chat.id === chatId
                                        ? "rgba(255, 255, 255, 0.1)"
                                        : "transparent",
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.1)",
                                    },
                                  }}
                                  onClick={() => handleChatSelect(chat.id)}
                                  button
                                  draggable={
                                    folder.type !== "tasks" &&
                                    folder.type !== "shared"
                                  }
                                  onDragStart={(e) => handleDragStart(e, chat)}
                                  data-folder={folder.id}
                                  ref={(el) => (chatRefs.current[chat.id] = el)}
                                >
                                  <TruncatedListItemText primary={chat.name} />
                                  {(isSmallScreen ||
                                    hoveredChatId === chat.id) && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: 8,
                                        display: "flex",
                                      }}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleMenuOpen(e, chat.id);
                                        }}
                                        size="small"
                                      >
                                        <MoreHorizIcon sx={{ fontSize: 18 }} />
                                      </IconButton>
                                      {chat.owner_email ===
                                        accounts[0].username && (
                                        <Menu
                                          anchorEl={menuAnchorEls}
                                          open={menuOpenId === chat.id}
                                          onClose={handleMenuClose}
                                          sx={{ width: 200, zIndex: 1300 }}
                                          popoverprops={{
                                            PaperProps: {
                                              sx: {
                                                zIndex: 1300,
                                              },
                                            },
                                          }}
                                        >
                                          <MenuItem
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleOpenShareDialog(
                                                chat.id,
                                                chat.owner_email,
                                              );
                                            }}
                                          >
                                            Collaboration
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleMenuRename(chat.id);
                                            }}
                                          >
                                            Rename
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleMenuDelete(chat.id);
                                            }}
                                          >
                                            Delete
                                          </MenuItem>
                                        </Menu>
                                      )}
                                    </div>
                                  )}
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
                  {chats
                    .filter((chat) => chat.folder_id === null)
                    .map((chat) => (
                      <ListItem
                        key={chat.id}
                        dense
                        className="fade-in"
                        onClick={() => handleChatSelect(chat.id)}
                        onMouseEnter={() => setHoveredChatId(chat.id)}
                        onMouseLeave={() => setHoveredChatId(null)}
                        button
                        sx={{
                          position: "relative",
                          borderTop: `1px solid`,
                          borderColor: darkMode
                            ? "#293038"
                            : "rgba(0, 0, 0, 0.12)",
                          backgroundColor:
                            chat.id === chatId
                              ? "rgba(255, 255, 255, 0.1)"
                              : "transparent",
                          "&:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                        draggable
                        onDragStart={(e) => handleDragStart(e, chat)}
                        ref={(el) => (chatRefs.current[chat.id] = el)}
                      >
                        <TruncatedListItemText primary={chat.name} />
                        {(isSmallScreen || hoveredChatId === chat.id) &&
                          chat.owner_email === accounts[0].username && (
                            <div
                              style={{
                                position: "absolute",
                                right: 8,
                                display: "flex",
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMenuOpen(e, chat.id); // or folder.id for folders
                                }}
                                size="small"
                              >
                                <MoreHorizIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                              <Menu
                                anchorEl={menuAnchorEls}
                                open={menuOpenId === chat.id}
                                onClose={handleMenuClose}
                                sx={{ width: 300 }}
                                popoverprops={{
                                  PaperProps: {
                                    sx: {
                                      zIndex: 1300,
                                    },
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenShareDialog(
                                      chat.id,
                                      chat.owner_email,
                                    );
                                  }}
                                >
                                  Collaboration
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuRename(chat.id);
                                  }}
                                >
                                  Rename
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuDelete(chat.id);
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                      </ListItem>
                    ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="body2">
                    No current chats available
                  </Typography>
                </Box>
              )}
            </List>
          </div>
        </Drawer>
      </div>
      <RenameModal
        open={renameModalOpen}
        onClose={closeRenameModal}
        onSave={handleRenameSave}
        initialName={itemToRename ? itemToRename.name : ""}
      />
      <DeleteFolderDialog
        open={deleteFolderDialogOpen}
        onClose={() => setDeleteFolderDialogOpen(false)}
        onConfirm={handleDeleteFolderConfirm}
      />
      {inviteUsersDialogOpen && (
        <InviteUsersDialog
          open={inviteUsersDialogOpen}
          onClose={() => setInviteUsersDialogOpen(false)}
          chatId={shareChatId}
          clientId={selectedClient}
          chatOwnerEmail={chatOwnerEmail}
          darkMode={darkMode}
          fetchChats={fetchChats}
        />
      )}
      <InboxDialog
        open={inboxOpen}
        onClose={handleInboxClose}
        invitations={invitations}
        onInvitationResponse={handleInvitationResponse}
      />
    </>
  );
};

export default ChatSidebar;
