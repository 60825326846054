import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Dashboard from "../pages/Dashboard";
import Clients from "../pages/Clients";
import History from "../pages/History";
import Actions from "../pages/Actions";
import Agents from "../pages/Agents";
import Analytics from "../pages/Analytics";
import Settings from "../pages/Settings";
import ChatGPT from "../pages/ChatGPT";
import Login from "../pages/Login";
import TaskDetails from "../pages/TaskDetails";
import SubmitIdea from "../pages/SubmitIdea";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import ChatGPTRedirect from "@components/redirects/ChatGPTRedirect";
import Education from "../pages/Education";
import AITools from "../pages/AITools";
import Error from "../pages/Error";
import GPTTest from "../pages/GPTTest";
import AINews from "../pages/AINews";
import InviteWrapper from "@components/wrappers/InviteWrapper";
import Social from "../pages/Social";
import SocialPost from "../pages/SocialPost";
import Tasks from "../pages/Tasks";
import Integrations from "../pages/Integrations";
import Logout from "../pages/Logout";

const AppRoutes = () => {
  const { apiCall } = useApiWithAuth();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/history" element={<History />} />
          <Route path="/actions" element={<Actions />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/chatgpt" element={<ChatGPTRedirect />} />
          <Route path="/chatgpt/:clientId/chat" element={<ChatGPT />} />
          <Route path="/chatgpt/:clientId/chat/:chatId" element={<ChatGPT />} />
          <Route path="/taskdetail/:taskId" element={<TaskDetails />} />
          <Route path="/submit-idea" element={<SubmitIdea />} />
          <Route path="/education" element={<Education />} />
          <Route path="/ai-tools" element={<AITools />} />
          <Route path="/ainews" element={<AINews />} />
          <Route path="/social" element={<Social />} />
          <Route path="/social/post/:postId" element={<SocialPost />} />
          <Route path="/error" element={<Error />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/invite/:token" element={<InviteWrapper />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/gpttest" element={<GPTTest />} />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  );
};

export default AppRoutes;
