import { useState, useEffect, useMemo } from "react";
import { fetchCategories, fetchTasks, fetchTaskList, urlFor } from "../utils/sanityUtils";
import { supabase } from "../supabaseClient";

export function useTasks(selectedCategory) {
  const [tasks, setTasks] = useState([]);
  const [tasksLoaded, setTasksLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //console.log("Fetching tasks...");
        const categoryData = await fetchCategories();
        const categoryMap = categoryData.reduce((map, category) => {
          map[category._id] = category.title;
          return map;
        }, {});

        const taskData = await fetchTasks();
        const taskListData = await fetchTaskList();
        //console.log("Task List Data:", taskListData);

        const { data: averageRatingData, error: avgRatingError } = await supabase
          .from('task_average_ratings')
          .select('task_id, average_rating');
          
        if (avgRatingError) {
          console.error("Error fetching average ratings:", avgRatingError);
        }

        //console.log("Average Rating Data:", averageRatingData);

        const ratingMap = averageRatingData?.reduce((acc, rating) => {
          acc[rating.task_id] = rating.average_rating;
          return acc;
        }, {});
        //console.log("Rating Map:", ratingMap);
        const { data: voteData, error: voteError } = await supabase
          .from('task_vote_counts')
          .select('*')

          //console.log("Vote Data:", voteData);

        if (voteError) {
          console.error("Error fetching vote data:", voteError);
        }
        //console.log("Vote Data:", voteData);
        const voteMap = voteData?.reduce((acc, vote) => {
          acc[vote.task_id] = vote.total_votes;
          return acc;
        }, {});
        //console.log("Vote Map:", voteMap);
        const processedTasks = taskData.map((task) => {
          const categoryRef = task?.category?._ref;
          const categoryTitle = categoryRef ? categoryMap[categoryRef] : "Uncategorized";

          return {
            id: task._id,
            title: task.title,
            description: task.description,
            instructions: task.instructions,
            assistantId: task.assistant,
            creator: task.creator,
            thumbnailUrl: task.thumbnail ? urlFor(task.thumbnail.asset._ref) : null,
            icon: task.myIcon?.name ?? "default-icon",
            prompts: task.prompts || [],
            webhook: task.webhook,
            category: categoryTitle,
            rating: ratingMap?.[task._id] || 0,
            totalVotes: voteMap?.[task._id] || 0,
          };
        });

        const sortedTasks = taskListData[0]?.tasks.map(taskRef => 
          processedTasks.find(task => task.id === taskRef._ref)
        ).filter(task => task);

        setTasks(sortedTasks);
        setTasksLoaded(true);
        console.log("Tasks with Ratings:", sortedTasks);
        //console.log("Tasks with Ratings:", sortedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchData();
  }, []);

  const filteredTasks = useMemo(() => {
    if (!selectedCategory || selectedCategory === "All") {
      return tasks;
    }
    return tasks.filter((task) => task?.category === selectedCategory);
  }, [tasks, selectedCategory]);

  return { tasks: filteredTasks, tasksLoaded };
}
