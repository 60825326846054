import React, { useState, useRef, useCallback, useEffect } from "react";
import { Box, Button, TextField, Typography, CircularProgress, Grid, LinearProgress, Alert } from "@mui/material";
import { supabase } from "../../supabaseClient";
import { useAuth } from "@/context/AuthContext";
import imageCompression from 'browser-image-compression';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';
import { useApiWithAuth } from "@/hooks/useApiWithAuth";
import { useSnackbar } from "@/context/SnackbarContext";

const ffmpeg = new FFmpeg({ log: true });

const UploadPost = ({ user, handleClose, refreshPosts }) => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const { acquireToken } = useAuth();
  const [error, setError] = useState(null);
  const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  const [compressing, setCompressing] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState(0);
  const [cancelling, setCancelling] = useState(false);
  const xhr = useRef(null);
  const MAX_FILE_SIZE = 200 * 1024 * 1024;
  const [ffmpegInstance, setFfmpegInstance] = useState(null);
  const cancelTokenRef = useRef({ isCancelled: false });
  const ffmpegRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [compressionAttempted, setCompressionAttempted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const { apiCall } = useApiWithAuth();
  const { addSnackbar } = useSnackbar();

  const chunkSize = 5 * 1024 * 1024; // 5MB chunks

  const sanitizeFilename = (filename) => {
    return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  };

  const compressImage = async (imageFile, onProgress) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      onProgress: (progress) => {
        onProgress(progress);
      },
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Image compression error:", error);
      throw error;
    }
  };

  const handleFile = useCallback((selectedFile) => {
    console.log(selectedFile);
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        setError(`File size exceeds the limit of ${MAX_FILE_SIZE / (1024 * 1024)}MB. Please choose a smaller file.`);
        setFile(null);
        setFileUrl(null);
        setMediaType("");
      } else {
        setError(null);
        setFile(selectedFile);
        setFileUrl(URL.createObjectURL(selectedFile));
        setMediaType(selectedFile.type.startsWith("video") ? "video" : "image");
      }
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    handleFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && (droppedFile.type.startsWith("image") || droppedFile.type.startsWith("video"))) {
      handleFile(droppedFile);
    }
  }, [handleFile]);

  const compressVideo = async (videoFile) => {
    console.log("Starting video compression for file:", videoFile.name);
    if (cancelTokenRef.current.isCancelled) return null;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      console.log("iOS device detected. Skipping compression.");
      return null;
    }

    try {
      if (!ffmpegRef.current) {
        console.log("FFmpeg not loaded, loading...");
        setCompressing(true);
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
        ffmpegRef.current = new FFmpeg();
        await ffmpegRef.current.load({
          coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
          wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
        });
        console.log("FFmpeg loaded");
      }

      console.log("Compressing video...");
      const inputFileName = videoFile.name;
      const outputFileName = `compressed_${inputFileName.split('.').slice(0, -1).join('.')}.mp4`;

      try {
        console.log(`Starting to write file: ${inputFileName}`);
        await ffmpegRef.current.writeFile(inputFileName, await fetchFile(videoFile));
        console.log(`Video written to FFmpeg: ${inputFileName}`);

        let lastProgress = 0;
        const progressHandler = ({ progress, time }) => {
          if (cancelTokenRef.current.isCancelled) {
            console.log('Cancellation detected during progress update');
            if (ffmpegRef.current) {
              ffmpegRef.current.off('progress', progressHandler);
            }
            return;
          }
          if (progress) {
            const roundedProgress = Math.round(progress * 100);
            if (roundedProgress !== lastProgress) {
              console.log(`Compression progress (from progress event): ${roundedProgress}%`);
              setCompressionProgress(roundedProgress);
              lastProgress = roundedProgress;
            }
          } else if (time) {
            const estimatedDuration = videoFile.duration || 60;
            const estimatedProgress = Math.min((time / estimatedDuration) * 100, 100);
            const roundedProgress = Math.round(estimatedProgress);
            if (roundedProgress !== lastProgress) {
              console.log(`Compression progress (estimated from time): ${roundedProgress}%`);
              setCompressionProgress(roundedProgress);
              lastProgress = roundedProgress;
            }
          }
        };

        ffmpegRef.current.on('progress', progressHandler);

        if (cancelTokenRef.current.isCancelled) return null;

        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const compressionSettings = isIOS 
          ? ['-crf', '28', '-preset', 'veryfast'] 
          : ['-crf', '23', '-preset', 'fast'];

        console.log("Starting FFmpeg execution");
        await ffmpegRef.current.exec([
          '-i', inputFileName,
          '-c:v', 'libx264',
          ...compressionSettings,
          '-c:a', 'aac',
          '-b:a', '128k',
          '-movflags', '+faststart',
          outputFileName
        ]);

        console.log('FFmpeg execution completed');

        if (cancelTokenRef.current.isCancelled) return null;

        console.log(`Starting to read compressed file: ${outputFileName}`);
        const data = await ffmpegRef.current.readFile(outputFileName);
        console.log(`Compressed video file read, size: ${data.byteLength} bytes`);

        const compressedFile = new File([data.buffer], outputFileName, { type: 'video/mp4' });
        console.log(`Compressed File created: ${compressedFile.name}, size: ${compressedFile.size} bytes`);

        return compressedFile;
      } catch (error) {
        console.log("Error during video compression:", error);
        if (error.message === "called FFmpeg.terminate()") {
          console.log("FFmpeg was terminated. Reinitializing...");
          ffmpegRef.current = null;  // Reset the FFmpeg instance
          return null;  // Return null to use the original file
        }
        throw error;  // Rethrow other errors
      } finally {
        if (ffmpegRef.current) {
          ffmpegRef.current.off('progress');
        }
        console.log("Compression process finished");
      }
    } catch (error) {
      console.error("Error during video compression:", error);
      throw error;
    } finally {
      if (ffmpegRef.current) {
        ffmpegRef.current.off('progress');
      }
      console.log("Compression process finished");
    }
  };

  useEffect(() => {
    return () => {
      if (ffmpegRef.current) {
        try {
          ffmpegRef.current.terminate();
        } catch (error) {
          console.log("FFmpeg termination error on unmount:", error);
        }
        ffmpegRef.current = null;
      }
    };
  }, []);

  const cancelOperation = useCallback(() => {
    setCancelling(true);
    cancelTokenRef.current.isCancelled = true;

    if (compressing && ffmpegRef.current) {
      try {
        ffmpegRef.current.off('progress');
        ffmpegRef.current.terminate();
      } catch (error) {
        console.log("FFmpeg termination error:", error);
      }
      ffmpegRef.current = null;
    }

    if (xhr.current) {
      xhr.current.abort();
      xhr.current = null;
    }

    setCompressing(false);
    setLoading(false);
    setIsUploading(false);  // Add this line to reset the uploading state
    setProgress(0);
    setCompressionProgress(0);
    setError(null);

    console.log("Operation cancelled");

    // Remove the setTimeout and directly set cancelling to false
    setCancelling(false);
  }, [compressing]);

  const MAX_SINGLE_UPLOAD_SIZE = 10 * 1024 * 1024; // 10MB
  const MAX_TOTAL_UPLOAD_SIZE = 200 * 1024 * 1024; // 200MB

  const handleUpload = async () => {
    if (!file || !title || !description) {
      setError("Please fill out all fields");
      return;
    }

    if (file.size > MAX_TOTAL_UPLOAD_SIZE) {
      setError(`File size exceeds the maximum limit of ${MAX_TOTAL_UPLOAD_SIZE / (1024 * 1024)} MB. Please choose a smaller file or try compressing it first.`);
      return;
    }

    setError(null);
    setLoading(true);
    setProgress(0);
    setIsUploading(false);
    cancelTokenRef.current.isCancelled = false;

    let fileToUpload = file;
    let compressionAttempted = false;

    try {
      if (mediaType === "video" && !compressionAttempted) {
        setCompressing(true);
        try {
          console.log("Attempting video compression");
          const compressedFile = await compressVideo(file);
          if (cancelTokenRef.current.isCancelled) {
            console.log("Upload cancelled during video compression");
            return;
          }
          if (compressedFile) {
            console.log("Compression successful, using compressed file");
            fileToUpload = compressedFile;
            compressionAttempted = true;
          } else {
            console.log("Compression skipped or failed, using original file");
            fileToUpload = file;
          }
        } catch (err) {
          console.error("Video compression error:", err);
          console.log("Using original video file due to compression error");
          fileToUpload = file;
        } finally {
          setCompressing(false);
        }
      } else if (mediaType === "image" && !compressionAttempted) {
        setCompressing(true);
        try {
          const compressedFile = await compressImage(file, (progress) => {
            setCompressionProgress(progress);
          });
          if (cancelTokenRef.current.isCancelled) {
            console.log("Upload cancelled during image compression");
            return;
          }
          if (compressedFile) {
            fileToUpload = compressedFile;
            setCompressionAttempted(true);
          } else {
            console.warn("Image compression failed. Using original file.");
            fileToUpload = file;  // Explicitly set to original file
          }
        } catch (err) {
          console.error("Image compression error:", err);
          console.warn("Using original image file due to compression error.");
          fileToUpload = file;  // Explicitly set to original file
        } finally {
          setCompressing(false);
        }
      }

      console.log("File to be uploaded:", fileToUpload.name, "Size:", fileToUpload.size);

      let token = await acquireToken(undefined, "id");
      let retryCount = 0;
      const maxRetries = 2;

      while (retryCount <= maxRetries) {
        try {
          setIsUploading(true);
          const response = await uploadInChunks(fileToUpload, token, { 
            title, 
            description, 
            mediaType, 
            email: user.email, 
            profile_image: user.profileImage || '' 
          }, (progress) => {
            setUploadProgress(progress);
          });
          console.log("Upload completed successfully");
          
          // Add notification after successful upload
          const shareUrl = `${window.location.origin}/social`;
          addSnackbar(
            title,  // Using title as the headline
            description,  // User name/email followed by description
            shareUrl
          );
          
          refreshPosts();
          handleClose();
          return;
        } catch (error) {
          if (error.message === "Token expired" && retryCount < maxRetries) {
            console.log("Token expired, refreshing...");
            token = await acquireToken(undefined, "id", true); // Force token refresh
            retryCount++;
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      if (!cancelTokenRef.current.isCancelled) {
        console.error("Error uploading post:", error);
        setError(error.message || "Failed to upload post.");
      } else {
        console.log("Upload cancelled during error handling");
      }
    } finally {
      if (cancelTokenRef.current.isCancelled) {
        console.log("Upload cancelled in finally block");
      }
      setLoading(false);
      setIsUploading(false);
      cancelTokenRef.current.isCancelled = false;
      setCompressionAttempted(false); // Reset for next upload
    }
  };

  const uploadInChunks = async (file, token, metadata, onTotalProgress) => {
    const chunkSize = 2 * 1024 * 1024; // 5MB chunks
    const chunks = Math.ceil(file.size / chunkSize);
    let uploadedSize = 0;

    for (let i = 0; i < chunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('file', chunk, file.name);
      formData.append('chunk', i.toString());
      formData.append('chunks', chunks.toString());
      formData.append('originalFileName', file.name);

      Object.entries(metadata).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        await uploadChunk(formData, token, (chunkProgress) => {
          const chunkSize = end - start;
          const chunkUploaded = (chunkProgress / 100) * chunkSize;
          const totalProgress = ((uploadedSize + chunkUploaded) / file.size) * 100;
          console.log(`Total progress: ${totalProgress.toFixed(2)}%`);
          onTotalProgress(totalProgress);
        });
        uploadedSize += (end - start);
      } catch (error) {
        console.error(`Error uploading chunk ${i}:`, error);
        throw error;
      }
    }
  };

  const uploadChunk = async (formData, token, onProgress) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/upload`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(`Chunk progress: ${percentComplete.toFixed(2)}%`);
          onProgress(percentComplete);
        }
      };

      xhr.onload = function() {
        if (xhr.status === 200 || xhr.status === 201) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}: ${xhr.responseText}`));
        }
      };

      xhr.onerror = () => reject(new Error('Network error occurred'));

      xhr.send(formData);
    });
  };

  const verifyVideo = async (videoFile) => {
    console.log("verifyVideo");
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      const timeoutId = setTimeout(() => {
        console.log("Video verification timed out");
        URL.revokeObjectURL(video.src);
        resolve(true);
      }, 5000);

      video.onloadedmetadata = () => {
        clearTimeout(timeoutId);
        URL.revokeObjectURL(video.src);
        console.log("Video metadata loaded successfully");
        resolve(true);
      };

      video.onerror = () => {
        clearTimeout(timeoutId);
        URL.revokeObjectURL(video.src);
        console.log("Error loading video metadata");
        resolve(false);
      };

      video.src = URL.createObjectURL(videoFile);
      console.log("video.src", video.src);
    });
  };

  const handleAIGenerate = async () => {
    if (!file) {
      setError("Please upload an image or video first");
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      let content;
      if (mediaType === 'image') {
        content = await getBase64(file);
      } else {
        content = file.name;
      }

      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/generateContent`,
        { mediaType, content }
      );

      setTitle(response.title);
      setDescription(response.description);
    } catch (error) {
      console.error('Error generating content:', error);
      setError('Failed to generate content. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box sx={{ my: 0 }}>
            <TextField
              fullWidth
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAIGenerate}
              disabled={!file || isGenerating}
              fullWidth
            >
              {isGenerating ? <CircularProgress size={24} /> : "AI Generate Text"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              border: '1px dashed #ccc',
              padding: 2,
              borderRadius: 1,
              textAlign: 'center',
              cursor: 'pointer',
              minHeight: '250px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => document.getElementById("fileInput").click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              hidden
              id="fileInput"
              accept="image/*, video/*"
              type="file"
              onChange={handleFileChange}
            />
            {file ? (
              mediaType === "image" ? (
                <img
                  src={fileUrl}
                  alt="preview"
                  style={{
                    width: "auto",
                    height: "100%",
                    objectFit: "cover",
                    aspectRatio: "9 / 16",
                  }}
                />
              ) : (
                <video
                  src={fileUrl}
                  style={{
                    width: "auto",
                    height: "100%",
                    objectFit: "cover",
                    aspectRatio: "9 / 16",
                  }}
                  controls
                />
              )
            ) : (
              <Typography variant="body2" color="textSecondary">
                Drag & drop or click to upload an image or video
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      {compressing && (
        <>
          <LinearProgress variant="determinate" value={compressionProgress} />
          <Typography variant="body2">{`Compressing: ${Math.round(compressionProgress)}%`}</Typography>
        </>
      )}
      {isUploading && (
        <>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2">{`Uploading: ${Math.round(uploadProgress)}%`}</Typography>
        </>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={loading || compressing || isUploading || !file || !title || !description}
          sx={{ flexGrow: 1, mr: 1 }}
        >
          {loading || compressing || isUploading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
        {(loading || compressing || isUploading) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={cancelOperation}
            disabled={cancelling}
          >
            Cancel
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UploadPost;
