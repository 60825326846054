import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import {
  ArrowUpward as ArrowUpwardIcon,
  AttachFile as AttachFileIcon,
  Language as LanguageIcon,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const DropTextField = styled(TextField)({
  borderRadius: "16px",
  padding: "0",
  backgroundColor: "rgba(64, 64, 64, 0.9)",
  ".MuiOutlinedInput-root": {
    borderRadius: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    backgroundColor: "rgba(64, 64, 64, 0.9)",
  },
  "& .MuiInputBase-input": {
    color: "rgba(255, 255, 255, 0.87)",
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.5)",
      opacity: 1,
    },
  },
  '& .MuiInputBase-root': {
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(52, 53, 65, 0.95)',
      borderRadius: '16px',
      border: '2px dashed rgba(255, 255, 255, 0.4)',
      zIndex: 10,
    },
    '&.dragging::after': {
      display: 'block',
    },
    '&.dragging::before': {
      content: '"Drop file to send"',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'rgba(255, 255, 255, 0.9)',
      zIndex: 11,
      fontFamily: 'inherit',
      fontSize: '14px',
      pointerEvents: 'none',
    },
  },
});

const InputArea = ({
  input,
  handleInputChange,
  handleSendMessage,
  handleFileChange,
  darkMode,
  status,
  fileInputRef,
  permissionLevel,
  useAzureContext,
  setUseAzureContext,
  selectedFile,
}) => {
  const location = useLocation();
  const [showContext, setShowContext] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  if (permissionLevel === "view") {
    return null;
  }

  useEffect(() => {
    if (location.pathname.includes("b00b24ba-2933-4d5e-919d-835cc05057a6")) {
      setShowContext(false);
    } else {
      setShowContext(true);
    }
  }, [location.pathname]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form 
        onSubmit={handleSendMessage} 
        style={{ width: "100%" }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <DropTextField
          fullWidth
          multiline
          maxRows={5}
          variant="outlined"
          placeholder="Message ChatGPT"
          value={input}
          onChange={handleInputChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleSendMessage(event);
            }
          }}
          InputProps={{
            style: {
              padding: "8px 12px",
              minHeight: "52px",
            },
            startAdornment: (
              <Box sx={{ display: "flex", gap: .5, mr: 1 }}>
                {showContext && (
                  <Tooltip title="Search with Context" placement="top">
                    <IconButton
                      size="x-small"
                      onClick={() => setUseAzureContext(!useAzureContext)}
                      sx={{
                        color: useAzureContext
                          ? "#ff9800"
                          : "rgba(255, 255, 255, 0.6)",
                        "&:hover": {
                          color: useAzureContext
                            ? "#ff9800"
                            : "rgba(255, 255, 255, 0.87)",
                          backgroundColor: "transparent",
                        },
                        transition: "color 0.2s ease",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <LanguageIcon sx={{ fontSize: 25, margin: 0, padding: 0 }} />
                    </IconButton>
                  </Tooltip>
                )}
                <label htmlFor="file-upload">
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <IconButton
                    color="inherit"
                    component="span"
                    size="small"
                    sx={{
                      color: "rgba(255, 255, 255, 0.6)",
                      "&:hover": {
                        color: "rgba(255, 255, 255, 0.87)",
                        backgroundColor: "transparent",
                      },
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <AttachFileIcon sx={{ fontSize: 25 }} />
                  </IconButton>
                </label>
              </Box>
            ),
            endAdornment: (
              <Box sx={{ mr: 1 }}>
                <IconButton
                  id="send-button"
                  onClick={handleSendMessage}
                  disabled={status === "in_progress" || (!input.trim() && !selectedFile)}
                  size="small"
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                    backgroundColor: (input.trim() || selectedFile)
                      ? "#ff9800"
                      : "transparent",
                    "&:hover": {
                      backgroundColor: (input.trim() || selectedFile)
                        ? "#ff9800"
                        : "transparent",
                      color: (input.trim() || selectedFile)
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(255, 255, 255, 0.4)",
                    },
                    opacity: 1,
                    transition: "all 0.2s ease",
                  }}
                >
                  <ArrowUpwardIcon sx={{ fontSize: 25 }} />
                </IconButton>
              </Box>
            ),
            className: isDragging ? 'dragging' : '',
          }}
          sx={{
            backgroundColor: 'rgba(64, 64, 64, 0.9)',
            '& .MuiInputBase-input': {
              opacity: isDragging ? 0.3 : 1,
              transition: 'opacity 0.2s ease',
            },
          }}
        />
      </form>
    </Box>
  );
};

InputArea.propTypes = {
  input: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  status: PropTypes.string,
  fileInputRef: PropTypes.object,
  permissionLevel: PropTypes.string,
  useAzureContext: PropTypes.bool.isRequired,
  setUseAzureContext: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
};

export default InputArea;
