import React from "react";
import { Box } from "@mui/material";
import CustomCarousel from "@/components/common/CustomCarousel";

const CarouselSection = ({ carouselItems }) => (
  <Box mb={3}>
    <CustomCarousel items={carouselItems} />
  </Box>
);

export default CarouselSection;
