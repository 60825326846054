import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  Link as LinkIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";

const ShareChatDialog = ({ open, onClose, chatId, clientId }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { apiCall } = useApiWithAuth();
  const [inviteLink, setInviteLink] = useState("");
  const [inviteLinkLoading, setInviteLinkLoading] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  const handleGenerateInvite = async () => {
    try {
      setInviteLinkLoading(true);
      const link = await apiCall(
        "get",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/invite/generate?chatId=${chatId}&expiresIn=12h&clientId=${clientId}&permission=view`,
      );
      setInviteLink(link.inviteUrl);
      setInviteLinkLoading(false);
    } catch (error) {
      console.error("Failed to generate invite link:", error);
      setInviteLinkLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ "& .MuiPaper-root": { borderRadius: "6px" } }}
    >
      <DialogTitle>
        <Typography variant="h5" gutterBottom component="div">
          Share a Chat
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <Typography variant="body2" mt={2} mb={2}>
          For one-time use, you can generate a view-only link to share this
          chat.
        </Typography>
        <Box display="flex" alignItems="center" sx={{ width: "100%" }}>
          {inviteLinkLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "90%",
                height: "56px",
                border: "1px solid #ccc",
                mr: 1,
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            <TextField
              fullWidth
              value={inviteLink}
              InputProps={{
                readOnly: true,
              }}
              sx={{ mr: 1 }}
            />
          )}

          <IconButton
            onClick={handleCopy}
            edge="end"
            disabled={!inviteLink || inviteLinkLoading}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<LinkIcon />}
            onClick={() => handleGenerateInvite()}
            disabled={inviteLinkLoading}
          >
            Create Link
          </Button>
        </Box>
        {copySuccess && (
          <Typography variant="caption" color="success.main">
            Copied to clipboard!
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShareChatDialog;
