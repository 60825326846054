import React from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import { useThemeContext } from '../../context/ThemeContext';

const NewsCardSkeleton = () => {
  const { theme } = useThemeContext();

  return (
    <Card 
      elevation={0} 
      sx={{ 
        border: `1px solid ${theme.palette.border.main}`,
        height: '400px', 
        display: 'flex', 
        flexDirection: 'column',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
      }}
    >
      <Skeleton variant="rectangular" height={400} />
      <CardContent>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
      </CardContent>
    </Card>
  );
};

export default NewsCardSkeleton;
