const historyData = [
  {
    id: 1,
    name: 'Create a Media Plan',
    status: 'Completed',
    completionDate: '2024-01-01',
    duration: '3 minutes',
  },
  {
    id: 2,
    name: 'Write a Creative Brief',
    status: 'Failed',
    completionDate: '2024-01-05',
    duration: '5 minutes',
  },
  {
    id: 3,
    name: 'Develop a Social Media Strategy',
    status: 'In Progress',
    completionDate: '',
    duration: '2 minutes',
  },
  {
    id: 4,
    name: 'Create a White Paper',
    status: 'Pending',
    completionDate: '',
    duration: '4 minutes',
  },
  {
    id: 5,
    name: 'Design a LinkedIn Ad',
    status: 'Completed',
    completionDate: '2024-02-10',
    duration: '3 minutes 30 seconds',
  },
  {
    id: 6,
    name: 'Draft a Statement of Work (SOW)',
    status: 'Completed',
    completionDate: '2024-02-15',
    duration: '6 minutes',
  },
  {
    id: 7,
    name: 'Create a Budget Plan',
    status: 'In Progress',
    completionDate: '',
    duration: '8 minutes',
  },
  {
    id: 8,
    name: 'Generate a Digital Ad Campaign',
    status: 'Pending',
    completionDate: '',
    duration: '7 minutes',
  },
  {
    id: 9,
    name: 'Summarize Market Research Data',
    status: 'Completed',
    completionDate: '2024-03-01',
    duration: '2 minutes 45 seconds',
  },
  {
    id: 10,
    name: 'Develop an SEO Strategy',
    status: 'Completed',
    completionDate: '2024-03-05',
    duration: '5 minutes 20 seconds',
  },
  {
    id: 11,
    name: 'Create an Email Marketing Campaign',
    status: 'Completed',
    completionDate: '2024-03-10',
    duration: '4 minutes 50 seconds',
  },
  {
    id: 12,
    name: 'Design Infographics',
    status: 'Completed',
    completionDate: '2024-03-15',
    duration: '3 minutes 10 seconds',
  },
  {
    id: 13,
    name: 'Produce Video Content',
    status: 'In Progress',
    completionDate: '',
    duration: '7 minutes 30 seconds',
  },
  {
    id: 14,
    name: 'Create Landing Pages',
    status: 'Pending',
    completionDate: '',
    duration: '6 minutes 40 seconds',
  },
  {
    id: 15,
    name: 'Develop a PPC Campaign',
    status: 'Completed',
    completionDate: '2024-04-01',
    duration: '8 minutes 15 seconds',
  },
  {
    id: 16,
    name: 'Plan a Webinar Event',
    status: 'Completed',
    completionDate: '2024-04-05',
    duration: '10 minutes',
  },
  {
    id: 17,
    name: 'Create a Content Calendar',
    status: 'Completed',
    completionDate: '2024-04-10',
    duration: '9 minutes 20 seconds',
  },
  {
    id: 18,
    name: 'Conduct a Competitor Analysis',
    status: 'In Progress',
    completionDate: '',
    duration: '5 minutes 50 seconds',
  },
  {
    id: 19,
    name: 'Draft Press Releases',
    status: 'Pending',
    completionDate: '',
    duration: '4 minutes 30 seconds',
  },
  {
    id: 20,
    name: 'Design Promotional Materials',
    status: 'Completed',
    completionDate: '2024-05-01',
    duration: '6 minutes 10 seconds',
  },
  {
    id: 21,
    name: 'Conduct Customer Surveys',
    status: 'Completed',
    completionDate: '2024-05-05',
    duration: '7 minutes',
  },
  {
    id: 22,
    name: 'Generate Sales Reports',
    status: 'Completed',
    completionDate: '2024-05-10',
    duration: '8 minutes',
  },
  {
    id: 23,
    name: 'Create Customer Personas',
    status: 'In Progress',
    completionDate: '',
    duration: '6 minutes 30 seconds',
  },
  {
    id: 24,
    name: 'Develop a Brand Style Guide',
    status: 'Pending',
    completionDate: '',
    duration: '5 minutes',
  },
  {
    id: 25,
    name: 'Perform A/B Testing on Campaigns',
    status: 'Completed',
    completionDate: '2024-06-01',
    duration: '9 minutes 40 seconds',
  },
  {
    id: 26,
    name: 'Create a Media Plan',
    status: 'Failed',
    completionDate: '2024-01-01',
    duration: '3 minutes',
  },
  {
    id: 27,
    name: 'Write a Creative Brief',
    status: 'Failed',
    completionDate: '2024-01-05',
    duration: '5 minutes',
  },
  {
    id: 28,
    name: 'Develop a Social Media Strategy',
    status: 'In Progress',
    completionDate: '',
    duration: '2 minutes',
  },
  {
    id: 29,
    name: 'Create a White Paper',
    status: 'Pending',
    completionDate: '',
    duration: '4 minutes',
  },
  {
    id: 30,
    name: 'Design a LinkedIn Ad',
    status: 'Completed',
    completionDate: '2024-02-10',
    duration: '3 minutes 30 seconds',
  },
  {
    id: 31,
    name: 'Draft a Statement of Work (SOW)',
    status: 'Completed',
    completionDate: '2024-02-15',
    duration: '6 minutes',
  },
  {
    id: 32,
    name: 'Create a Budget Plan',
    status: 'In Progress',
    completionDate: '',
    duration: '8 minutes',
  },
  {
    id: 33,
    name: 'Generate a Digital Ad Campaign',
    status: 'Pending',
    completionDate: '',
    duration: '7 minutes',
  },
  {
    id: 34,
    name: 'Summarize Market Research Data',
    status: 'Completed',
    completionDate: '2024-03-01',
    duration: '2 minutes 45 seconds',
  },
  {
    id: 35,
    name: 'Develop an SEO Strategy',
    status: 'Completed',
    completionDate: '2024-03-05',
    duration: '5 minutes 20 seconds',
  },
];

export default historyData;
