import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';

const ImportWebsiteModal = ({ open, handleClose, onWebsiteSelect }) => {
    const [website, setWebsite] = useState('');
    const [maxPages, setMaxPages] = useState(25);
    const [syncFrequency, setSyncFrequency] = useState('Never');
    const [crawlMode, setCrawlMode] = useState('Domain');
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            setWebsite('');
            setMaxPages(25);
            setSyncFrequency('Never');
            setCrawlMode('Domain');
            setError('');
        }
    }, [open]);

    const handleWebsiteChange = (e) => {
        let url = e.target.value;
        url = url.replace(/^https?:\/\//, '').replace(/\/$/, '');
        setWebsite(url);

        const urlPattern = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
        if (!urlPattern.test(url)) {
            setError('Please enter a valid URL');
        } else {
            setError('');
        }
    };

    const handleSubmit = () => {
        if (!website || error) {
            setError('Please enter a valid URL');
            return;
        }

        const formattedWebsite = website.startsWith('https://') ? website : `https://${website}`;
        const formData = {
            website: formattedWebsite,
            maxPages,
            syncFrequency,
            crawlMode
        };
        onWebsiteSelect(formData);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" gutterBottom>
                    Import a Website
                </Typography>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <TextField
                        label="Website URL"
                        value={website}
                        onChange={handleWebsiteChange}
                        error={!!error}
                        helperText={error}
                        fullWidth
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel>Max Number of Pages</InputLabel>
                    <Select
                        value={maxPages}
                        onChange={(e) => setMaxPages(e.target.value)}
                        label="Max Number of Pages"
                    >
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={250}>250</MenuItem>
                        <MenuItem value={1000}>1000</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel>Sync Frequency</InputLabel>
                    <Select
                        value={syncFrequency}
                        onChange={(e) => setSyncFrequency(e.target.value)}
                        label="Sync Frequency"
                    >
                        <MenuItem value="Weekly">Weekly</MenuItem>
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Never">Never</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel>Crawl Mode</InputLabel>
                    <Select
                        value={crawlMode}
                        onChange={(e) => setCrawlMode(e.target.value)}
                        label="Crawl Mode"
                    >
                        <MenuItem value="Domain">Domain</MenuItem>
                        <MenuItem value="Sub Domain">Sub Domain</MenuItem>
                        <MenuItem value="Page">Page</MenuItem>
                    </Select>
                </FormControl>
                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Import
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ImportWebsiteModal;