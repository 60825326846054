import React, { useReducer, useEffect, useContext, useCallback, useState } from "react";
import { debounce } from "lodash";
import {
  useTheme,
  Grid,
  Box,
  useMediaQuery,
  Container,
} from "@mui/material";
import {
  fetchCategories,
  fetchTaskList,
  fetchCarouselItems,
  urlFor,
} from "../utils/sanityUtils";
import { useNavigate } from "react-router-dom";
import { isAndroidOrIOS } from "../utils/deviceDetection";
import { useUser } from "@context/UserContext";
import { track } from "@vercel/analytics";
import { useThemeContext } from "@context/ThemeContext";
import axios from "axios";
import CustomCarouselItem from "@/components/common/CustomCarouselItem";
import { ClientContext } from "@context/ClientContext";
import CarouselSection from "@/components/dashboard/CarouselSection";
import ChatSection from "@/components/dashboard/ChatSection";
import ChatSectionMobile from "@/components/dashboard/ChatSectionMobile";
import FindToolSection from "@/components/dashboard/FindToolSection";
import tourSteps from "@/components/dashboard/TourSteps";
import TasksSection from "@/components/dashboard/TasksSection";
import CustomJoyride from "@/components/dashboard/CustomJoyride";
import WelcomeModal from "@/components/dashboard/WelcomeModal";
import { useTasks } from "@/hooks/useTasks";
import { useCategories } from "@/hooks/useCategories";
import { dashboardReducer, initialState } from "@/reducers/dashboardReducer"; // Import the reducer and initial state
import { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import AIProductsSection from "@/components/dashboard/AIProductsSection";

const Dashboard = () => {
  const theme = useTheme();
  const [state, dispatch] = useReducer(dashboardReducer, initialState);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const isMobile = isAndroidOrIOS();
  const { user } = useUser();
  const { darkMode } = useThemeContext();
  const { clients } = useContext(ClientContext);
  const { categories, selectedCategory, setSelectedCategory } = useCategories();
  const { tasks: initialTasks, tasksLoaded } = useTasks('All');
  const [tasks, setTasks] = useState(initialTasks);

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    dispatch({ type: 'SET_LOADING', payload: true });

    try {
      const payload = { description: state.taskDescription };
      const rawResponse = await axios.post(
        "https://hook.us1.make.com/0prjwurozlqg049uwlxhc7s4fmd7gvn4",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      let cleanString = rawResponse.data.replace(/```json|```/g, "");
      cleanString = cleanString.replace(/[\u201C\u201D]/g, '"');
      const parsedResponse = JSON.parse(cleanString);
      dispatch({ type: 'SET_RESPONSE', payload: parsedResponse });
    } catch (error) {
      console.error("Error calling webhook:", error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [state.taskDescription]);

  const handleCategorySelect = useCallback(debounce((category) => {
    setSelectedCategory(category);
  }, 300), [setSelectedCategory]);

  const handleSelectTask = useCallback((item) => {
    console.log('Selected task item:', item);  // Added console log

    navigate("/chatgpt/b00b24ba-2933-4d5e-919d-835cc05057a6/chat", {
      state: { taskItem: item },
    });
  }, [navigate]);

  useEffect(() => {
    if (!tasksLoaded) return;
  
    const fetchData = async () => {
      try {
        const data = await fetchTaskList();
        if (data.length > 0) {
          console.log("Tasks:", data);
          dispatch({ type: 'SET_TASKS', payload: data });
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
  
    fetchData();
  }, [tasksLoaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCarouselItems();
        const customCarouselItem = {
          type: "custom",
          content: (
            <CustomCarouselItem
              theme={theme}
              isSmallScreen={isSmallScreen}
              handlePromptStarter={(prompt) => dispatch({ type: 'SET_TASK_DESCRIPTION', payload: prompt })}
              loading={state.loading}
              promptStarter=""
            />
          ),
        };
        const updatedBanners = [customCarouselItem, ...(data.banners || [])];
        dispatch({ type: 'SET_CAROUSEL_ITEMS', payload: updatedBanners });
      } catch (error) {
        console.error("Error fetching carousel items:", error);
      }
    };
    fetchData();
  }, [theme, isSmallScreen, state.loading]);

  const handleJoyrideCallback = useCallback((data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) { 
      dispatch({ type: 'SET_STEP_INDEX', payload: state.stepIndex + (action === ACTIONS.PREV ? -1 : 1) });
    }
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem("tourShown", "true");
      dispatch({ type: 'SET_RUN', payload: false });
    }
  }, [state.stepIndex]);

  useEffect(() => {
    if (state.run) {
      document.body.classList.add("disable-scroll");
      document.querySelector("main").style.overflow = "hidden";
    } else {
      document.body.classList.remove("disable-scroll");
      document.querySelector("main").style.overflow = "auto";
    }
  }, [state.run]);

  useEffect(() => {
    const tourShown = localStorage.getItem("tourShown");
    if (!tourShown) {
      dispatch({ type: 'SET_SHOW_MODAL', payload: true });
      document.body.classList.add("disable-scroll");
      document.querySelector("main").style.overflow = "hidden";
    } else {
      dispatch({ type: 'SET_SHOW_MODAL', payload: false });
      document.body.classList.remove("disable-scroll");
      document.querySelector("main").style.overflow = "auto";
      dispatch({ type: 'SET_NOTIFICATIONS_DISPLAYED', payload: true });
    }
  }, []);

  return (
    <Container maxWidth="lg" disableGutters>
      <Box
        className="fade-in p-4 pt-20 pb-20"
        style={{ height: "100%", overflow: "auto", overflowX: "hidden" }}
      >
        <WelcomeModal
          open={state.showModal}
          onStart={() => {
            dispatch({ type: 'SET_SHOW_MODAL', payload: false });
            dispatch({ type: 'SET_RUN', payload: true });
          }}
          onClose={() => {
            dispatch({ type: 'SET_SHOW_MODAL', payload: false });
            dispatch({ type: 'SET_NOTIFICATIONS_DISPLAYED', payload: true });
            localStorage.setItem("tourShown", "true");
            document.body.classList.remove("disable-scroll");
            document.querySelector("main").style.overflow = "auto";
          }}
          firstName={user.name ? user.name.split(" ").slice(0, -1).join(" ") : ""}
        />

        <CustomJoyride
          steps={tourSteps}
          run={state.run}
          stepIndex={state.stepIndex}
          handleJoyrideCallback={handleJoyrideCallback}
        />

        <div>
          <CarouselSection carouselItems={state.carouselItems} />
        </div>

        <Grid container spacing={2}>
          <TasksSection
            darkMode={darkMode}
            theme={theme}
            categories={categories}
            tasksLoaded={tasksLoaded}
            selectedCategory={selectedCategory}
            tasks={tasks}
            setTasks={setTasks}
            isSmallScreen={isSmallScreen}
            isMobile={isMobile}
            handleCategorySelect={handleCategorySelect}
            handleSelectTask={handleSelectTask}
            navigate={navigate}
            user={user}
            track={track}
          />

          <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
            {isSmallScreen ? (
              <>
                <ChatSectionMobile
                  darkMode={darkMode}
                  theme={theme}
                  clients={clients}
                  urlFor={urlFor}
                />
              </>
            ) : null}
          </Grid>

          <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
            {!isSmallScreen ? (
              <>
                <ChatSection
                  darkMode={darkMode}
                  theme={theme}
                  clients={clients}
                  urlFor={urlFor}
                />
              </>
            ) : null}

            <FindToolSection
              darkMode={darkMode}
              theme={theme}
              loading={state.loading}
              taskDescription={state.taskDescription}
              setTaskDescription={(description) => dispatch({ type: 'SET_TASK_DESCRIPTION', payload: description })}
              handleSubmit={handleSubmit}
              response={state.response}
            />
            <AIProductsSection
              darkMode={darkMode}
              theme={theme}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
