import React from "react";
import { Box, Typography, Container } from "@mui/material";

const GPTTest = () => {
  return (
    <Container
      maxWidth="md"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensure full height but allow body to scroll
        overflow: "visible", // Allow natural page scrolling
      }}
    >
      {/* Header */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          height: "60px",
          backgroundColor: "#cccccc",
          zIndex: 10,
        }}
      >
        Header
      </Box>

      {/* Messages Area */}
      <Box
        sx={{
         //flexGrow: 1,
        //top:0,
        //position: "relative",
            //height:"200px",
          //overflowY: "auto", // Only the messages area scrolls
          WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
          paddingTop: "90px", // Offset for the fixed header
          paddingBottom: "90px", // Offset for the fixed footer
          marginTop: "60px",
        }}
      >
        {Array.from({ length: 50 }).map((_, index) => (
          <Typography key={index} sx={{ paddingBottom: "10px" }}>
            Placeholder message {index + 1}: Lorem ipsum dolor sit amet.
          </Typography>
        ))}
      </Box>

      {/* Input box */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: "60px",
          backgroundColor: "#cccccc",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            paddingTop: 1,
            paddingLeft: 2,
            paddingRight: 2,
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 700,
            left: "50%",
            transform: "translateX(-50%)",
            flexDirection: "column",
          }}
        >
          Input Field
        </Box>
      </Box>
    </Container>
  );
};

export default GPTTest;
