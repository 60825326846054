// src/hooks/useCustomTheme.js
import { useState, useEffect, useMemo } from 'react';
import getTheme from '../assets/styles/theme';

const useCustomTheme = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme === 'dark' : true;
  });

  const handleThemeChange = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('theme', newMode ? 'dark' : 'light');
  };

  // Corrected: Properly create the theme object
  const theme = useMemo(() => getTheme(darkMode ? 'dark' : 'light'), [darkMode]);

  useEffect(() => {
    const themeMode = darkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', themeMode);
  
    const themeColor = darkMode ? '#121212' : '#E0E0E0';
    document.documentElement.style.backgroundColor = themeColor;
    document.body.style.backgroundColor = themeColor;
    document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
  }, [darkMode]);

  return { theme, handleThemeChange, darkMode }; // Return the theme object
};

export default useCustomTheme;