import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../sanityClient";
const builder = imageUrlBuilder(sanityClient);

export const downloadFile = async (
  fileId,
  bucketName,
  acquireToken,
  accounts,
) => {
  try {
    const token = await acquireToken(accounts[0], "id");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/files/download/${fileId}?bucketName=${bucketName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response.ok) {
      // The response should be a redirect to the signed URL
      window.location.href = response.url;
    } else {
      throw new Error("Failed to get download URL");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const urlFor = (source) => {
  return builder.image(source).url();
};

const isColorDark = (color) => {
  const hexColor = color.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 128; // Returns true if color is dark
};

export const fetchClientInfo = async (clientId, clients, sanityClient) => {
  try {
    const processedClients = clients.map((client) => {
      const colorHex = client.clientColor.hex;
      return {
        id: client._id,
        name: client.clientName,
        logo: urlFor(
          client.clientLogo.asset._ref,
          imageUrlBuilder(sanityClient),
        ),
        color: colorHex,
        isColorDark: isColorDark(colorHex),
        clientId: client.clientId,
        assistantId: client.assistantId,
      };
    });

    const matchingClient = processedClients.find(
      (client) => client.clientId === clientId,
    );

    return matchingClient || null;
  } catch (error) {
    console.error("Error fetching client info:", error);
    return null;
  }
};
