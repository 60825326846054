import React, { useState } from "react";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApiWithAuth } from "@hooks/useApiWithAuth";

const SourceListItem = ({
  source,
  selectedItem,
  setSources,
  setError,
  pagesSynced,
  itemType,
  lastSyncedAt,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { apiCall } = useApiWithAuth();

  const handleDeleteSource = async () => {
    setIsDeleting(true);

    // console.log("*****************************");
    // console.log('Deleting source:', source);
    // console.log('Item Type:', itemType);
    // console.log('Task ID:', selectedItem);
    // console.log("*****************************");

    let id = source.id;
    let sourceId = source.source_id;
    let assistantId = source.assistant_id;

    console.log("*****************************");
    console.log("id:", id);
    console.log("sourceId:", sourceId);
    console.log("assistantId:", assistantId);
    console.log("*****************************");

    try {
      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/delete-source`,
        {
          id,
          sourceId,
          assistantId,
        },
      );

      if (response.success) {
        setSources((prevSources) =>
          prevSources.filter((s) => s.source_id !== source.source_id),
        );
        console.log("Successfully deleted the source");
      } else {
        console.error("Failed to delete the source:", response.message);
        setError("Failed to delete the source");
      }
    } catch (error) {
      console.error("Error deleting the source:", error);
      setError("Error deleting the source");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ListItem divider>
      <ListItemText
        primary={source.rag_sources.source_description}
        secondary={`${source.rag_sources.source_type} - Last updated ${new Date(source.updated_at).toLocaleDateString()}`}
        sx={{
          "& .MuiListItemText-primary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
          },
          "& .MuiListItemText-secondary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
          },
        }}
      />
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={handleDeleteSource}
        disabled={isDeleting}
      >
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

export default SourceListItem;
