import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Chat as ChatIcon, IosShare as ShareIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Header = ({
  chatName,
  currentClientObject,
  darkMode,
  handleNewChat,
  opacity,
  handleOpenShareDialog,
  chatId,
}) => {
  const theme = useTheme();
  const { currentClientColor } = useSelector((state) => state.chatgpt);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    currentClientColor && (
      <Box
        className="fade-in"
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          paddingLeft: isSmallScreen ? "60px" : "120px",
          paddingRight: "60px",
          paddingTop: "14px",
          paddingBottom: "0px",
          backgroundColor: currentClientColor
            ? currentClientColor
            : darkMode
              ? "#181818"
              : "#ffffff",
          height: "50px",
          zIndex: 10,
          color: darkMode ? "#ffffff" : "#000000",
          opacity: !opacity,
          transition: "all 0.2s ease-in-out",
        }}
      >
        <Typography
          variant="body1"
          color={currentClientObject ? "white" : "textSecondary"}
          sx={{
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            userSelect: "none",
            opacity: opacity,
            transition: "all 0.2s ease-in-out",
          }}
        >
          {chatName ||
            (currentClientObject
              ? `${currentClientObject.name}${currentClientObject.name !== 'ChatGPT' ? ' ChatGPT' : ''}`
              : "Nucleus ChatGPT")}
        </Typography>
        {chatId && (
          <IconButton
            sx={{
              position: "fixed",
              top: 5,
              right: 10,
              color: darkMode ? "#ffffff" : "#000000",
            }}
            onClick={handleOpenShareDialog}
          >
            <ShareIcon sx={{ fontSize: 24 }} />
          </IconButton>
        )}
      </Box>
    )
  );
};

Header.propTypes = {
  chatName: PropTypes.string,
  currentClientObject: PropTypes.object,
  darkMode: PropTypes.bool.isRequired,
  handleNewChat: PropTypes.func.isRequired,
  handleOpenShareDialog: PropTypes.func.isRequired,
};

export default Header;
