// ClientContext.js 
import React, { createContext, useState, useEffect } from "react";
import sanityClient from "../sanityClient";
import imageUrlBuilder from '@sanity/image-url';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const builder = imageUrlBuilder(sanityClient);

  function urlFor(source) {
    return builder.image(source).url();
  }

  const fetchClients = async () => {
    const data = await sanityClient.fetch('*[_type == "clients"]');
    console.log("fetchClients raw data", data);
    const sortedData = data
      .filter(client => client.isVisible !== 'hidden') // Filter out hidden clients
      .map(client => ({
        ...client,
        url: client.clientLogo?.asset?._ref ? urlFor(client.clientLogo.asset) : null
      }))
      .sort((a, b) => {
        if (a.clientName === "ChatGPT") return -1;
        if (b.clientName === "ChatGPT") return 1;
        if (a.clientName === "Fusion92")
          return a.clientName === "ChatGPT" ? 1 : -1;
        if (b.clientName === "Fusion92")
          return b.clientName === "ChatGPT" ? -1 : 1;
        return a.clientName.localeCompare(b.clientName);
      });
      
    console.log("fetchClients sorted and filtered data", sortedData);
    setClients(sortedData);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <ClientContext.Provider value={{ clients, fetchClients }}>
      {children}
    </ClientContext.Provider>
  );
};
