import React, { useState } from 'react';
import { Icon, List, Collapse, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { ExpandLess, ExpandMore, People, Assignment, ListAlt } from '@mui/icons-material';
import CustomListItem from './CustomListItem';
import {
    List as ViewListIcon,
    Person as UserIcon,
    Settings as CogIcon,
    School as SchoolIcon,
    Task as TaskAltIcon,
} from "@mui/icons-material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useThemeContext } from '../../context/ThemeContext';
import { useMediaQuery } from '@mui/material';
import { isAndroidOrIOS } from '../../utils/deviceDetection';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'; 
import HistoryIcon from '@mui/icons-material/History';

const CollapsibleAdminSection = ({ collapsed }) => {
  const { darkMode, theme } = useThemeContext();
  const [open, setOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = isAndroidOrIOS();

  const isTaskHistoryActive =
    location.pathname.startsWith("/history") ||
    location.pathname.startsWith("/taskdetail");
    
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem
        onClick={handleClick}
        className={open ? 'list-item folder-active-nav' : ''}
        sx={{
          minHeight: '50px',
          boxShadow: 'inset 4px 0 0 transparent',
          cursor: 'pointer',
          paddingLeft: '18px',
          backgroundColor: open ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0)',
          transition: `all ${theme.custom.animation.fast} ease-in-out`,
          ...(isMobile ? {} : {
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              paddingLeft: '20px',
              boxShadow: 'inset 4px 0 0 rgba(255, 255, 255, 0.2)',
              '& .MuiListItemIcon-root': {
                color: darkMode ? theme.palette.common.white : theme.palette.primary.main,
              },
            },
          }),
        }}
      >
        <ListItemIcon style={{ minWidth: '42px' }}>
        <AdminPanelSettingsIcon 
          className="h-6 w-6"
          sx={{ color: theme.palette.icon.secondary }} 
        />
        </ListItemIcon>
        <ListItemText primary="Admin" sx={{userSelect: 'none',}} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      
      <Collapse in={open} timeout={parseInt(theme.custom.animation.fast, 10)} unmountOnExit>
        <List component="div" disablePadding>

            <CustomListItem
              to="/history"
              icon={HistoryIcon}
              primaryText="Task History"
              isActive={(path) =>
                isTaskHistoryActive || path.startsWith("/taskdetail")
              }
              handleNavLinkClick={() => {}}            />

            {/* <CustomListItem
              to="/actions"
              icon={ViewListIcon}
              primaryText="Actions"
              isActive={(path) => path === "/actions"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
              className="list-item" 
            /> 

            <CustomListItem
              to="/agents"
              icon={UserIcon}
              primaryText="Agents"
              isActive={(path) => path === "/agents"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            /> */}

            <CustomListItem
              to="/clients"
              icon={SupervisorAccountIcon}
              primaryText="Clients"
              isActive={(path) => path === "/clients"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            />

            <CustomListItem
              to="/tasks"
              icon={TaskAltIcon}
              primaryText="Training Data"
              isActive={(path) => path === "/tasks"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            />

            <CustomListItem
              to="/integrations"
              icon={CogIcon}
              primaryText="Integrations"
              isActive={(path) => path === "/integrations"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            />

            <CustomListItem
              to="/analytics"
              icon={AnalyticsIcon}
              primaryText="Analytics"
              isActive={(path) => path === "/analytics"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            />

            {/* <CustomListItem
              to="/social"
              icon={PlayCircleFilledIcon}
              primaryText="Social"
              isActive={(path) => path === "/social"}
              handleNavLinkClick={() => {}}
              collapsed={collapsed}
            /> */}

        </List>
      </Collapse>
    </div>
  );
};

export default CollapsibleAdminSection;
