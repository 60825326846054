// src/reducers/dashboardReducer.js

export const initialState = {
    tasksSorted: false,
    carouselItems: [],
    run: false,
    showModal: false,
    notificationsDisplayed: false,
    response: null,
    taskDescription: "",
    loading: false,
    stepIndex: 0,
    runTour: false,
  };
  
  export function dashboardReducer(state, action) {
    switch (action.type) {
      case 'SET_TASKS':
        return { ...state, tasks: action.payload };
      case 'SET_CAROUSEL_ITEMS':
        return { ...state, carouselItems: action.payload };
      case 'SET_RUN':
        return { ...state, run: action.payload };
      case 'SET_SHOW_MODAL':
        return { ...state, showModal: action.payload };
      case 'SET_NOTIFICATIONS_DISPLAYED':
        return { ...state, notificationsDisplayed: action.payload };
      case 'SET_RESPONSE':
        return { ...state, response: action.payload };
      case 'SET_TASK_DESCRIPTION':
        return { ...state, taskDescription: action.payload };
      case 'SET_LOADING':
        return { ...state, loading: action.payload };
      case 'SET_STEP_INDEX':
        return { ...state, stepIndex: action.payload };
      case 'SET_RUN_TOUR':
        return { ...state, runTour: action.payload };
      default:
        return state;
    }
  }
  