import React from "react";
import { Box, Typography, Paper, Container } from "@mui/material";
import { useTheme } from "@mui/material";

const Agents = ({ darkMode }) => {
  const theme = useTheme();

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Paper
          elevation={theme.elevation}
          sx={{
            padding: theme.spacing(4),
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Agents
          </Typography>
          <Typography variant="body1" gutterBottom>
            Welcome to the Agents page.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default Agents;
