import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  Button,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Add as PlusIcon,
  Try as TryIcon,
  Menu as MenuIcon,
  School as SchoolIcon,
  Settings as CogIcon,
  AutoAwesome as AutoAwesomeIcon,
  Newspaper as NewspaperIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CustomAvatar from "../common/CustomAvatar";
import CollapsibleAdminSection from "../common/CollapsibleAdminSection";
import CustomListItem from "../common/CustomListItem";
import { ReactComponent as Logo } from "../../assets/images/nucleus-icon.svg";
import ChatSidebar from "../chat-sidebar/ChatSidebar";
import { useUIContext } from "@context/UIContext";
import { useThemeContext } from "../../context/ThemeContext";

const Sidebar = ({ user, isAdmin }) => {
  const { darkMode } = useThemeContext();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const {
    uiSettings,
    toggleSidebar,
    toggleChatSidebar,
    closeSidebar,
    closeChatSidebar,
  } = useUIContext();
  const [collapsed, setCollapsed] = useState(false);
  const theme = useTheme();
  const [navLoaded, setNavLoaded] = useState(false);
  const isChatGPTPath = location.pathname.includes("/chatgpt");
  const isSocialPath = location.pathname.includes("/social");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    if (isSmallScreen || isChatGPTPath || isSocialPath) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    const timer = setTimeout(() => {
      setNavLoaded(true);
    }, 0);

    return () => clearTimeout(timer);
  }, [isSmallScreen, location.pathname]);

  const handleLogoClick = () => {
    if (!isSmallScreen) {
      navigate("/");
    } else {
      toggleSidebar();
    }
  };

  const handleNavLinkClick = () => {
    if (isSmallScreen) {
      closeSidebar();
      closeChatSidebar();
    }
  };

  const isDashboardActive =
    location.pathname === "/" || location.pathname.startsWith("/dashboard");

  const drawerContent = (
    <>
      <div
        className={`flex justify-left items-left ${
          uiSettings.sidebarOpen ? "pt-3 pl-4 pb-0" : "pt-3 pl-4 pb-0"
        }`}
        style={{ minHeight: "50px" }}
        onClick={handleLogoClick}
      >
        <Logo
          style={{
            width: "30px",
            height: "30px",
            fill: "#FF9800",
            cursor: "pointer",
            opacity: isSmallScreen && (isChatGPTPath || isSocialPath) ? "0" : "1",
          }}
        />

        <Typography
          variant="h6"
          component="div"
          className="flex-grow pl-4"
          sx={{ userSelect: "none", cursor: "default" }}
        >
          NUCLEUS{" "}
          <span className="font-bold" style={{ color: "#FF9800" }}>
            AI
          </span>
        </Typography>
      </div>
      <List>
        <CustomListItem
          to="/dashboard"
          icon={DashboardIcon}
          primaryText="Dashboard"
          isActive={(path) => isDashboardActive || path === "/"}
          handleNavLinkClick={handleNavLinkClick}
          iconClassName="first-step"
        />

        <CustomListItem
          to="/chatgpt/b00b24ba-2933-4d5e-919d-835cc05057a6/chat"
          icon={TryIcon}
          primaryText="ChatGPT"
          isActive={(path) => path.includes("/chatgpt")}
          handleNavLinkClick={handleNavLinkClick}
          iconClassName="second-step"
        />

        <CustomListItem
          to="/ai-tools"
          icon={AutoAwesomeIcon}
          primaryText="AI Task Force"
          isActive={(path) => path === "/ai-tools"}
          handleNavLinkClick={handleNavLinkClick}
        />

        <CustomListItem
          to="/ainews"
          icon={NewspaperIcon}
          primaryText="AI News & Trends"
          isActive={(path) => path === "/ainews"}
          handleNavLinkClick={handleNavLinkClick}
        />

        <CustomListItem
          to="/education"
          icon={SchoolIcon}
          primaryText="Education"
          isActive={(path) => path === "/education"}
          handleNavLinkClick={handleNavLinkClick}
        />

        <CustomListItem
          to="/social"
          icon={PlayCircleFilledIcon}
          primaryText="Social"
          isActive={(path) => path === "/social"}
          handleNavLinkClick={handleNavLinkClick}
        />

        <CustomListItem
          to="/settings"
          icon={CogIcon}
          primaryText="Settings"
          isActive={(path) => path === "/settings"}
          handleNavLinkClick={handleNavLinkClick}
        />

        {isAdmin && (
          <>{isAdmin && <CollapsibleAdminSection collapsed={collapsed} />}</>
        )}
      </List>

      <>
        <div className="p-2">
          <Button
            variant="contained"
            color="primary"
            align="left"
            textalign="left"
            display="flex"
            sx={{
              padding: "10px",
              margin: "0px",
              minWidth: "43px",
              height: "43px",
              textAlign: "left",
              transition: `all ${theme.custom.animation.fast} ease-in-out`,
              width: (!uiSettings.sidebarOpen || isSocialPath) ? "43px" : "205px",
            }}
            onClick={() => navigate("/submit-idea")}
          >
            <PlusIcon
              sx={{
                fontSize: "20px",
                padding: "0px !important",
                margin: "0px !important",
                position: "absolute",
                left: "11px",
              }}
            />

            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                display: "flex",
                width: "180px",
                padding: "0px 0px 0px 30px !important",
                margin: "0px !important",
                opacity: (uiSettings.sidebarOpen && !isSocialPath) ? 1 : 0,
                pointerEvents: (!uiSettings.sidebarOpen || isSocialPath) ? "auto" : "none",
                minWidth: "180px",
                left: "13px",
                animation: `${(uiSettings.sidebarOpen || isSocialPath) ? "fadeIn" : "fadeOut"} 0.2s ease-in-out`,
              }}
            >
              Submit an Idea
            </Typography>
          </Button>
        </div>
      </>

      <div
        className="flex justify-start items-center mt-auto p-4"
        style={{
          paddingLeft: 10,
          backgroundColor: darkMode
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.05)",
        }}
      >
        {user?.name && user?.profileImage && (
          <div className="fade-in flex items-center">
            <CustomAvatar
              name={user.name}
              darkMode={darkMode}
              width="40px"
              height="40px"
              src={user.profileImage}
              style={{ cursor: "pointer" }}
            />
            <Typography
              variant="body2"
              color={darkMode ? "textPrimary" : "textSecondary"}
              className="pl-2"
              sx={{ marginLeft: "10px", userSelect: "none", cursor: "default" }}
            >
              {user.name}
            </Typography>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div
      className={`force-overflow`}
      style={{
        position: "absolute",
        opacity: navLoaded ? 1 : 0,
        top: 0,
        left:
          (isChatGPTPath || isSocialPath) && isSmallScreen && !uiSettings.chatSidebarOpen
            ? "-1px"
            : "0px",
        height: "100%",
        zIndex: 2000,
        overflowY: "auto",
        width: (isChatGPTPath || isSocialPath)
          ? isSmallScreen
            ? uiSettings.chatSidebarOpen
              ? "60px"
              : "0px"
            : "60px"
          : uiSettings.sidebarOpen
            ? "220px"
            : "60px",
        boxShadow: darkMode
          ? "0px 0px 20px 0px rgba(0, 0, 0, .5)"
          : "0px 0px 20px 0px rgba(0, 0, 0, 0.3)",
        transition:
          navLoaded && !isChatGPTPath
            ? theme.transitions.create(["width", "opacity"], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.custom.animation.fast,
              })
            : "none",
        borderRight: `solid 1px ${darkMode ? "#333333" : "#ffffff"}`,
      }}
    >
      <Drawer
        variant="permanent"
        anchor="left"
        open={uiSettings.sidebarOpen || !isSmallScreen}
        sx={{
          zIndex: 2040,
          [`& .MuiDrawer-paper`]: {
            zIndex: 2040,
            width: "220px",
            display: "flex",
            position: "absolute",
            borderRight: 0,
            overflowX: "hidden",
          },
        }}
      >
        {drawerContent}
      </Drawer>

      {(isChatGPTPath || (isSocialPath && isSmallScreen)) && (
        <>
          <IconButton
            className="fade-in-delay"
            onClick={() => {
              toggleChatSidebar();
            }}
            sx={{
              opacity: 1,
              position: "fixed",
              top: 5,
              zIndex: 5030,
              left: isSmallScreen ? "10px" : "65px",
            }}
          >
            <MenuIcon 
              sx={{ 
                fontSize: 24,
                color: uiSettings.clientColor,
              }}
            />
          </IconButton>
          {isChatGPTPath && (
            <ChatSidebar
              sx={{
                zIndex: 1,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Sidebar;
