import React, { useState, useEffect } from "react";
import { Fab, useMediaQuery } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import CloseIcon from "@mui/icons-material/Close";
import SearchGPT from './SearchGPT';

const ChatFabPopup = ({ theme }) => {
  const [chatOpen, setChatOpen] = useState(false);
  const [enlarged, setEnlarged] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [fadeUp, setFadeUp] = useState(false);
  const [fadeOverlay, setFadeOverlay] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFabClick = () => {
    if (!enlarged) {
      setEnlarged(true);
      setChatOpen(true);
      setFadeOverlay(true);

    } else {
      setEnlarged(false);
      setChatOpen(false);
      setShowPopup(false);
      setFadeUp(false);
      setFadeOverlay(false);

    }
  };

  const handleCloseOverlay = () => {
    setFadeOverlay(false);
    setTimeout(() => {
      setEnlarged(false);
      setChatOpen(false);
      setShowPopup(false);
      setFadeUp(false);
    }, 0);
  };

    useEffect(() => {
        const circleBG = document.querySelector('.circleBG');
        if (enlarged) {
            setTimeout(() => {
                isSmallScreen ? circleBG.style.transform = "scale(.4)" : circleBG.style.transform = "scale(.8)"
                setShowPopup(true);
                setTimeout(() => setFadeUp(true), 50);
                circleBG.classList.add('enlarged');
                circleBG.classList.remove('closing');
                circleBG.style.opacity = "1";
            }, 0);
        } else {
            //circleBG.style.opacity = "1";
            circleBG.style.transform = "scale(.0001)";
            circleBG.classList.remove('enlarged');
            circleBG.classList.add('closing');
        }
    }, [enlarged]);

  return (
    <>

    <div
        onClick={handleCloseOverlay}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 7500,
          pointerEvents: fadeOverlay ? 'auto' : 'none',
          opacity: fadeOverlay ? 1 : 0,
          transition: 'opacity .3s ease',
        }}
      />


      <Fab
        color="primary"
        aria-label="chat"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 10000,
        }}
        sx={{ boxShadow: 20 }}
        onClick={handleFabClick}
      >
        {enlarged ? (
          <CloseIcon
            sx={{
              height: '24px',
              width: '24px',
              color: '#000000',
              zIndex: 10001,
            }}
          />
        ) : (
          <SmsIcon
            sx={{
              height: '24px',
              width: '24px',
              color: '#000000',
              zIndex: 10001,
            }}
          />
        )}

        <div className={`circleBG ${enlarged ? 'enlarged' : ''}`}></div>
      </Fab>

      {showPopup && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '500px',
            zIndex: 10003,
            opacity: fadeUp ? 1 : 0, 
            transform: fadeUp ? 'translateY(0)' : 'translateY(0)',
            transition: 'opacity 0.5s ease, transform 0.5s ease',
            pointerEvents: 'auto',
          }}
        >
          <SearchGPT
            onClose={handleCloseOverlay}
          />
        </div>
      )}
    </>
  );
};

export default ChatFabPopup;
