import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import Post from "@components/social/Post";
import { Box, Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { useUser } from "@context/UserContext";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "@context/ThemeContext";

const SinglePostView = () => {
    const { apiCall } = useApiWithAuth();
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { user, isAdmin } = useUser();
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const { postId } = useParams();
    const { darkMode } = useThemeContext();
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

    useEffect(() => {
        const fetchPost = async () => {
            try {
                setIsLoading(true);
                const response = await apiCall(
                    "POST",
                    `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/post`,
                    {
                        email: user.email,
                        postId,
                    },
                );

                setPost(response.post);
            } catch (err) {
                console.error("Error fetching post:", err);
                setError("Failed to fetch post.");
            } finally {
                setIsLoading(false);
            }
        };

        if (user?.email) {
            fetchPost();
        }
    }, [postId]);

    // Observer callback for video playback
    useEffect(() => {
        if (!videoRef.current) return;

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    videoRef.current
                        .play()
                        .catch((error) => console.error("Error playing video:", error));
                } else {
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersect, {
            threshold: 0.75,
        });

        observer.observe(videoRef.current);

        return () => observer.disconnect();
    }, [post]);

    const handleDelete = async (postId) => {
        try {
            await apiCall(
                "DELETE",
                `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/posts/${postId}`,
            );
            navigate("/"); // Or wherever your posts list is
        } catch (err) {
            console.error("Error deleting post:", err);
        }
    };

    const handleArchive = async (postId) => {
        try {
            await apiCall(
                "POST",
                `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/posts/${postId}/archive`,
            );
            navigate("/");
        } catch (err) {
            console.error("Error archiving post:", err);
        }
    };

    // Handle invalid media URLs
    const isValidMediaUrl = (url) => {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
        } catch {
            return false;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                width: '100%',
                backgroundColor: darkMode ? "#121212" : "#f0f2f5",
            }}
        >
            <Box
                className="posts-container"
                sx={{
                    overflowY: isDesktop ? 'unset' : 'scroll',
                    display: isDesktop ? 'flex' : 'block',
                    flexDirection: isDesktop ? 'column' : 'unset',
                    alignItems: isDesktop ? 'center' : 'unset',
                    scrollSnapType: isDesktop ? 'none' : 'y mandatory',
                    width: isDesktop ? '400px' : '100vw',
                    height: isDesktop ? 'auto' : '100dvh',
                    "& .post-item": {
                        marginBottom: isDesktop ? '30px' : '0',
                    },
                }}
            >
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography color="error">{error}</Typography>
                    </Box>
                ) : !post ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography>Post not found</Typography>
                    </Box>
                ) : (
                    <Box
                        className="post-item"
                        sx={{
                            scrollSnapAlign: 'center',
                        }}
                    >
                        <Post
                            title={post.title}
                            liked={post.liked}
                            description={post.description}
                            postId={post.id}
                            userUserId={post.user_id}
                            email={user.email}
                            postOwnerEmail={post.email}
                            media_url={isValidMediaUrl(post.media_url) ? post.media_url : ""}
                            profileImage={post.profile_image}
                            name={post.name}
                            timestamp={post.created_at}
                            initialLikes={post.likes_count}
                            mediaType={post.media_type}
                            videoRef={videoRef}
                            onArchive={() => handleArchive(post.id)}
                            onDelete={handleDelete}
                            user={user}
                            isArchived={post.is_archived}
                            refreshPosts={() => {}} // No need to refresh in single post view
                            handleClickOpen={() => {}} // Add if needed
                            postView={true} // Add this prop to indicate it's a single post view
                            isAdmin={isAdmin}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SinglePostView;
