import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import { ExpandMore, ExpandLess, Folder } from "@mui/icons-material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";

const ImportSharePointModal = ({
  open,
  handleClose,
  onSiteSelect,
  sources,
}) => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedSites, setExpandedSites] = useState({});
  const { apiCall } = useApiWithAuth();

  const existingSourceDescriptions = useMemo(() => {
    return new Set(
      sources.flatMap((source) => [source.rag_sources.folder_name]),
    );
  }, [sources]);

  useEffect(() => {
    if (open) {
      fetchSites();
    }
  }, [open]);

  useEffect(() => {
    const filtered = sites.filter((site) =>
      site.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredSites(filtered);
  }, [searchTerm, sites]);

  const fetchSites = async () => {
    try {
      setLoading(true);
      const response = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/sharepoint/fetch-sites`,
      );

      if (response && response.success && Array.isArray(response.sites)) {
        const newSites = response.sites
          .map((site) => {
            const filteredFolders = site.folders.filter(
              (folder) => !existingSourceDescriptions.has(folder.name),
            );

            return {
              ...site,
              folders: filteredFolders,
            };
          })
          .filter((site) => site.folders.length > 0); // Filter out sites with no folders

        setSites(newSites);
        setFilteredSites(newSites);
      } else {
        setError("Failed to load sites: Invalid response");
      }
    } catch (err) {
      setError("Error fetching sites: " + (err.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  const handleItemToggle = (siteId, folderId = null) => {
    setSelectedItems((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (folderId) {
        if (!newSelected[siteId]) {
          newSelected[siteId] = { folders: {} };
        }
        newSelected[siteId].folders[folderId] =
          !newSelected[siteId].folders[folderId];
      } else {
        if (newSelected[siteId]?.allSelected) {
          delete newSelected[siteId];
        } else {
          const site = sites.find((s) => s.id === siteId);
          newSelected[siteId] = {
            allSelected: true,
            folders: Object.fromEntries(
              site.folders.map((folder) => [folder.id, true]),
            ),
          };
        }
      }
      return newSelected;
    });
  };

  const handleSiteExpand = (siteId) => {
    setExpandedSites((prev) => ({ ...prev, [siteId]: !prev[siteId] }));
  };

  const handleImport = () => {
    const formattedSelection = sites
      .filter((site) => selectedItems[site.id])
      .map((site) => {
        const selectedFolders = site.folders.filter(
          (folder) =>
            selectedItems[site.id].allSelected ||
            selectedItems[site.id].folders[folder.id],
        );
        return {
          id: site.id,
          name: site.name,
          description: site.description,
          selectedFolders: selectedFolders.map((folder) => ({
            id: folder.id,
            name: folder.name,
            web_url: folder.web_url,
          })),
        };
      })
      .filter((site) => site.selectedFolders.length > 0);

    if (formattedSelection.length > 0) {
      onSiteSelect(formattedSelection);
      setSelectedItems({});
      handleClose();
      setExpandedSites({}); // Reset expandedSites after import
    } else {
      setError("Please select at least one folder to import.");
    }
  };

  const isSiteFullySelected = (siteId) => {
    return selectedItems[siteId]?.allSelected || false;
  };

  const isFolderSelected = (siteId, folderId) => {
    return (
      selectedItems[siteId]?.allSelected ||
      selectedItems[siteId]?.folders[folderId] ||
      false
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          maxHeight: "80vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Import Sites from SharePoint
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Select sites or individual folders to import. Changes will be synced
          daily.
        </Typography>

        <TextField
          fullWidth
          label="Search Sites"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ my: 2 }}
        />

        {loading ? (
          <Typography>Loading sites...</Typography>
        ) : filteredSites.length === 0 ? (
          <Typography>No new sites available to import.</Typography>
        ) : (
          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {filteredSites.map((site) => (
              <React.Fragment key={site.id}>
                <ListItem
                  dense
                  button
                  onClick={() => handleItemToggle(site.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isSiteFullySelected(site.id)}
                      indeterminate={
                        !isSiteFullySelected(site.id) && selectedItems[site.id]
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={site.name}
                    secondary={site.description}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSiteExpand(site.id);
                    }}
                  >
                    {expandedSites[site.id] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItem>
                <Collapse
                  in={expandedSites[site.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {site.folders.map((folder) => (
                      <ListItem
                        key={folder.id}
                        dense
                        button
                        onClick={() => handleItemToggle(site.id, folder.id)}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isFolderSelected(site.id, folder.id)}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <Folder fontSize="small" sx={{ mr: 1 }} />
                        <ListItemText primary={folder.name} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        )}

        {error && <Typography color="error">{error}</Typography>}

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            onClick={handleImport}
            variant="contained"
            color="primary"
            disabled={loading || Object.keys(selectedItems).length === 0}
          >
            Import Selected
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImportSharePointModal;
