import React from "react";
import { Link, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  LinearProgress,
  Breadcrumbs,
  useTheme,
  Container,
} from "@mui/material";

import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector as MuiTimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

import {
  Insights as InsightsIcon,
  Memory as MemoryIcon,
  Timeline as TimelineIcon,
  Assessment as AssessmentIcon,
  Layers as LayersIcon,
  Event as EventIcon,
  AssignmentInd as AssignmentIcon,
  DesignServices as DesignIcon,
  Build as BuildIcon,
  Done as DoneIcon,
  Description as DocumentIcon,
} from "@mui/icons-material";

import Divider from "../components/common/Divider";

const TimelineConnector = styled(MuiTimelineConnector)(({ theme }) => ({
  height: "50px",
}));

const TaskDetails = ({ darkMode }) => {
  const theme = useTheme();

  const { taskId } = useParams();

  const task = {
    id: taskId,
    name: "Build a landing page for a new product",
    createdBy: "John Doe",
    createdDate: "Sep 14, 2023",
    description:
      "We're launching a new product next month and need a landing page to go with it. The landing page should be in line with our brand and showcase the product's key features.",
    status: "In progress",
    completionDate: "Oct 5, 2023",
    type: "Design",
    duration: "2 weeks",
  };

  const aiItems = [
    {
      title: "Predictive AI Insights",
      icon: <InsightsIcon fontSize="large" />,
    },
    { title: "Machine Learning Models", icon: <MemoryIcon fontSize="large" /> },
    {
      title: "Neural Network Training",
      icon: <TimelineIcon fontSize="large" />,
    },
    {
      title: "Data Science Analysis",
      icon: <AssessmentIcon fontSize="large" />,
    },
    {
      title: "Deep Learning Algorithms",
      icon: <LayersIcon fontSize="large" />,
    },
  ];

  const taskDetails = [
    { label: "ID", value: `#${task.id}` },
    { label: "Name", value: task.name },
    { label: "Description", value: task.description },
    { label: "Status", value: task.status },
    { label: "Completion Date", value: task.completionDate },
    { label: "Type", value: task.type },
    { label: "Duration", value: task.duration, span: 2 },
  ];

  const timelineEvents = [
    {
      label: "Task created",
      time: "Sep 14, 2023",
      icon: <EventIcon />,
      role: "Conductor",
    },
    {
      label: "Tasks assigned to roles",
      time: "Sep 15, 2023",
      icon: <AssignmentIcon />,
      role: "Conductor",
    },
    {
      label: "Defines Strategy",
      time: "Sep 16, 2023",
      icon: <EventIcon />,
      role: "Strategist",
    },
    {
      label: "Find images and Layouts",
      time: "Sep 17, 2023",
      icon: <DesignIcon />,
      role: "Designer",
    },
    {
      label: "Assemble assets into final",
      time: "Sep 18, 2023",
      icon: <BuildIcon />,
      role: "Production",
    },
    {
      label: "Task completed",
      time: "Sep 19, 2023",
      icon: <DoneIcon />,
      role: "Conductor",
    },
  ];

  const analyticsData = [
    { label: "Result Rating", value: "9.8/10", change: "+19%" },
    { label: "Performance Metrics", value: 120, change: "+12%" },
    { label: "Predictive Insights", value: "92%", change: "-8%" },
  ];

  const taskScoringData = [
    { label: "Quality", value: 80 },
    { label: "Creativity", value: 70 },
    { label: "Efficiency", value: 90 },
    { label: "Communication", value: 85 },
  ];

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Box className="relative flex flex-col mb-4 text-white overflow-x-hidden">
          <Box className="flex flex-col">
            <Box className="flex flex-1 justify-center">
              <Paper
                elevation={0}
                style={{ backgroundColor: "transparent" }}
                className="flex flex-col max-w-[960px] flex-1 p-4"
              >
                <Box className="flex flex-wrap justify-between gap-3 mb-4">
                  <Box className="flex min-w-72 flex-col gap-3">
                    <Breadcrumbs
                      separator={<NavigateNextIcon fontSize="small" />}
                      aria-label="breadcrumb"
                    >
                      <Link
                        to="/history"
                        style={{
                          textDecoration: "none",
                          color: darkMode ? "#FF9800" : "#3c4753",
                        }}
                      >
                        Task History
                      </Link>
                      <Typography color="textPrimary">Task Overview</Typography>
                    </Breadcrumbs>
                    <Typography variant="overline" color="textSecondary">
                      Task: #{task.id}
                    </Typography>
                    <Typography variant="h4" component="h1" fontWeight="bold">
                      {task.name}
                    </Typography>
                    <Typography color="textSecondary">
                      Created by {task.createdBy} on {task.createdDate}
                    </Typography>
                  </Box>
                </Box>

                <Divider />

                <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                  {taskDetails.map((item, index) => (
                    <Grid item xs={12} sm={item.span ? 12 : 6} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          padding: "0 16px 16px 16px",
                        }}
                      >
                        <Typography color="textSecondary" variant="body2">
                          {item.label}
                        </Typography>
                        <Typography variant="body2">{item.value}</Typography>
                      </Box>
                      <Divider />
                    </Grid>
                  ))}
                </Grid>

                <Divider />

                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    fontWeight="bold"
                    pb={3}
                  >
                    Analytics & Insights
                  </Typography>
                  <Box className="flex flex-wrap gap-4 p-4">
                    {analyticsData.map((item, index) => (
                      <Paper
                        elevation={0}
                        key={index}
                        sx={{
                          flex: "1 1 158px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 2,
                          borderRadius: "8px",
                          boxShadow: "none",
                        }}
                      >
                        <Box
                          sx={{
                            padding: 0,
                            "&:last-child": { paddingBottom: 0 },
                          }}
                        >
                          <Box className="flex justify-between items-center">
                            <Typography
                              variant="overline"
                              className="font-medium"
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={`${item.change.startsWith("+") ? "text-green-500" : "text-red-500"}`}
                            >
                              {item.change}
                            </Typography>
                          </Box>
                          <Typography variant="h3" className="font-bold">
                            {item.value}
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Box>

                <Box>
                  <Divider />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Typography
                          variant="h5"
                          component="h2"
                          fontWeight="bold"
                          pb={3}
                          pt={5}
                        >
                          Task Timeline
                        </Typography>
                        <Timeline sx={{ padding: 0 }}>
                          {timelineEvents.map((event, index) => (
                            <TimelineItem
                              key={index}
                              sx={{ "&::before": { display: "none" } }}
                            >
                              <TimelineSeparator>
                                <TimelineDot color="primary">
                                  {event.icon}
                                </TimelineDot>
                                {index < timelineEvents.length - 1 && (
                                  <TimelineConnector />
                                )}
                              </TimelineSeparator>
                              <TimelineContent sx={{ padding: "12px 16px" }}>
                                <Typography variant="body1" fontWeight="bold">
                                  {event.role ? `${event.role}` : ""}
                                </Typography>
                                <Typography variant="body2">
                                  {event.label}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {event.time}
                                </Typography>
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        </Timeline>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box>
                        <Typography
                          variant="h5"
                          component="h2"
                          fontWeight="bold"
                          pb={3}
                          pt={5}
                        >
                          Inputs & Outputs
                        </Typography>
                        {[
                          {
                            title: "Product Brief",
                            description:
                              "A document explaining the product, its key features, and the target audience",
                          },
                          {
                            title: "Design References",
                            description:
                              "A document with wireframes and design inspiration for the landing page",
                          },
                        ].map((item, index) => (
                          <Paper
                            elevation={0}
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              px: 2,
                              minHeight: 72,
                              py: 1,
                              justifyContent: "space-between",
                              mb: 2,
                              borderRadius: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 1,
                                  width: 60, // Ensuring the width and height are equal
                                  height: 60,
                                  minWidth: 60, // Ensure the box doesn't shrink
                                }}
                              >
                                <DocumentIcon fontSize="large" sx={{}} />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="body1" fontWeight="medium">
                                  {item.title}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {item.description}
                                </Typography>
                              </Box>
                            </Box>
                            <Button variant="contained" color="primary">
                              View
                            </Button>
                          </Paper>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Typography
                  variant="h5"
                  component="h2"
                  fontWeight="bold"
                  pb={3}
                  pt={5}
                >
                  Task Workflow
                </Typography>

                <Box className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 mb-4">
                  <Box
                    className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                    style={{
                      backgroundImage: "url('../assets/images/brief1.png')",
                    }}
                  ></Box>
                </Box>

                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    fontWeight="bold"
                    pb={3}
                    pt={5}
                  >
                    AI & Machine Learning Integration
                  </Typography>

                  <Box className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 mb-4">
                    {aiItems.map((item, index) => (
                      <Paper
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderWidth: "0px",
                          borderRadius: "8px",
                          padding: "16px",
                        }}
                        variant="outlined"
                      >
                        <Box sx={{ mr: 2 }}>{item.icon}</Box>
                        <Box
                          sx={{
                            padding: 0,
                            "&:last-child": { paddingBottom: 0 },
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            sx={{ marginBottom: 0 }}
                          >
                            {item.title}
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Box>

                <Box className="relative flex mb-4 flex-col overflow-x-hidden">
                  <Box>
                    <Typography
                      variant="h5"
                      component="h2"
                      fontWeight="bold"
                      pb={3}
                      pt={5}
                    >
                      Task Scoring & Feedback
                    </Typography>
                    {taskScoringData.map((item, index) => (
                      <Paper
                        elevation={0}
                        key={index}
                        className="flex flex-col gap-3 mb-4 p-4"
                      >
                        <Box className="flex gap-6 justify-between">
                          <Typography variant="body1" fontWeight="medium">
                            {item.label}
                          </Typography>
                          <Typography variant="body2">{item.value}%</Typography>
                        </Box>
                        <Box>
                          <LinearProgress
                            variant="determinate"
                            value={item.value}
                            sx={{
                              height: 10,
                              borderRadius: 5,
                            }}
                          />
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TaskDetails;
