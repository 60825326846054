import { useState, useEffect } from 'react';

export const useConfluence = (acquireToken) => {
    const [haveConfluenceToken, setHaveConfluenceToken] = useState(false);

    useEffect(() => {
        const checkForConfluenceToken = async () => {
            try {
                const token = await acquireToken(undefined, "id");
                const response = await fetch(`${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/connect/confluence/check-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ integration: 'Confluence' }),
                });

                const data = await response.json();
                console.log('Check token response:');

                if (data.accessToken) {
                    setHaveConfluenceToken(true);
                }
            } catch (error) {
                console.error('Error checking for Confluence token:', error);
            }
        };

        checkForConfluenceToken();
    }, [acquireToken]);

    return { haveConfluenceToken, setHaveConfluenceToken };
};

export const fetchConfluencePages = async (accessToken, spaceKey = '') => {
    try {
        const url = `${process.env.CONFLUENCE_API_URL}/wiki/rest/api/content?expand=body.storage&spaceKey=${spaceKey}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch Confluence pages: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched Confluence pages:', data);

        // Return the relevant pages or empty array if none found
        return data.results || [];
    } catch (error) {
        console.error("Error fetching Confluence pages:", error);
        return [];
    }
};