import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useSharePoint } from '@hooks/useSharePoint';
import { useConfluence } from '@hooks/useConfluence';
import ConfluenceModal from '@components/training/ConfluenceModal';
import { useAuth } from '@context/AuthContext';
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import IntegrationCard from "@components/common/IntegrationCard";
import { initializeMsal, authenticateSharePoint } from '../msalConfig';
import { supabase } from "../supabaseClient";

const Integrations = () => {
    const theme = useTheme();
    const [confluenceModalOpen, setConfluenceModalOpen] = useState(false);
    const { acquireToken } = useAuth();
    const { haveConfluenceToken } = useConfluence(acquireToken);
    const { apiCall } = useApiWithAuth();
    const [currentIntegrations, setCurrentIntegrations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeMsal().catch(console.error);
        fetchCurrentIntegrations();
    }, []);

    const fetchCurrentIntegrations = async () => {
        setIsLoading(true);
        try {
            const { data, error } = await supabase
                .from('rag_integration_connections')
                .select('integration_name');
            console.log("Integration data:", data);
            const integrationNames = data.map(item => item.integration_name);
            setCurrentIntegrations(integrationNames);
        } catch (error) {
            console.error("Error fetching current integrations:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleSharePointConnect = async () => {
        try {
            const { accessToken, tokenType } = await authenticateSharePoint();
            console.log("SharePoint access token:", accessToken);

            const response = await fetch('https://fusion92.sharepoint.com/_api/web', {
                method: 'GET',
                headers: {
                    'Authorization': `${tokenType} ${accessToken}`,
                    'Accept': 'application/json;odata=verbose'
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('SharePoint API Error:', response.status, errorText);
                throw new Error(`SharePoint API request failed: ${response.status}`);
            }

            const data = await response.json();
            console.log("SharePoint data:", data);
        } catch (error) {
            console.error("Error connecting to SharePoint:", error);
        }
    };

    const handleGoogleDriveIntegration = () => {
        console.log("Google Drive integration");
    }

    const handleSalesforceIntegration = () => {
        console.log("Salesforce integration");
    }

    const handleHubspotIntegration = () => {
        console.log("Hubspot integration");
    }

    const handleJiraIntegration = () => {
        console.log("Jira integration");
    }

    const handleGithubIntegration = () => {
        console.log("Github integration");
    }

    const handleZendeskIntegration = () => {
        console.log("Zendesk integration");
    }

    const handleNetSuiteIntegration = () => {
        console.log("NetSuite integration");
    }

    const handleConfluenceLogin = () => {
        const clientId = process.env.REACT_APP_CONFLUENCE_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_CONFLUENCE_REDIRECT_URI;
        const state = Math.random().toString(36).substring(2); // A random state to track the request
        const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=read:content:confluence read:space-details:confluence read:content-details:confluence read:page:confluence read:attachment:confluence read:custom-content:confluence read:space:confluence read:content.metadata:confluence offline_access&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&response_type=code&prompt=consent`;
        console.log('Opening Confluence login popup');
        const popup = window.open(authUrl, 'ConfluenceLogin', 'width=640,height=600');

        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
            alert('Popup was blocked. Please allow popups for this site.');
            return;
        }

        const popupCheckInterval = setInterval(() => {
            try {
                // Detect if the popup is closed
                if (popup.closed) {
                    clearInterval(popupCheckInterval);
                    console.log('Popup closed by user');
                    return;
                }

                // Check if the URL has changed to include the authorization code
                if (popup.location.href.includes('code=')) {
                    const urlParams = new URLSearchParams(popup.location.search);
                    const authCode = urlParams.get('code');
                    const stateFromUrl = urlParams.get('state');

                    if (authCode && stateFromUrl === state) {
                        console.log('Received auth code:', authCode);
                        window.postMessage({ authCode }, window.location.origin);
                        popup.close();
                        clearInterval(popupCheckInterval);
                        sendAuthCodeToBackend(authCode);
                    }
                }
            } catch (error) {
                if (error instanceof DOMException && error.name === 'SecurityError') {
                    console.log('Cross-origin error, waiting for redirect...');
                } else {
                    console.error('Unexpected error:', error);
                }
            }
        }, 500);
    };


    const sendAuthCodeToBackend = async (authCode) => {
        console.log("Sending auth code to backend:", authCode);
        try {
            const response = await apiCall(
                "POST",
                `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/confluence/exchange-auth-code`,
                {
                    authCode: authCode
                }
            );

            console.log('Raw response:', response); // Log the raw response

            let data;
            if (typeof response === 'string') {
                try {
                    data = JSON.parse(response);
                } catch (error) {
                    console.error('Error parsing response:', error);
                    throw new Error('Invalid response from server');
                }
            } else if (typeof response === 'object') {
                data = response;
            } else {
                throw new Error('Unexpected response type from server');
            }

            if (data.error) {
                throw new Error(data.error);
            }

            console.log('Confluence connection successful:', data);
            // Update UI to show Confluence is connected
            // For example:
            // setHaveConfluenceToken(true);
        } catch (error) {
            console.error('Error exchanging auth code:', error);
            // Show error message to user
            // For example:
            // setErrorMessage(error.message);
        }
    };

    const integrations = [
        {
            title: "Connect SharePoint",
            description: "SharePoint integration allows seamless document sharing and collaboration.",
            icon: "https://www.svgrepo.com/show/452068/ms-sharepoint.svg",
            onClick: handleSharePointConnect,
            integrationName: "SharePoint"
        },
        {
            title: "Connect Confluence",
            description: "Confluence integration allows centralized document management.",
            icon: "https://www.svgrepo.com/show/353597/confluence.svg",
            onClick: !haveConfluenceToken ? handleConfluenceLogin : null,
            integrationName: "Confluence"
        },
        {
            title: "Connect Google Drive",
            description: "Google Drive integration for cloud storage and file sharing.",
            icon: "https://cdn.worldvectorlogo.com/logos/google-drive.svg",
            onClick: handleGoogleDriveIntegration,
            integrationName: "Google"
        },
        {
            title: "Connect Salesforce",
            description: "Salesforce integration for CRM and customer relationship management.",
            icon: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg",
            onClick: handleSalesforceIntegration,
            integrationName: "Salesforce"
        },
        {
            title: "Connect HubSpot",
            description: "HubSpot integration for marketing automation and sales.",
            icon: "https://cdn.worldvectorlogo.com/logos/hubspot-1.svg",
            onClick: handleHubspotIntegration,
            integrationName: "HubSpot"
        },
        {
            title: "Connect Jira",
            description: "Jira integration for agile project management.",
            icon: "https://cdn.worldvectorlogo.com/logos/jira-3.svg",
            onClick: handleJiraIntegration,
            integrationName: "Jira"
        },
        {
            title: "Connect GitHub",
            description: "GitHub integration for source code management and collaboration.",
            icon: "https://cdn.iconscout.com/icon/free/png-256/free-github-logo-icon-download-in-svg-png-gif-file-formats--70-flat-social-icons-color-pack-logos-432516.png?f=webp&w=256",
            onClick: handleGithubIntegration,
            integrationName: "GitHub"
        },
        {
            title: "Connect Zendesk",
            description: "Zendesk integration for customer support and ticketing.",
            icon: "https://cdn.worldvectorlogo.com/logos/zendesk.svg",
            onClick: handleZendeskIntegration,
            integrationName: "Zendesk"
        },
        {
            title: "Connect NetSuite",
            description: "NetSuite integration for project management and billing.",
            icon: "https://cdnlogo.com/logos/n/3/netsuite.svg",
            onClick: handleNetSuiteIntegration,
            integrationName: "NetSuite"
        },
    ];

    const futureIntegrations = [
        {
            title: "Aderant",
            description: "Legal practice and financial management software."
        },
        {
            title: "Adobe Experience Manager",
            description: "Digital experience and content management platform."
        },
        {
            title: "Alfresco",
            description: "Enterprise content management and business process platform."
        },
        {
            title: "Amazon Aurora",
            description: "Cloud-native relational database engine."
        },
        {
            title: "Amazon RDS",
            description: "Managed relational database service."
        },
        {
            title: "Amazon S3",
            description: "Scalable cloud storage service."
        },
        {
            title: "Azure Active Directory (AD)",
            description: "Cloud-based identity and access management service."
        },
        {
            title: "Azure Blob Storage",
            description: "Microsoft's object storage solution for the cloud."
        },
        {
            title: "Azure SQL Database",
            description: "Intelligent, scalable cloud database service."
        },
        {
            title: "Bentley",
            description: "Infrastructure engineering software solutions."
        },
        {
            title: "Bing Search Services",
            description: "Including Bing News and Web Search APIs."
        },
        {
            title: "Contentful",
            description: "API-first content management platform."
        },
        {
            title: "CuadraSTAR",
            description: "Knowledge management and library automation system."
        },
        {
            title: "Deltek",
            description: "Project-based ERP and professional services solutions."
        },
        {
            title: "DISQOVER",
            description: "Data discovery and integration platform."
        },
        {
            title: "Egnyte",
            description: "Cloud content security and governance platform."
        },
        {
            title: "Elite / 3E",
            description: "Legal business management solution."
        },
        {
            title: "EMC eRoom",
            description: "Virtual workspace collaboration platform."
        },
        {
            title: "File Share",
            description: "Network file sharing system integration."
        },
        {
            title: "Google Cloud SQL",
            description: "Fully-managed relational database service."
        },
        {
            title: "HP Enterprise Solutions",
            description: "Including Consolidated Archive and Records Manager."
        },
        {
            title: "IBM Suite",
            description: "Including Connections, Content Manager, Db2, FileNet P8, and WebSphere."
        },
        {
            title: "iManage Solutions",
            description: "Including Cloud and Work platforms for document management."
        },
        {
            title: "iMednet",
            description: "Clinical research platform."
        },
        {
            title: "Jive",
            description: "Collaboration software platform."
        },
        {
            title: "Kaltura",
            description: "Video platform and streaming solutions."
        },
        {
            title: "LDAP",
            description: "Lightweight Directory Access Protocol integration."
        },
        {
            title: "LegalKEY",
            description: "Records management system for legal professionals."
        },
        {
            title: "LexisNexis InterAction",
            description: "CRM solution for professional services."
        },
        {
            title: "Litera Foundation",
            description: "Document and transaction management platform."
        },
        {
            title: "Lotus Notes Databases",
            description: "Enterprise collaboration and email platform."
        },
        {
            title: "M-Files",
            description: "Intelligent information management system."
        },
        {
            title: "MediaPlatform PrimeTime",
            description: "Enterprise video content management."
        },
        {
            title: "Microsoft Enterprise Suite",
            description: "Including Dynamics 365, Exchange, Teams, and Academic services."
        },
        {
            title: "MySQL",
            description: "Open-source relational database management system."
        },
        {
            title: "Nasuni",
            description: "Cloud file storage platform."
        },
        {
            title: "NCBI Protein",
            description: "Biological sequence database."
        },
        {
            title: "NetDocuments",
            description: "Cloud-based document management system."
        },
        {
            title: "Neudesic The Firm Directory",
            description: "Enterprise expertise location system."
        },
        {
            title: "Nuxeo",
            description: "Content services platform."
        },
        {
            title: "Objective",
            description: "Information and process governance platform."
        },
        {
            title: "OneDrive for Business",
            description: "Cloud storage and synchronization service."
        },
        {
            title: "OpenText Solutions",
            description: "Including Documentum, Content Server, and eDOCS DM."
        },
        {
            title: "Oracle Solutions",
            description: "Including Database, WebCenter, and Content Management systems."
        },
        {
            title: "PostgreSQL",
            description: "Open-source object-relational database system."
        },
        {
            title: "Practical Law",
            description: "Legal know-how and workflow solution."
        },
        {
            title: "ProLaw",
            description: "Legal practice management software."
        },
        {
            title: "PubChem",
            description: "Chemistry database platform."
        },
        {
            title: "PubMed",
            description: "Biomedical literature database."
        },
        {
            title: "RightFind",
            description: "Content workflow solution."
        },
        {
            title: "SAP Solutions",
            description: "Including ERP, Cloud, and HANA integrations."
        },
        {
            title: "ServiceNow",
            description: "Cloud-based workflow management system."
        },
        {
            title: "SharePoint Legacy",
            description: "Including SharePoint 2010-2019 versions."
        },
        {
            title: "Sitecore",
            description: "Digital experience and content management platform."
        },
        {
            title: "SQL-based CRM Systems",
            description: "Various SQL-based customer relationship management systems."
        },
        {
            title: "UniProt",
            description: "Protein sequence and functional information database."
        },
        {
            title: "Veeva Vault",
            description: "Life sciences cloud enterprise platform."
        },
        {
            title: "Veritas Enterprise Vault",
            description: "Information archiving platform."
        },
        {
            title: "Website Crawler",
            description: "Custom web content extraction solution."
        },
        {
            title: "West km",
            description: "Knowledge management system for legal professionals."
        },
        {
            title: "Workplace by Facebook",
            description: "Enterprise collaboration platform."
        },
        {
            title: "Xerox DocuShare",
            description: "Content management platform."
        },
        {
            title: "Yammer",
            description: "Enterprise social networking service."
        }
    ].sort((a, b) => a.title.localeCompare(b.title));


    return (
        <Box className="fade-in p-4 pt-20 pb-20">
            <Container maxWidth="lg" disableGutters>
                <Paper elevation={theme.elevation} sx={{ padding: theme.spacing(4), borderRadius: theme.shape.borderRadius }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Integrations
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Manage your SharePoint and Confluence integrations.
                    </Typography>

                    <Grid container spacing={2} alignItems="stretch" sx={{ mt: 4 }}>
                        {integrations.map((integration, index) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                <IntegrationCard
                                    title={integration.title}
                                    description={integration.description}
                                    icon={integration.icon}
                                    onClick={integration.onClick}
                                    disabled={isLoading || currentIntegrations.includes(integration.integrationName)}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h5" component="h2" sx={{ mt: 8, mb: 2 }}>
                        Future Integrations
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        These integrations are coming soon to enhance your experience.
                    </Typography>

                    <Grid container spacing={2} alignItems="stretch" sx={{ mt: 2 }}>
                        {futureIntegrations.map((integration, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        bgcolor: 'action.hover',
                                        opacity: 1
                                    }}
                                >
                                    <Typography variant="subtitle1" component="h3">
                                        {integration.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {integration.description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Confluence Modal */}
                    <ConfluenceModal
                        confluenceModalOpen={confluenceModalOpen}
                        setConfluenceModalOpen={setConfluenceModalOpen}
                    />

                </Paper>
            </Container>
        </Box>
    );
};

export default Integrations;

// Modal styling
const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};
