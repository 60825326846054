import React from 'react';
import Joyride from 'react-joyride';

const CustomJoyride = ({ 
  steps, 
  run, 
  stepIndex, 
  handleJoyrideCallback 
}) => {
  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      spotlightPadding={5}
      disableOverlayClose
      hideBackButton
      disableScrolling
      hideBeacon={true}
      hideCloseButton
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
      styles={{
        options: {
          zIndex: 10000,
          beaconSize: 45,
          primaryColor: "#FF9800",
          textColor: "#000",
          overlayColor: "rgba(0, 0, 0, 0.7)",
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
        },
        tooltip: {
          maxWidth: "100%",
          padding: 15,
          width: "350px",
          textAlign: "left",
        },
        tooltipFooter: {
          marginTop: 0,
        },
        tooltipContent: {
          padding: "10px 10px",
        },
        buttonNext: {
          padding: 12,
        },
        tooltipTitle: {
          margin: 0,
          fontWeight: "bold",
          fontSize: 24,
          padding: "10px 0 0 10px",
        },
        tooltipContainer: {
          textAlign: "left",
        },
        beaconInner: {
          backgroundColor: "rgba(255, 152, 0, 1)",
        },
        beaconOuter: {
          backgroundColor: "rgba(255, 152, 0, 0.3)",
          border: "1px rgba(255, 152, 0, 1) solid",
        },
        spotlight: {
          borderRadius: "5px",
          boxShadow: "0 0 0 2px #fff",
        },
        buttonNext: {
          backgroundColor: "#FF9800",
        },
        buttonBack: {
          color: "#FF9800",
        },
      }}
    />
  );
};

export default CustomJoyride;
