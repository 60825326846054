import React, { useState } from 'react';
import { Button, Box, CardContent, Typography, Rating, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { PlayArrow as PlayIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { isAndroidOrIOS } from '../../utils/deviceDetection';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import { supabase } from "../../supabaseClient";
import { useUser } from "@context/UserContext";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const TaskItem = ({ item, onFavoriteToggle }) => {
  const theme = useTheme();
  const iconName = item.icon;
  const isMobile = isAndroidOrIOS();
  const { user } = useUser();
  const [rating, setRating] = useState(item.rating || 0);
  const [isFavorite, setIsFavorite] = useState(item.isFavorite || false);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FF9800',
    },
    '& .MuiRating-iconHover': {
      color: '#FF9800',
    },
  });

  const handleRatingChange = async (event, id, newValue) => {
    event.stopPropagation();

    if (!newValue) {
      return;
    }

    setRating(newValue);

    try {
      const { error } = await supabase
        .from('task_ratings')
        .upsert({
          task_id: item.id,
          user_id: user.email,
          rating: newValue,
        }, { onConflict: ['task_id', 'user_id'] });

      if (error) {
        console.error("Error updating rating:", error);
      }
    } catch (error) {
      console.error("Error updating rating:", error);
    }
  };

  const handleFavoriteToggle = async (event) => {
    event.stopPropagation();
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);

    try {
      if (newFavoriteStatus) {
        // If favoriting, upsert the record
        const { error } = await supabase
          .from('user_favorites')
          .upsert({
            task_id: item.id,
            user_id: user.email,
            is_favorite: true,
          }, { onConflict: ['task_id', 'user_id'] });

        if (error) throw error;
      } else {
        // If unfavoriting, delete the record
        const { error } = await supabase
          .from('user_favorites')
          .delete()
          .match({ task_id: item.id, user_id: user.email });

        if (error) throw error;
      }

      onFavoriteToggle(item.id, newFavoriteStatus);
    } catch (error) {
      console.error("Error updating favorite status:", error);
      // Revert the UI state if the database operation fails
      setIsFavorite(!newFavoriteStatus);
    }
  };

  return (
    <CardContent className="fade-in" style={{ opacity: 0, background: "transparent", display: 'flex', flexDirection: 'column', flexGrow: 1, marginBottom: 5, marginTop: 15, marginLeft: 15, marginRight: 15, padding: 0 }}>
      <IconButton
        onClick={handleFavoriteToggle}
        sx={{
          position: 'absolute',
          top: 15,
          right: 15,
          width: '10px',
          height: '10px',
          transform: 'rotate(0deg)',
          zIndex: 2,
          '& svg': {
            position: 'relative',
            zIndex: 2
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: -15,
            right: -15,
            width: '50px',
            height: '50px',
            transition: 'background-image 0.2s ease-in-out',
            zIndex: 1,
          },
        }}
      >
        {isFavorite ? (
          <FavoriteIcon color="error" />
        ) : (
          <FavoriteBorderIcon />
        )}
      </IconButton>

      <Box sx={{ marginBottom: 2 }}>
        <Icon
          icon={iconName}
          className="icon"
          height="40px"
          width="40px"
          style={{
            color: theme.palette.secondary.main,
            transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
            ...(isMobile
              ? {}
              : { cursor: 'pointer', '&:hover': { transform: 'scale(1.1)', color: theme.palette.primary.main } }),
          }}
        />
      </Box>

      <Typography variant="body1" gutterBottom sx={{ userSelect: 'none' }}>
        {item.title}
      </Typography>

      <Typography variant="body2" color="textSecondary" paragraph sx={{ mb: 2, userSelect: 'none' }}>
        {item.description}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 'auto', mb: 0 }}>
        <Button
          variant='contained'
          sx={{
            width: '100%',
          }}
          startIcon={<PlayIcon style={{ fontSize: 18, marginRight: 0 }} />}
        >
          Start Task
        </Button>

        <Box sx={{ width: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1.5, mb: 1 }}>
          <StyledRating
            id={`rating-${item.id}`}
            name={`rating-${item.id}`}
            size="small"
            value={rating}
            precision={0.5}
            onChange={(event, newValue) => {
              handleRatingChange(event, item.id, newValue);
            }}
            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
          {item.totalVotes > 0 && (
            <Typography variant="body2" color="textSecondary" sx={{ ml: 1, mr: 2 }}>
              ({item.totalVotes})
            </Typography>
          )}
        </Box>
      </Box>
    </CardContent>
  );
};

export default TaskItem;
