import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Skeleton,
  Chip,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import TaskItem from "./TaskItem";
import AddTaskIcon from '@mui/icons-material/AddTask';
import { supabase } from "../../supabaseClient";

const TasksSection = ({
  darkMode,
  theme,
  categories,
  tasksLoaded,
  selectedCategory,
  tasks,
  setTasks,
  isSmallScreen,
  isMobile,
  handleCategorySelect,
  handleSelectTask,
  navigate,
  user,
  track
}) => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    fetchFavorites();
  }, [user]);

  const fetchFavorites = async () => {
    const { data, error } = await supabase
      .from('user_favorites')
      .select('task_id')
      .eq('user_id', user.email)
      .eq('is_favorite', true);

    if (error) {
      console.error("Error fetching favorites:", error);
    } else {
      setFavorites(data.map(fav => fav.task_id));
    }
  };

  const handleFavoriteToggle = (taskId, isFavorite) => {
    setTasks(prevTasks => 
      prevTasks.map(task => 
        task.id === taskId ? { ...task, isFavorite } : task
      )
    );
    
    let newFavorites;
    if (isFavorite) {
      newFavorites = [...favorites, taskId];
    } else {
      newFavorites = favorites.filter(id => id !== taskId);
    }
    setFavorites(newFavorites);

    if (newFavorites.length === 0 && selectedCategory === "My Saved") {
      handleCategorySelect("All");
    }
  };

  return (
    <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
      <Box display="flex" flexDirection="column" height="100%">
        <Typography
          variant="h5"
          mt={0}
          component="h1"
          textAlign={"left"}
          gutterBottom
          sx={{ userSelect: "none" }}
        >
          Task and Automations
        </Typography>
        <hr
          style={{
            width: "100%",
            border: "none",
            borderBottom: darkMode
              ? "1px dashed rgba(255,255,255,.25)"
              : "1px dashed rgba(0,0,0,.25)",
            margin: "0px",
          }}
        />

        {!categories.length > 0 || !tasksLoaded  ? (
          <>
            {/* Skeleton for chips */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                mt: 2,
                mb: 3,
              }}
            >
              {Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={`skeleton-chip-${index}`}
                  variant="rectangular"
                  width={100}
                  height={32}
                  sx={{ borderRadius: 2 }}
                />
              ))}
            </Box>

            {/* Skeletons for tasks */}
            <Grid
              container
              spacing={2}
              className="grid-container"
              sx={{}}
            >
              {Array.from(new Array(9)).map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={`skeleton-${index}`}
                  className="grid-item"
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      position: "relative",
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        border: `1px solid ${theme.palette.border.main}`,
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        height: "100%",
                        minHeight: 250,
                        cursor: "pointer",
                        borderRadius: theme.shape.borderRadius,
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            pointerEvents: "none",
                          }}
                        >
                          <CardContent
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                              minHeight: "100%",
                              padding: 15,
                              margin: 0,
                              pointerEvents: "none",
                            }}
                          >
                            <Skeleton
                              variant="rounded"
                              width={40}
                              height={40}
                              style={{ marginBottom: "16px" }}
                            />
                            <Skeleton
                              variant="rounded"
                              height={22}
                              sx={{ marginBottom: "4px" }}
                            />
                            {!isSmallScreen && (
                              <Skeleton
                                variant="rounded"
                                height={22}
                                width="75%"
                                sx={{ marginBottom: "4px" }}
                              />
                            )}
                            <Skeleton
                              variant="rounded"
                              height={16}
                              width="90%"
                              sx={{
                                marginTop: "4px",
                                marginBottom: "4px",
                              }}
                            />
                            <Skeleton
                              variant="rounded"
                              height={16}
                              width="100%"
                              sx={{
                                marginTop: "0px",
                                marginBottom: "4px",
                              }}
                            />
                            <Skeleton
                              variant="rounded"
                              height={16}
                              width="80%"
                              sx={{
                                marginTop: "0px",
                                marginBottom: "4px",
                              }}
                            />
                            <Skeleton
                              variant="rounded"
                              height={36}
                              width="100%"
                              sx={{ marginTop: "auto" }}
                            />
                          </CardContent>
                        </div>
                      </Box>
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                mt: 2,
                mb: 2,
              }}
            >
              {/* Render "All" category chip */}
              <Chip
                key="chip-all"
                className="fade-in"
                label="All"
                clickable
                color={selectedCategory === "All" ? "primary" : "default"}
                onClick={() => handleCategorySelect("All")}
                sx={{
                  boxShadow: 0,
                  textTransform: "uppercase",
                  borderRadius: 2,
                  cursor: "pointer",
                  fontSize: "14px",
                  paddingY: "15px",
                  paddingX: "0px",
                  border: `1px solid ${theme.palette.border.main}`,
                  backgroundColor:
                    selectedCategory === "All"
                      ? theme.palette.background.paper
                      : theme.palette.background.paper,
                  color:
                    selectedCategory === "All"
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary,
                  "&:hover": {
                    color: theme.palette.black.main,
                  },
                }}
              />

              {/* Render "My Saved" category chip only if there are favorites */}
              {favorites.length > 0 && (
                <Chip
                  key="chip-saved"
                  label="My Saved"
                  clickable
                  color={selectedCategory === "My Saved" ? "primary" : "default"}
                  onClick={() => handleCategorySelect("My Saved")}
                  sx={{
                    boxShadow: 0,
                    textTransform: "uppercase",
                    borderRadius: 2,
                    cursor: "pointer",
                    fontSize: "14px",
                    paddingY: "15px",
                    paddingX: "0px",
                    border: `1px solid ${theme.palette.border.main}`,
                    backgroundColor:
                      selectedCategory === "My Saved"
                        ? theme.palette.background.paper
                        : theme.palette.background.paper,
                    color:
                      selectedCategory === "My Saved"
                        ? theme.palette.primary.contrastText
                        : theme.palette.text.primary,
                    "&:hover": {
                      color: theme.palette.black.main,
                    },
                  }}
                />
              )}

              {/* Render other category chips */}
              {categories.filter(category => category !== "All").map((category, index) => (
                <Chip
                  key={`chip-${index}`}
                  className="fade-in"
                  label={category}
                  clickable
                  color={selectedCategory === category ? "primary" : "default"}
                  onClick={() => handleCategorySelect(category)}
                  sx={{
                    boxShadow: 0,
                    textTransform: "uppercase",
                    borderRadius: 2,
                    cursor: "pointer",
                    fontSize: "14px",
                    paddingY: "15px",
                    paddingX: "0px",
                    border: `1px solid ${theme.palette.border.main}`,
                    backgroundColor:
                      selectedCategory === category
                        ? theme.palette.background.paper
                        : theme.palette.background.paper,
                    color:
                      selectedCategory === category
                        ? theme.palette.primary.contrastText
                        : theme.palette.text.primary,
                    "&:hover": {
                      color: theme.palette.black.main,
                    },
                  }}
                />
              ))}
            </Box>

            <Grid container spacing={2} className="grid-container">
              {(tasks && tasks.length > 0 ? tasks : Array.from(new Array(20)))
                .filter((item) => 
                  item && (
                    selectedCategory === "All" ||
                    item.category === selectedCategory ||
                    (selectedCategory === "My Saved" && favorites.includes(item.id))
                  )
                )
                .map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={4}
                    key={item ? `task-${item.id}` : `skeleton-${index}`}
                    className="grid-item"
                    style={{
                      marginTop: "6px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        position: "relative",
                        overflow: "visible",
                        boxShadow: 0,
                        borderRadius: theme.shape.borderRadius,
                        transition: `all .2s ease-in-out`,
                        border: `1px solid ${theme.palette.border.main}`,

                        ...(isMobile
                          ? {}
                          : {
                              "&:hover": {
                                transform: "scale(1.03)",
                                boxShadow: 10,
                                zIndex: 1,
                                borderRadius: 10,
                                transition: `all .2s ease-in-out`,
                              },
                              "&:hover .cardHover": {
                                borderRadius: 10,
                                backgroundColor:
                                  theme.palette.mode === "dark"
                                    ? "#252525"
                                    : "#FFFFFF",
                                transition: `all .2s ease-in-out`,
                              },
                            }),
                      }}
                    >
                      {/* Background element */}
                      {/* <Box
                        // className={
                        //   index === 0 ? "bestsellerBackBackground" : ""
                        // }
                        style={{
                          display: index === 0 ? "block" : "none",
                          position: "absolute",
                          zIndex: -1,
                          transform: "rotate(-135deg)",
                          width: "120px",
                          height: "20px",
                          background:
                            "linear-gradient(#ec8a12 0%, #ce7c00 100%)",
                          boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
                          top: "35px",
                          right: "-15px",
                          clipPath:
                            "polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px)",
                          pointerEvents: "none",
                        }}
                      ></Box> */}

                      <Card
                        elevation={0}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                          height: "100%",
                          cursor: "pointer",
                          //borderRadius: theme.shape.borderRadius,
                          overflow: "visible",
                          position: "relative",
                          zIndex: 0,
                          transition: `all .2s ease-in-out`,
                        }}
                        className={`cardHover ${index === 0 ? "second-task-item" : ""}`}
                      >
                        <Box
                          sx={{
                            zIndex: 1,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            transition: "all 0.2s ease-in-out 1",
                            "&:hover .icon": {
                              color: !isMobile
                                ? `${theme.palette.primary.main} !important`
                                : "inherit",
                              animation: !isMobile
                                ? "scale-rotate 0.2s ease-in-out 1"
                                : "none",
                              transform: !isMobile ? "scale(1.2)" : "none",
                            },
                          }}
                          onClick={(event) => {
                            if (!event.target.closest('.MuiRating-root')) { 
                              track(item.title, { user: user.email });
                              handleSelectTask(item);
                            }
                          }}
                        >
                        {tasksLoaded && (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                width: "100%",
                                position: "relative",
                                zIndex: 1,
                              }}
                            >
                              <TaskItem 
                                item={{ ...item, isFavorite: favorites.includes(item.id) }} 
                                onFavoriteToggle={handleFavoriteToggle} 
                              />
                            </Box>
                          )}
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              
              {selectedCategory === "All" && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  className="grid-item"
                  style={{
                    marginTop: "6px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      border: `1px solid ${theme.palette.border.main}`,
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      height: "100%",
                      minHeight: "230px",
                      cursor: "pointer",
                      borderRadius: theme.shape.borderRadius,
                      boxShadow: 0,
                      transition: `all .2s ease-in-out`,
                      ...(isMobile
                        ? {}
                        : {
                            "&:hover": {
                              boxShadow: 10,
                              borderRadius: 10,
                              transform: "scale(1.03)",
                              transition: `all .2s ease-in-out`,
                              backgroundColor: theme.palette.primary.main,
                              "& .MuiTypography-root": {
                                color: "#000000",
                              },
                              "& .icon": {
                                color: "#000000",
                              },
                            },
                          }),
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative",
                        elevation: 0,
                        transition: "all 0.2s ease-in-out 1",
                        "&:hover .icon": {
                          color: !isMobile
                            ? `#000000 !important`
                            : "inherit",
                          animation: !isMobile
                            ? "scale-rotate 0.2s ease-in-out 1"
                            : "none",
                          transform: !isMobile ? "scale(1.2)" : "none",
                        },
                      }}
                      onClick={() => navigate("/submit-idea")}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                          pointerEvents: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: isSmallScreen
                            ? theme.palette.primary.main
                            : "transparent",
                        }}
                        className={`task-item-wrapper`}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "100%",
                            padding: 2,
                            margin: 0,
                            textAlign: "center",
                            color: isSmallScreen
                              ? theme.palette.black.main
                              : theme.palette.secondary.main,
                          }}
                        >
                          <AddTaskIcon
                            className="icon"
                            sx={{
                              fontSize: "45px",
                              margin: "10px",
                              color: isSmallScreen
                                ? theme.palette.black.main
                                : theme.palette.secondary.main,
                              transition:
                                "transform 0.2s ease-in-out, color 0.2s ease-in-out",
                            }}
                          />
                          <Typography variant="h6">
                            Submit an Idea
                          </Typography>
                          <Typography variant="body2">
                            Have a great idea, but you don't know how to get
                            started?
                          </Typography>
                        </CardContent>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default TasksSection;
