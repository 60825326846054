import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentClient: null,
  currentClientColor: null,
  messages: [],
};

const chatgptSlice = createSlice({
  name: "chatgpt",
  initialState,
  reducers: {
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },
    setCurrentClientColor: (state, action) => {
      state.currentClientColor = action.payload;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { setCurrentClient, setCurrentClientColor, clearMessages, setMessages } = chatgptSlice.actions;
export default chatgptSlice.reducer;
