// src/MainLayout.js
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { CssBaseline, Box, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useMsal } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useUIContext } from "@context/UIContext";
import { useTheme } from "@mui/material/styles";
import { useUser } from "@context/UserContext";
import { track } from "@vercel/analytics";
import ChatFabPopup from "../common/ChatFabPopup";
import BugReportFab from "../common/Bug/BugReportFab";
import BugReportModal from "../common/Bug/BugReportModal";
import { useDispatch } from "react-redux";
import { supabase } from "../../supabaseClient";
import { useSnackbar } from "@/context/SnackbarContext";
import { useApiWithAuth } from "@/hooks/useApiWithAuth";

const MainLayout = ({ children }) => {
  const { accounts } = useMsal();
  const { user, isAdmin } = useUser();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const { closeSidebar, closeChatSidebar, uiSettings, setUISettings } =
    useUIContext();
  const theme = useTheme();
  const overlayVisible =
    (isSmallScreen && uiSettings.sidebarOpen) || uiSettings.chatSidebarOpen;
  const isErrorPage = location.pathname === "/error";
  const isChatPage = location.pathname.includes("/chatgpt");
  const dispatch = useDispatch();
  const { addSnackbar } = useSnackbar();
  const [bugModalOpen, setBugModalOpen] = useState(false);
  const subscribed = useRef(false);
  const { apiCall } = useApiWithAuth();
  const API_BASE = process.env.REACT_APP_API_MESSAGE_URL;

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (event.target.tagName === "BUTTON" || event.target.tagName === "A") {
        track("Global Click", {
          id: event.target.id || "No ID",
          text: event.target.innerText || "No Text",
          user: user.email,
        });
      }
    };
    document.addEventListener("click", handleGlobalClick);
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, []); 

  const isTokenExpiringSoon = (exp) => {
    const currentTime = Math.floor(Date.now() / 1000);
    return exp - currentTime < 300;
  };

  const collapseSidebar = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (isSmallScreen || isChatPage) {
      setUISettings((prev) => ({ ...prev, sidebarOpen: false }));
    } else {
      setUISettings((prev) => ({ ...prev, sidebarOpen: true }));
    }

    if (!isChatPage) {
      setUISettings((prev) => ({ ...prev, chatSidebarOpen: false }));
    }
  }, [isSmallScreen, isChatPage, setUISettings]);

  const getPageTitle = () => {
    const path = "/" + location.pathname.split("/")[1];
    switch (path) {
      case "/":
      case "/dashboard":
        return "Welcome to Nucleus";
      case "/clients":
        return "Clients";
      case "/taskdetail":
        return "Task Overview";
      case "/history":
        return "Task History";
      case "/actions":
        return "Actions";
      case "/agents":
        return "Agents";
      case "/analytics":
        return "Analytics";
      case "/settings":
        return "Settings";
      case "/submit-idea":
        return "Submit an Idea";
      case "/education":
        return "Education & Research";
      case "/ai-tools":
        return "AI Tools & Resources";
      case "/ainews":
        return "AI News & Trends";
      case "/tasks":
        return "Training Data";
      case "/integrations":
        return "Integrations";
      default:
        return "";
    }
  };

  const hasHeader = location.pathname.includes("/chatgpt") || location.pathname.includes("/social");
  const marginLeft = isErrorPage
    ? "0px"
    : isSmallScreen
      ? location.pathname.includes("/chatgpt") || location.pathname.includes("/social")
        ? "0px"
        : "60px"
      : location.pathname.includes("/chatgpt") || location.pathname.includes("/social")
        ? "60px"
        : "220px";

  const handleBugReportClick = () => {
    setBugModalOpen(true);
  };

  const handleBugModalClose = () => {
    setBugModalOpen(false);
  };

  // After sending a notification, check the DB directly:
  const checkNotification = async () => {
    try {
      const response = await apiCall(
        'GET',
        `${API_BASE}/api/notifications?type=user&user_email=${user.email}`
      );
      console.log("Current notifications in DB:", response);
    } catch (error) {
      console.error('Error checking notifications:', error);
    }
  };

  useEffect(() => {
    if (!user?.email) {
      console.log("⚠️ No user email available for notifications");
      return;
    }
    
    if (!subscribed.current) {
      console.log("🔔 Setting up notification channels for:", user.email);
      subscribed.current = true;
      
      const userChannel = supabase
        .channel('user-notifications')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'notifications_user',
            filter: `user_email=eq.${user.email}`
          },
          async (payload) => {
            console.log("📨 Received user notification payload:", payload);
            const { id, title, description } = payload.new;

            // Delete the notification immediately
            const { error: deleteError } = await supabase
              .from('notifications_user')
              .delete()
              .eq('id', id);

            if (deleteError) {
              console.error("❌ Error deleting user notification:", deleteError);
            } else {
              console.log("✅ User notification deleted:", id);
            }

            // Show the notification in UI
            addSnackbar({
              id,
              title,
              description,
              isGlobal: false,
              notification: payload.new
            });
          }
        )
        .subscribe();

      const globalChannel = supabase
        .channel('global-notifications')
        .on(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'notifications_global'
          },
          (payload) => {
            console.log("🌍 Received global notification payload:", payload);
            const { id, title, description } = payload.new;
            addSnackbar({
              id,
              title,
              description,
              isGlobal: true,
              notification: payload.new
            });
          }
        )
        .subscribe();

      return () => {
        console.log("🔄 Cleaning up notification channels");
        supabase.removeChannel(userChannel);
        supabase.removeChannel(globalChannel);
        subscribed.current = false;
      };
    }
  }, [user?.email]); // Remove addSnackbar from dependencies

  // Separate useEffect for initial global notifications check
  useEffect(() => {
    const checkGlobalNotifications = async () => {
      if (!user?.email || !subscribed.current) return;
      
      console.log("🔍 Checking for unseen global notifications");
      try {
        const { data: seenNotifications } = await supabase
          .from('notifications_global_status')
          .select('global_notification_id')
          .eq('user_email', user.email);
        
        const seenIds = seenNotifications?.map(n => n.global_notification_id) || [];
        
        const { data: globalNotifications, error } = await supabase
          .from('notifications_global')
          .select('*')
          .gt('expires_at', new Date().toISOString())
          .not('id', 'in', `(${seenIds.length > 0 ? seenIds : ['00000000-0000-0000-0000-000000000000']})`)
          .order('created_at', { ascending: false });

        if (error) {
          console.error("❌ Error fetching global notifications:", error);
          return;
        }
        
        globalNotifications?.forEach(notification => {
          addSnackbar({
            id: notification.id,
            title: notification.title,
            description: notification.description,
            isGlobal: true,
            notification
          });
        });
      } catch (error) {
        console.error("❌ Error in global notifications check:", error);
      }
    };

    // Only run once on mount
    checkGlobalNotifications();
  }, [user?.email]); // Remove addSnackbar from dependencies

  // // Enhanced test function with more logging
  // const testNotification = async () => {
  //   console.log("🧪 Starting notification test for user:", user?.email);
  //   try {
  //     const { data, error } = await supabase
  //       .from("notifications_user")
  //       .insert({
  //         user_email: user.email,
  //         title: "Test Notification " + new Date().toLocaleTimeString(),
  //         description: "This is a test notification",
  //         is_read: false,
  //         delivered: false
  //       })
  //       .select()
  //       .single();
        
  //     if (error) {
  //       console.error("❌ Error creating test notification:", error);
  //       return;
  //     }
      
  //     console.log("✅ Test notification created successfully:", data);
  //     // Immediately check if notification exists
  //     await checkNotification();
  //   } catch (err) {
  //     console.error("❌ Exception while creating test notification:", err);
  //   }
  // };

  // // Add this test function for global notifications
  // const testGlobalNotification = async () => {
  //   console.log("🌍 Starting global notification test");
  //   try {
  //     const { data, error } = await supabase
  //       .from("notifications_global")
  //       .insert({
  //         title: "Global Test " + new Date().toLocaleTimeString(),
  //         description: "This is a global test notification",
  //         created_by: user.email
  //       })
  //       .select()
  //       .single();
        
  //     if (error) {
  //       console.error("❌ Error creating global notification:", error);
  //       return;
  //     }
      
  //     console.log("✅ Global notification created successfully:", data);
  //   } catch (err) {
  //     console.error("❌ Exception while creating global notification:", err);
  //   }
  // };

  return (
    <div className="flex flex-col">
      <CssBaseline />
      <div className="flex flex-row flex-grow relative fade-in">
        <AuthenticatedTemplate>
          {!isErrorPage ? (
            <>
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  height: "100%",
                  zIndex: 1500,
                }}
              >
                <Sidebar
                  open={sidebarOpen}
                  onClose={collapseSidebar}
                  user={user}
                  isAdmin={isAdmin}
                />
              </Box>

              <Box
                id="overlay"
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  zIndex: 1400,
                  opacity: overlayVisible ? 1 : 0,
                  pointerEvents: overlayVisible ? "auto" : "none",
                  transition: `all ${theme.custom.animation.fast} ease-in-out`,
                }}
                onClick={() => {
                  closeChatSidebar();
                  closeSidebar();
                }}
              />

              {!hasHeader && (
                <>
                  <Header title={getPageTitle()} />
                  <ChatFabPopup theme={theme} />
                  <BugReportFab onClick={handleBugReportClick} />
                  <BugReportModal open={bugModalOpen} onClose={handleBugModalClose} />
                </>
              )}

              <main
                className="flex-grow p-0"
                style={{
                  position: "relative",
                  zIndex: 1,
                  marginLeft: marginLeft,
                  transition: `all ${theme.custom.animation.fast} ease-in-out`,
                }}
              >
                {children}
              </main>
            </>
          ) : (
            <main className="flex-grow p-0">{children}</main>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <main className="flex-grow p-0">{children}</main>
        </UnauthenticatedTemplate>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
