export const programmingLanguages = {
  javascript: ".js",
  python: ".py",
  java: ".java",
  c: ".c",
  cpp: ".cpp",
  "c++": ".cpp",
  "c#": ".cs",
  ruby: ".rb",
  php: ".php",
  swift: ".swift",
  "objective-c": ".m",
  kotlin: ".kt",
  typescript: ".ts",
  go: ".go",
  perl: ".pl",
  rust: ".rs",
  scala: ".scala",
  haskell: ".hs",
  lua: ".lua",
  shell: ".sh",
  sql: ".sql",
  html: ".html",
  css: ".css",
};

export const fileExtensions = {
  pdf: ".pdf",
  doc: ".doc",
  docx: ".docx",
  ppt: ".ppt",
  pptx: ".pptx",
  txt: ".txt",
  csv: ".csv",
  xls: ".xls",
  xlsx: ".xlsx",
  json: ".json",
  xml: ".xml",
  md: ".md",
  mdx: ".mdx",
  yaml: ".yaml",
  yml: ".yml",
};

export const generateRandomString = (length, lowercase = false) => {
  const chars = "ABCDEFGHJKLMNPQRSTUVWXY3456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return lowercase ? result.toLowerCase() : result;
};
