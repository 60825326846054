import React from 'react';
import { useThemeContext } from '@context/ThemeContext';
import {
    Typography,
  } from "@mui/material";

  export function createAvatar(name) {
    const { darkMode } = useThemeContext();
    const initials = name ? name.split(' ').map(word => word[0]).join('') : '?';
    return {
        initials,
        sx: {
            bgcolor: darkMode ? '#757575' : '#757575',
            color: darkMode ? '#211b1b' : '#ffffff',
        },
    };
}

const CustomAvatar = ({ name, darkMode, width, height, src, borderRadius }) => {
    const { initials, sx } = createAvatar(name, darkMode);

    return (
        <div 
            style={{ 
                backgroundColor: sx.bgcolor, 
                width: width !== undefined ? width : '40px', 
                height: height !== undefined ? height : '40px', 
                minHeight: '25px',
                minWidth: '25px',
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                borderRadius: borderRadius !== undefined ? borderRadius : '50%',
            }} 
            className="avatar rounded-full"
        >
            {src != null ? (
                <img
                    src={src.startsWith("data:image") || src.startsWith("http") ? src : `data:image/png;base64,${src}`}
                    alt={name}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%',
                    }}
                    onError={(e) => { e.target.src = ""; }}
                />
            ) : (
                <Typography
                    variant="body1"
                    style={{
                        color: sx.color,
                        userSelect: 'none',
                        cursor: 'default',
                    }}
                >
                    {initials}
                </Typography>
            )}
        </div>
    );
};

export default CustomAvatar;