import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Box, Typography, Paper, Container, Button, CardActionArea, TextField, Popover, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Card, CardContent, CardActions, CardMedia } from "@mui/material";
import { useTheme } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import { HexColorPicker } from "react-colorful";
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import EditIcon from '@mui/icons-material/Edit';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { ClientContext } from '@context/ClientContext';
import useClickOutside from "../hooks/useClickOutside";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source).url();
}

const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const buttonRef = useRef();
  const [isOpen, toggle] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const openPopover = () => {
    const rect = buttonRef.current.getBoundingClientRect();
    setPosition({ top: rect.bottom, left: rect.left });
    toggle(true);
  };

  return (
    <div className="custom-layout picker" style={{ position: "relative" }}>
      <div
        className="swatch"
        style={{ 
          backgroundColor: color, 
          width: 24, 
          height: 24, 
          cursor: 'pointer', 
          border: '1px solid #ccc' 
        }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div 
          className="popover" 
          ref={popover}
          style={{
            position: 'absolute',
            top:  "-200px",
            left: "-200px",
            zIndex: 9999,
            borderRadius: '4px',
            padding: '10px'
          }}
        >
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};


const Clients = ({ darkMode }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { clients, fetchClients } = useContext(ClientContext);
  const [selectedClient, setSelectedClient] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [newColor, setNewColor] = useState("");
  const [newName, setNewName] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    setNewLogo(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleColorChange = (color) => {
    setNewColor(color);
  };

  const handleEditClick = (client) => {
    setSelectedClient(client);
    setNewColor(client.clientColor.hex || ""); 
    setNewName(client.clientName || "");
    setNewLogo(null);
    setOpen(true);
  };
  
  const handleAddNewClick = () => {
    setSelectedClient(null);
    setNewColor("");
    setNewName("");
    setNewLogo(null);
    setOpen(true);
  };

  const handleSave = async () => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      let updatedClient = { 
        clientColor: { hex: newColor },
        clientName: newName
      };
  
      if (newLogo) {
        const logoAsset = await sanityClient.assets.upload('image', newLogo);
        updatedClient.clientLogo = { asset: { _ref: logoAsset._id } };
      }
  
      if (selectedClient) {
        // Update existing client
        await sanityClient.patch(selectedClient._id).set(updatedClient).commit();
        alert("Client updated successfully!");
      } else {
        // Create new client
        await sanityClient.create({
          _type: 'clients',
          ...updatedClient
        });
        alert("Client created successfully!");
      }
  
      await fetchClients(); // Refresh client data
      handleClose();
    } catch (error) {
      console.error("Failed to save client:", error);
      alert("Failed to save client");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClickColorBox = (event) => {
    setAnchorEl(event.currentTarget);
    setShowColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setAnchorEl(null);
    setShowColorPicker(false);
  };

  const handleDelete = async () => {
    if (!selectedClient || isSubmitting) return;
    
    if (window.confirm('Are you sure you want to delete this client?')) {
      try {
        setIsSubmitting(true);
        await sanityClient.delete(selectedClient._id);
        await fetchClients(); // Refresh client data
        handleClose();
        alert('Client deleted successfully!');
      } catch (error) {
        console.error("Failed to delete client:", error);
        alert("Failed to delete client");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedClient(null);
    setNewColor("");
    setNewName("");
    setNewLogo(null);
    setIsSubmitting(false);
  };

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>

          <Grid container spacing={2}>
            {clients.map(client => (
              <Grid item xs={6} sm={6} md={3} lg={2} key={client._id}>
                <Card
                  sx={{ 
                    cursor: 'pointer',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: theme.shape.borderRadius,
                    transition: 'all 0.2s ease-in-out',
                    border: `1px solid ${theme.palette.border.main}`,
                    boxShadow: 0,
                    ...(isSmallScreen
                      ? {}
                      : {
                          "&:hover": {
                            boxShadow: 10,
                            borderRadius: 10,
                            transform: "scale(1.05)",
                            transition: `all .2s ease-in-out`, 
                          },
                        }),
                  }}
                  onClick={() => handleEditClick(client)}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={urlFor(client.clientLogo.asset._ref)}
                    alt={client.clientName}
                  />
                  <CardContent>
                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>{client.clientName}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: client.clientColor.hex,
                          marginRight: theme.spacing(1),
                        }}
                      />
                      <Typography variant="body1">
                        {client.clientColor.hex}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}

          <Grid item xs={6} sm={6} md={3} lg={2}>
          <Card
              elevation={0}
              sx={{
                border: `1px solid ${theme.palette.border.main}`,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                minHeight: "230px",
                cursor: "pointer",
                borderRadius: theme.shape.borderRadius,
                transition: `all .2s ease-in-out`,
                boxShadow: 0,
                ...(isSmallScreen
                  ? {}
                  : {
                      "&:hover": {
                        boxShadow: 10,
                        borderRadius: 10,
                        transform: "scale(1.05)",
                        transition: `all .2s ease-in-out`, 
                        backgroundColor: theme.palette.primary.main,
                        "& .MuiTypography-root": {
                          color: "#000000",
                        },
                        "& .icon": {
                          color: "#000000",
                        },
                      },
                    }),
              }}
              onClick={handleAddNewClick}
            >
              <CardActionArea
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  position: "relative",
                  transition: "all 0.2s ease-in-out 1",
                  "&:hover .icon": {
                    color: !isSmallScreen ? `#000000 !important` : "inherit",
                    animation: !isSmallScreen ? "scale-rotate 0.2s ease-in-out 1" : "none",
                    transform: !isSmallScreen ? "scale(1.2)" : "none",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: isSmallScreen ? theme.palette.primary.main : "transparent",
                  }}
                  className={`task-item-wrapper`}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100%",
                      padding: 2,
                      margin: 0,
                      textAlign: "center",
                      color: isSmallScreen ? theme.palette.black.main : theme.palette.secondary.main,
                    }}
                  >
                    <AddIcon
                      className="icon"
                      sx={{
                        fontSize: "45px",
                        margin: "10px",
                        color: isSmallScreen ? theme.palette.black.main : theme.palette.secondary.main,
                        transition: "transform 0.2s ease-in-out, color 0.2s ease-in-out",
                      }}
                    />
                    <Typography variant="Body1">
                      Add New Client
                    </Typography>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
          </Grid>

          <Dialog
  open={open}
  onClose={handleClose}
  fullWidth
  maxWidth="sm"
  sx={{
    '& .MuiDialog-paper': {
      width: isSmallScreen ? '90%' : '50%',
      maxWidth: 'none',
      borderRadius: '10px',
    },
  }}
>
  <DialogTitle sx={{p:4}}>
    {selectedClient ? "Edit Client" : "Add New Client"}
  </DialogTitle>
  <DialogContent sx={{p:4}}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
      <Box {...getRootProps()} sx={{
        border: '2px dashed #ccc',
        padding: 2,
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
        cursor: 'pointer',
        width: '190px', 
        height: '190px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          width: '150px',
          height: '150px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: newLogo 
            ? `url(${URL.createObjectURL(newLogo)})` 
            : selectedClient && selectedClient.clientLogo 
              ? `url(${urlFor(selectedClient.clientLogo.asset._ref)})` 
              : 'none',
          padding: '20px',
          display: newLogo || (selectedClient && selectedClient.clientLogo) ? 'block' : 'none',
        }} />
        <input {...getInputProps()} />
        {!newLogo && !(selectedClient && selectedClient.clientLogo) && (
          <Typography variant="body2" color="textSecondary">
            Drag & drop or click to upload a logo
          </Typography>
        )}
      </Box>
    </Box>
    <TextField
      label="Client Name"
      fullWidth
      value={newName}
      variant="outlined"
      onChange={(e) => setNewName(e.target.value)}
      sx={{ mb: 4 }}
    />


<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                label="Client Color"
                fullWidth
                value={newColor || ""} 
                variant="outlined"
                onChange={(e) => setNewColor(e.target.value)}
                sx={{ mr: 2 }}
              />
              <PopoverPicker className="custom-layout" color={newColor} onChange={setNewColor} />
            </Box>
  </DialogContent>
  <DialogActions sx={{p:4}}>
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      {selectedClient && (
        <Button 
          size="large" 
          onClick={handleDelete}
          color="error"
          variant="outlined"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Deleting...' : 'Delete'}
        </Button>
      )}
      <Box>
        <Button 
          size="large" 
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          size="large" 
          onClick={handleSave} 
          variant="contained" 
          color="primary"
          disabled={isSubmitting}
          sx={{ ml: 2 }}
        >
          {isSubmitting 
            ? (selectedClient ? 'Saving...' : 'Adding...') 
            : (selectedClient ? 'Save' : 'Add')
          }
        </Button>
      </Box>
    </Box>
  </DialogActions>
</Dialog>

      </Container>
    </Box>
  );
};

export default Clients;
