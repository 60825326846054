import React, { useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import BugReportIcon from "@mui/icons-material/BugReport";

const BugReportModal = ({ open, onClose }) => {
  const [taskDescription, setTaskDescription] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        setSubmitted(true);
        const response = await axios.post(
        "https://hook.us1.make.com/vw9w85syyaa4qav4ukp8js31uhqkdx0h",
        {
          description: taskDescription,
        }
      );
      console.log("Webhook response:", response.data);
      
    } catch (error) {
      console.error("Error calling webhook:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="bug-report-modal-title"
      aria-describedby="bug-report-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: '80%',
          maxWidth: 600,
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          p: 3,
          pt: 6,
          pb: 6,
          borderRadius: 2,
        }}
      >
        {!submitted ? (
          // Display the form if not submitted
          <>
            <Typography variant="h6" id="bug-report-modal-title" gutterBottom>
              Report a Defect
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="task-description"
                label="Describe the issue..."
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                sx={{ mb: 2 }}
                disabled={submitted}
              >
                Submit
              </Button>
            </Box>
          </>
        ) : (
          // Display the thank you message after submission
          <Box className="fade-in" align="center" sx={{ my: 4 }}>
            <BugReportIcon
              color="primary"
              sx={{
                fontSize: 75,
                mb: 2,
                animation: "wiggle .2s ease-in-out infinite",
                "@keyframes wiggle": {
                  "0%": { transform: "rotate(0deg)" },
                  "25%": { transform: "rotate(-10deg)" },
                  "50%": { transform: "rotate(10deg)" },
                  "75%": { transform: "rotate(-10deg)" },
                  "100%": { transform: "rotate(0deg)" },
                },
              }}
            />
            <Typography variant="h5" component="h1" gutterBottom>
              Thank you for helping us make Nucleus better!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose} // Allow user to close the modal
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default BugReportModal;
