// src/components/LogoutButton.js
import { Button } from '@mui/material';
import React from 'react';
import { useMsal } from '@azure/msal-react';

const LogoutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });
  };

  return <Button
    variant="contained"
    color="primary"
    onClick={handleLogout}
    size="large"
    sx={{
        mt: 4,
        paddingLeft: 4, // Adjust the padding values as needed
        paddingRight: 4,
        paddingTop: 1, // Optional: adjust the vertical padding
        paddingBottom: 1, // Optional: adjust the vertical padding
        fontSize: '1rem', // Optional: adjust the font size
    }}
    >Logout</Button>;
};

export default LogoutButton;
