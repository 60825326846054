import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Container, Grid, Card, CardContent, CardMedia, CardActionArea, Button, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material";
import { useThemeContext } from "@context/ThemeContext";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import sanityClient from "../sanityClient";
import imageUrlBuilder from '@sanity/image-url';
import { isAndroidOrIOS } from "../utils/deviceDetection";

const Education = () => {
  const theme = useTheme();
  const [education, setEducation] = useState(null);
  const { apiCall } = useApiWithAuth();
  const [educationLoaded, setEducationLoaded] = useState(false);
  const [educationOrderLoaded, setEducationOrderLoaded] = useState(false);
  const builder = imageUrlBuilder(sanityClient);
  const { darkMode, handleThemeChange } = useThemeContext();
  const isMobile = isAndroidOrIOS();

  function urlFor(source) {
    return builder.image(source).url();
  }

  useEffect(() => {
      const fetchEducation = async () => {
        // console.log("fetchEducation");  
        try {
              const data = await sanityClient.fetch('*[_type == "education"]');
              // console.log("Education data:", data);
              const processedEducation = data.map(item => ({
                  id: item._id,
                  title: item.title,
                  description: item.description,
                  image: item.image ? urlFor(item.image.asset._ref) : null,
                  url: item.url,
                  overline: item.overline,
                  caption: item.caption,
                  cta: item.cta,
              }));
              // console.log("Processed education:", processedEducation);
              setEducation(processedEducation);
              setEducationLoaded(true);
          } catch (error) {
              console.error("Error fetching education:", error);
          }
      };
      fetchEducation();
  }, []);

  useEffect(() => {
      const fetchEducationOrder = async () => {
        // console.log("fetchEducationOrder");  
        if (!educationLoaded) return;
        // console.log("Education loaded");
        try {
            const data = await sanityClient.fetch('*[_type == "educationOrder"]');
            if (data.length > 0 && data[0].education) {
                const educationOrder = data[0].education.map(item => item._ref);
                const orderedEducation = educationOrder.map(educationId =>
                    education.find(item => item.id === educationId)
                ).filter(item => item);

                setEducation(orderedEducation);
                setEducationOrderLoaded(true);
            }
        } catch (error) {
            console.error("Error fetching education order:", error);
        }
      };
      fetchEducationOrder();
  }, [educationLoaded]);

  return (
    <Container maxWidth="lg" disableGutters>
    <Box className="fade-in p-4 pt-20 pb-20">
      

        {educationOrderLoaded && education && education.length > 0 && (
          <Box mt={0}>
            <Card
              className="fade-in"
              elevation={theme.elevation}
              sx={{
                display: 'flex',
                border: `1px solid ${theme.palette.border.main}`,
                flexDirection: { xs: 'column', md: 'row' },
                mb: 3,
                borderRadius: "30px",
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              <CardActionArea
                href={education[0].url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' }, 
                  width: '100%',
                  height: '100%',
                }}
              >
              {education[0].image && (
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 'auto', sm: 'auto', md: '400px' },
                    width: { sm: '100%', md: '100%', lg:"40%" },
                    objectFit: 'cover',
                  }}
                  image={education[0].image}
                  alt={education[0].title.trim()}
                />
              )}
        <CardContent
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%', lg: '60%' }, // Full width on small screens, 60% on larger screens
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'left',
            textAlign: 'left',
            p: 3,
          }}
        >
          <Typography variant="overline">
            {education[0].overline}
          </Typography>
          <Typography variant="h4" component="div" gutterBottom>
            {education[0].title.trim()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {education[0].description}
          </Typography>
          <Box sx={{ pt: 2 }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => window.open(education[0].url, '_blank', 'noopener,noreferrer')} // Handle click event here
            >
              {education[0].cta}
            </Button>
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 2 }}>
              {education[0].caption}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>

    <Box>
      <Typography
        variant="h5"
        mt={3}
        mb={2}
        component="h1"
        textAlign={"left"}
        gutterBottom
        sx={{ userSelect: "none", }}
      >
        LinkedIn Learning Courses
      </Typography>
      <hr
        style={{
          width: "100%",
          border: "none",
          borderBottom: darkMode
            ? "1px dashed rgba(255,255,255,.25)"
            : "1px dashed rgba(0,0,0,.25)",
          margin: "10px 0 0 0",
          marginBottom: "15px",
        }}
      />
      
    </Box>



    <Grid container spacing={2}>
  {educationOrderLoaded ? (
    education.slice(1).map((course, index) => (
      <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
        <Card 
          elevation={theme.elevation} 
          sx={{ 
            border: `1px solid ${theme.palette.border.main}`,
            borderRadius: theme.shape.borderRadius, 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100%',
            transition: `all .2s ease-in-out`,
              ...(isMobile
                ? {}
                : {
                    "&:hover": {
                      transform: "scale(1.03)",
                      boxShadow: 10,
                      zIndex: 1,
                      borderRadius: 10,
                      transition: `all .2s ease-in-out`,
                    },
                  }),
                  }}
              >
          <CardActionArea
            href={course.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              height: '100%', 
              alignItems: 'start',
            }}
          >
            {course.image ? (
              <CardMedia
                component="img"
                sx={{ height: 200 }}
                image={course.image}
                alt={course.title.trim()}
              />
            ) : (
              <Skeleton variant="rectangular" width="100%" height={220} />
            )}
            <CardContent sx={{ flexGrow: 1, padding:3 }}>
              <Typography variant="overline">
                {course.overline}
              </Typography>
              <Typography variant="h6" sx={{ lineHeight: 1.3 }} component="div" gutterBottom>
                {course.title.trim()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {course.description}
              </Typography>
            </CardContent>
            {/* <Box sx={{ p: 2 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => window.open(course.url, '_blank', 'noopener,noreferrer')} // Handle click event here
              >
                {course.cta}
              </Button>
            </Box> */}
          </CardActionArea>
        </Card>
      </Grid>
    ))
  ) : (
    // Render skeletons while loading
    [1, 2, 3, 4, 5, 6].map((index) => (
      <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
        <Skeleton variant="rectangular" width="100%" height={300} sx={{ borderRadius: theme.shape.borderRadius }} />
      </Grid>
    ))
  )}
</Grid>



          </Box>
        )}
        </Box>
      </Container>
    
  );
};

export default Education;

