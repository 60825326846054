// src/hooks/useCategories.js
import { useState, useEffect } from "react";
import { fetchCategories } from "../utils/sanityUtils";

export function useCategories() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryData = await fetchCategories();
        const allCategories = ["All", ...categoryData.map((cat) => cat.title)];
        setCategories(allCategories);
        setSelectedCategory("All");
        console.log("Categories:", allCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchData();
  }, []);

  return { categories, selectedCategory, setSelectedCategory };
}
