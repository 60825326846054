import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, CircularProgress, Avatar } from "@mui/material";
import PropTypes from "prop-types";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import ChatMessage from "@/components/common/message/MessageMarkdown";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useMsal } from "@azure/msal-react";
import { supabase } from "../../supabaseClient";

const MessageList = ({
  messages,
  darkMode,
  isSmallScreen,
  assistantLoading,
  loadingMessage,
  chatId,
  isMessageStreaming,
}) => {
  const { apiCall } = useApiWithAuth();
  const [isSharedChat, setIsSharedChat] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await apiCall("get", `/chats/${chatId}/shared`);
      if (response.data.length > 0) {
        setIsSharedChat(true);
      }
    };
    if (chatId) {
      fetchMessages();
    }
  }, [apiCall, chatId]);

  useEffect(() => {
    // Set up real-time listener
    const subscription = supabase
      .channel("chat_participants")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "chat_participants",
          filter: `chat_id=eq.${chatId}`,
        },
        (payload) => {
          if (payload.new && payload.new.chat_id === chatId) {
            setIsSharedChat(true);
          }
        },
      )
      .subscribe();

    // Cleanup subscription
    return () => {
      supabase.removeChannel(subscription);
    };
  }, [chatId, apiCall]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isMessageStreaming]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        paddingTop: "40px",
        paddingBottom: isSmallScreen ? "150px" : "150px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        WebkitOverflowScrolling: "touch",
      }}
      className="custom-scrollbar"
    >
      {messages.map((message, index) => (
        <MessageItem
          key={index}
          message={message}
          darkMode={darkMode}
          isSmallScreen={isSmallScreen}
          isSharedChat={isSharedChat}
        />
      ))}
      {assistantLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: "5px 20px",
            gap: "10px",
            padding: 2,
          }}
        >
          <CircularProgress size={20} sx={{ color: "secondary.main" }} />
          <Typography variant="body2" color="textSecondary">
            {loadingMessage}
          </Typography>
        </Box>
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  darkMode: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  assistantLoading: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string.isRequired,
  isMessageStreaming: PropTypes.bool.isRequired,
};

const MessageItem = ({ message, darkMode, isSmallScreen, isSharedChat }) => {
  const getInitials = (email) => {
    const parts = email.split("@")[0].split(".");
    return parts.length > 1
      ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
      : parts[0][0].toUpperCase();
  };
  const { accounts } = useMsal();

  return (
    <Box
      className="markdown"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: message.role === "user" ? "flex-end" : "flex-start",
        margin: "5px 20px",
        opacity: 1,
        transition: "opacity .2s ease-in-out",
      }}
    >
      {message?.file && (
        <Box
          sx={{
            backgroundColor: darkMode
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            padding: "25px",
            marginBottom: 1,
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AttachFileIcon sx={{ marginRight: 1, fontSize: "1.25rem" }} />
          {message?.file?.name}
        </Box>
      )}
      {message?.file_name && (
        <Box
          sx={{
            backgroundColor: darkMode
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            padding: "25px",
            marginBottom: 1,
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AttachFileIcon sx={{ marginRight: 1, fontSize: "1.25rem" }} />
          {message?.file_name}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "5px 20px",
          opacity: 1,
          transition: "opacity .2s ease-in-out",
        }}
      >
        <Box
          sx={{
            borderRadius: 10,
            padding: "0px 20px",
            backgroundColor:
              message.role !== "user"
                ? "transparent"
                : darkMode
                  ? "rgba(255, 255, 255, 0.1)"
                  : "rgba(255, 255, 255, .5)",
            maxWidth: isSmallScreen ? "100%" : "100%",
            overflow: "hidden",
          }}
        >
          {message.role !== "data" ? (
            <ChatMessage
              content={message.content}
              toolInvocations={message?.toolInvocations}
            />
          ) : (
            <>
              {message.data.description}
              <br />
              <pre
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                  padding: "0px",
                  borderRadius: "5px",
                }}
              >
                {JSON.stringify(message.data, null, 2)}
              </pre>
            </>
          )}
        </Box>
        {isSharedChat &&
          ((message.sender_email && message.role === "user") ||
            (accounts[0].username && message.role === "user")) && (
            <Avatar
              sx={{
                bgcolor: darkMode
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.3)",
                marginLeft: 1,
              }}
            >
              {getInitials(message.sender_email || accounts[0].username)}
            </Avatar>
          )}
      </Box>
    </Box>
  );
};

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  darkMode: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export { MessageList, MessageItem };
