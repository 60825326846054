// CustomListItem.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useThemeContext } from '../../context/ThemeContext';
import { isAndroidOrIOS } from '../../utils/deviceDetection';

const CustomListItem = ({ to, icon: Icon, iconClassName, primaryText, isActive, handleNavLinkClick, collapsed }) => {
  const location = useLocation();
  const { darkMode, theme } = useThemeContext();
  const isActiveState = isActive(location.pathname);
  const isMobile = isAndroidOrIOS();

  return (
    <ListItem
      component={NavLink}
      to={to}
      onClick={handleNavLinkClick}
      className={`${isActiveState ? ' list-item active-nav' : ''}`}
      sx={{
        minHeight: '50px',
        backgroundColor: isActiveState
          ? darkMode
            ? 'rgba(255, 255, 255, 0.05)'
            : 'rgba(0, 0, 0, 0.05)'
          : 'transparent',
        boxShadow: 'inset 4px 0 0 transparent',
        position: 'relative',
        paddingLeft: '18px',
        transition: `all ${theme.custom.animation.fast} ease-in-out`,
        cursor: 'pointer',
        ...(isMobile ? {} : {
          '&:hover': {
            backgroundColor: darkMode
              ? 'rgba(255, 255, 255, 0.2)'
              : 'rgba(0, 0, 0, 0.1)', 
            boxShadow: darkMode
              ? 'inset 4px 0 0 rgba(255, 255, 255, 0.2)'
              : 'inset 4px 0 0 rgba(0, 0, 0, 0.2)',
            paddingLeft: '20px',
            '& .icon': {
              color: isActiveState 
                ? (darkMode ? theme.palette.common.white : theme.palette.common.black) 
                : (darkMode ? theme.palette.common.white : theme.palette.text.primary)
            },
          },
        }),
      }}
    >
      <ListItemIcon style={{ minWidth: '42px' }}>
        <Icon 
          className={`icon h-6 w-6 ${iconClassName || ''}`} 
          sx={{
            color: isActiveState
              ? (darkMode ? theme.palette.common.white : theme.palette.common.black)
              : theme.palette.icon.secondary
          }}
        />
      </ListItemIcon>
      <ListItemText primary={primaryText} sx={{userSelect: 'none',}}/>
    </ListItem>
  );
};

export default CustomListItem;