// src/assets/styles/theme.js
import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: '#FF9800',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#B0B0B0',
    },
    white: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },
    background: {
      default: mode === 'dark' ? '#121212' : '#E0E0E0',
      inverse: mode === 'dark' ? '#FFFFFF' : '#1a1a1a',
      paper: mode === 'dark' ? '#1a1a1a' : '#FFFFFF',
    },
    border: {
      main: mode === 'dark' ? '#333333' : '#CCCCCC',
    },
    text: {
      primary: mode === 'dark' ? '#FFFFFF' : '#000000',
      secondary: mode === 'dark' ? '#B0B0B0' : '#4F4F4F',
      disabled: mode === 'dark' ? '#5F5F5F' : '#9E9E9E',
      hint: mode === 'dark' ? '#8A8A8A' : '#6D6D6D',
    },
    icon: {
      primary: mode === 'dark' ? '#FF9800' : '#FF9800',
      secondary: mode === 'dark' ? '#B0B0B0' : '#808080',
      disabled: mode === 'dark' ? '#5F5F5F' : '#9E9E9E',
    },
  },
  spacing: 8, 
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: "40px", 
        },
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  elevation: 0,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButton: {
      defaultProps: {
        //disableElevation: true
      },
      styleOverrides: {
        contained: {
          color: '#000000'
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: mode === 'dark' ? '#ffffff' : '#1a1a1a',
          '&.MuiChip-clickable:hover': {
            backgroundColor: '#FF9800',
            opacity: 1,
          },
          '&.MuiChip-filledPrimary': {
            backgroundColor: '#FF9800',
            color: '#000000',
            opacity: 1,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'dark' ? '#1a1a1a' : '#FFFFFF',
        },
      },
    },
  },
  custom: {
    animation: {
      fast: '100ms',
      normal: '250ms',
      slow: '400ms',
    },
  },
});

const getTheme = (mode) => createTheme(getDesignTokens(mode));

export default getTheme;