import { useState, useCallback } from "react";

const useCopyToClipboard = ({ timeout = 2000 }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback(
    (text) => {
      if (typeof navigator !== "undefined") {
        navigator.clipboard.writeText(text).then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), timeout);
        });
      }
    },
    [timeout]
  );

  return { isCopied, copyToClipboard };
};

export default useCopyToClipboard;
