export const LOADING_MESSAGES = [
  "Analyzing context for optimal response...",
  "Consulting vast language models...",
  "Processing natural language input...",
  "Generating coherent thoughts...",
  "Exploring neural pathways for insights...",
  "Synthesizing information from training data...",
  "Calibrating response tone and style...",
  "Applying artificial intelligence algorithms...",
  "Parsing query for intent and nuance...",
  "Formulating a thoughtful reply...",
];

export const CONVERSATION_STAGES = {
  INITIAL: "initial",
  IN_PROGRESS: "inProgress",
};

export const CHAT_API_ENDPOINTS = {
  STREAM: `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/stream`,
  CREATE_MESSAGE: `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/create-message`,
  PERMISSION: `${process.env.REACT_APP_API_URL}/api/chats/permission`,
  SOURCES: `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/sources`,
};
