import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia
} from '@mui/material';

const ChatSection = ({ isSmallScreen, darkMode, theme, clients, urlFor }) => {
  return (
    <>
      <Box className="fade-in">
                <Typography
                  variant="h5"
                  mt={0}
                  component="h1"
                  textAlign={"left"}
                  gutterBottom
                  sx={{ userSelect: "none" }}
                >
                  Start a Chat
                </Typography>
                <hr
                  style={{
                    width: "100%",
                    border: "none",
                    borderBottom: darkMode
                      ? "1px dashed rgba(255,255,255,.25)"
                      : "1px dashed rgba(0,0,0,.25)",
                    margin: "0px",
                  }}
                />

                <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
                  {clients.map((client) => (
                    <Grid item xs={3} sm={3} md={3} lg={3} key={client._id}>
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          cursor: "pointer",
                          boxShadow: 0,
                          borderRadius: theme.shape.borderRadius,
                          border: `1px solid ${theme.palette.border.main}`,
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            boxShadow: 10,
                            transform: "scale(1.05)",
                          },
                        }}
                        onClick={() =>
                          (window.location.href = `/chatgpt/${client.clientId}/chat`)
                        }
                      >
                        <CardActionArea>
                          <CardMedia
                            className="fade-in"
                            component="img"
                            image={urlFor(client.clientLogo.asset._ref)}
                            alt={client.clientName}
                            sx={{ objectFit: "contain", padding: 0 }}
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
    </>
  );
};

export default ChatSection;
