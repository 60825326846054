import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const InboxIconComponent = ({ onClick, title, icon }) => {
  const theme = useTheme();

  return (
    <Tooltip 
      title={title} 
      placement="bottom" 
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
    >
      <IconButton 
      onClick={onClick}
      sx={{
        borderRadius: 2,
        border: `1px solid ${theme.palette.divider}`,
        padding: "12px",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.black,
        },
      }}
    >
      {icon}
    </IconButton>
    </Tooltip>
  );
};

export default InboxIconComponent;
