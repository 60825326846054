import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { supabase } from "../supabaseClient";
import { useAuth } from "@context/AuthContext";
import { CircularProgress, Box } from "@mui/material";

const UserContext = createContext();

export const UserProvider = ({ children, accounts }) => {
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true); // Track loading state
  const { acquireToken } = useAuth();

  const adminEmails = [
    "j.beckley@fusion92.com",
    "c.auer@fusion92.com",
    "n.herrera@fusion92.com",
  ];

  const userSetUpAndFetchProfile = async () => {
    try {
      // console.log(
      //   "UserProvider: userSetUpAndFetchProfile: accounts: ",
      //   accounts,
      // ``);
      if (!accounts || accounts.length === 0) {
        // console.log("No accounts available, skipping user setup");
        setLoading(false);
        return;
      }

      const account = accounts[0];
      const userEmail = account?.username ?? "Unknown";
      const entraId = account?.idTokenClaims?.oid ?? "Unknown";

      // console.log(
      //   "UserProvider: userSetUpAndFetchProfile: userEmail: ",
      //   userEmail,
      // );
      // console.log("UserProvider: userSetUpAndFetchProfile: entraId: ", entraId);

      setIsAdmin(adminEmails.includes(userEmail));

      // Fetch user from Supabase
      const { data: userData, error: userFetchError } = await supabase
        .from("users")
        .select("id")
        .eq("email", userEmail)
        .maybeSingle();

      if (userFetchError) {
        throw new Error(
          `Error fetching user from users table: ${userFetchError.message}`,
        );
      }

      // console.log(
      //   "UserProvider: userSetUpAndFetchProfile: userData: ",
      //   userData,
      // );

      if (!userData) {
        // console.log("User not found in Supabase, creating new user");
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: account: ",
        //   account,
        // );
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: userEmail: ",
        //   userEmail,
        // );
        // console.log(
        //  "UserProvider: userSetUpAndFetchProfile: entraId: ",
        //   entraId,
        // );
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: account?.name: ",
        //   account?.name,
        // );
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: account?.idTokenClaims?.given_name: ",
        //   account?.idTokenClaims?.given_name,
        // );
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: account?.idTokenClaims?.family_name: ",
        //   account?.idTokenClaims?.family_name,
        // );
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: account?.idTokenClaims?.upn: ",
        //   account?.idTokenClaims?.upn,
        // );

        const { data: newUser, error: createError } = await supabase
          .from("users")
          .insert({
            email: userEmail,
            entra_id: entraId,
            name: account?.name ?? "Unknown",
            given_name: account?.idTokenClaims?.given_name ?? "Unknown",
            family_name: account?.idTokenClaims?.family_name ?? "Unknown",
            upn: account?.idTokenClaims?.upn ?? "Unknown",
            username: userEmail,
          })
          .single();

        if (createError) {
          throw new Error(
            `Error creating new user in Supabase: ${createError.message}`,
          );
        }

        // console.log("New user created:", newUser);
        userData = newUser;
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: userData: ",
        //   userData,
        // );
      }

      const userId = userData.id;
      // console.log("UserProvider: userSetUpAndFetchProfile: userId: ", userId);

      // Acquire token for Microsoft Graph API
      const token = await acquireToken(account, "access");
      // console.log("UserProvider: userSetUpAndFetchProfile: token acquired");

      if (userData.profile_image) {
        setUser((prevUser) => ({
          ...prevUser,
          name: account?.name ?? "Unknown",
          email: userEmail,
          givenName: account?.idTokenClaims?.given_name ?? "Unknown",
          familyName: account?.idTokenClaims?.family_name ?? "Unknown",
          fullName: account?.idTokenClaims?.name ?? account?.name ?? "Unknown",
          upn: account?.idTokenClaims?.upn ?? "Unknown",
          entraId,
          userId,
          profileImage: userData.profile_image,
        }));
        return;
      }

      // console.log(
      // "UserProvider: userSetUpAndFetchProfile: userData.profile_image not found, fetching profile photo from Microsoft Graph API",
      // );
      // Fetch profile photo from Microsoft Graph API
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/photo/$value",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        // console.log("UserProvider: userSetUpAndFetchProfile: response.ok");
        const blob = await response.blob();
        const fileName = `${entraId}.png`;
        const file = new File([blob], fileName, { type: blob.type });
        const bucket = "profile-images";

        const { error: uploadError } = await supabase.storage
          .from(bucket)
          .upload(fileName, file, {
            cacheControl: "3600",
            upsert: true,
          });

        if (uploadError) {
          throw new Error(
            `Error uploading image to Supabase: ${uploadError.message}`,
          );
        }

        const publicUrl = supabase.storage.from(bucket).getPublicUrl(fileName)
          .data.publicUrl;
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: publicUrl: ",
        //   publicUrl,
        // );

        const { error: updateError } = await supabase
          .from("users")
          .update({ profile_image: publicUrl })
          .eq("id", userId);

        if (updateError) {
          throw new Error(
            `Error updating profile_image in users table: ${updateError.message}`,
          );
        }

        // Update user state with profile image
        setUser((prevUser) => ({
          ...prevUser,
          name: account?.name ?? "Unknown",
          email: userEmail,
          givenName: account?.idTokenClaims?.given_name ?? "Unknown",
          familyName: account?.idTokenClaims?.family_name ?? "Unknown",
          fullName: account?.idTokenClaims?.name ?? account?.name ?? "Unknown",
          upn: account?.idTokenClaims?.upn ?? "Unknown",
          entraId,
          userId,
          profileImage: publicUrl,
        }));

        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: user updated with profile image",
        // );
        // console.log("UserProvider: userSetUpAndFetchProfile: user: ", user);
      } else if (response.status === 404) {
        // console.log("No profile image found, setting default or null");

        // No profile image found, update user with default or null profile
        const { error: updateError } = await supabase
          .from("users")
          .update({ profile_image: null })
          .eq("id", userId);

        if (updateError) {
          throw new Error(
            `Error updating profile_image to null in users table: ${updateError.message}`,
          );
        }

        setUser((prevUser) => ({
          ...prevUser,
          profileImage: null,
        }));
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: user updated with null profile image",
        // );
        // console.log("UserProvider: userSetUpAndFetchProfile: user: ", user);
        // console.log(
        //   "UserProvider: userSetUpAndFetchProfile: user.profileImage: ",
        //   user.profileImage,
        // );
      } else {
        throw new Error(
          `Error fetching profile image from Microsoft Graph: ${response.status} ${response.statusText}`,
        );
      }
    } catch (error) {
      // console.error(
      //   "UserProvider: Error setting up user or fetching profile image:",
      //   error,
      // );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userSetUpAndFetchProfile();
  }, [accounts]);

  useEffect(() => {
    // console.log("User state updated: ", user);
  }, [user]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="90vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, isAdmin, setIsAdmin, adminEmails }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  accounts: PropTypes.array.isRequired,
};

export const useUser = () => useContext(UserContext);
