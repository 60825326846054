import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { isAndroidOrIOS } from '../../utils/deviceDetection';
import { useEffect, useState } from 'react';
import sanityClient from '../../sanityClient';

const AIProductsSection = ({ darkMode, theme }) => {
  const isMobile = isAndroidOrIOS();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const query = `*[_type == "aiProducts"] {
        name,
        owner,
        logo,
        externalLink
      }`;
      
      try {
        const result = await sanityClient.fetch(query);
        setProducts(result);
      } catch (error) {
        console.error('Error fetching AI products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Box className="fade-in" sx={{ mt: 4 }}>
      <Typography
        variant="h5"
        component="h1"
        textAlign="left"
        gutterBottom
        sx={{ userSelect: "none" }}
      >
        Fusion92 AI Products
      </Typography>
      <hr
        style={{
          width: "100%",
          border: "none",
          borderBottom: darkMode
            ? "1px dashed rgba(255,255,255,.25)"
            : "1px dashed rgba(0,0,0,.25)",
          margin: "0px",
        }}
      />

      <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
        {products.map((product) => (
          <Grid item xs={12} key={product.name}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                position: "relative",
                overflow: "visible",
                boxShadow: 0,
                borderRadius: theme.shape.borderRadius,
                transition: `all .2s ease-in-out`,
                border: `1px solid ${theme.palette.border.main}`,
                ...(isMobile
                  ? {}
                  : {
                      "&:hover": {
                        transform: "scale(1.01)",
                        boxShadow: 0,
                        zIndex: 1,
                        borderRadius: 8,
                        transition: `all .2s ease-in-out`,
                      },
                      "&:hover .cardHover": {
                        borderRadius: 5,
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#252525"
                            : "#FFFFFF",
                        transition: `all .2s ease-in-out`,
                      },
                    }),
              }}
            >
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  height: "100%",
                  cursor: "pointer",
                  overflow: "visible",
                  position: "relative",
                  zIndex: 0,
                  transition: `all .2s ease-in-out`,
                }}
                className="cardHover"
              >
                <CardActionArea
                  component="a"
                  href={product.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 2,
                    height: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CardMedia
                      className="fade-in"
                      component="img"
                      image={product.logo}
                      alt={product.name}
                      sx={{ 
                        objectFit: "contain",
                        width: 40,
                        height: 40,
                        marginRight: 2,
                        transition: 'transform 0.2s ease-in-out',
                        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#cccccc",
                        padding: .2,
                        borderRadius: 0,
                        '&:hover': {
                          transform: 'scale(1.05)',
                        }
                      }}
                    />
                    <Box>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          userSelect: 'none',
                          fontWeight: 500
                        }}
                      >
                        {product.name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="textSecondary" 
                        sx={{ userSelect: 'none' }}
                      >
                        Owner: {product.owner}
                      </Typography>
                    </Box>
                  </Box>
                  <Icon 
                    icon="material-symbols:open-in-new" 
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 20,
                      marginLeft: 2,
                    }}
                  />
                </CardActionArea>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AIProductsSection; 