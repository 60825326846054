import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Icon } from "@iconify/react";
import { useTheme } from "@mui/material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useTasks } from "@/hooks/useTasks";
import SourceListItem from "@components/training/SourceListItem";
import SyncIcon from "@mui/icons-material/Sync";
import AddIcon from "@mui/icons-material/Add";
import ImportConfluenceModal from "@components/training/ImportConfluenceModal";
import ImportSharePointModal from "@components/training/ImportSharePointModal";
import ImportWebsiteModal from "@components/training/ImportWebsiteModal";
import { ClientContext } from "@context/ClientContext";
import AddSourceMenu from "@components/training/AddSourceMenu";
import CollapsibleSourceList from "@components/training/CollapsibleSourceList";

const TasksPage = () => {
  const theme = useTheme();
  const { apiCall } = useApiWithAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { tasks } = useTasks("All");
  const [sources, setSources] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSyncing, setSyncing] = useState(false);
  const [lastSyncedAt, setLastSyncedAt] = useState("Never");
  const [pagesSynced, setPagesSynced] = useState(0);
  const { clients } = useContext(ClientContext);
  const [itemType, setItemType] = useState("client");
  const [isConfluenceImportModalOpen, setIsConfluenceImportModalOpen] =
    useState(false);
  const toggleConfluenceImportModal = () =>
    setIsConfluenceImportModalOpen((prev) => !prev);
  const [isSharePointImportModalOpen, setIsSharePointImportModalOpen] =
    useState(false);
  const toggleSharePointImportModal = () =>
    setIsSharePointImportModalOpen((prev) => !prev);
  const [isTestingPdf, setIsTestingPdf] = useState(false);
  const [isWebsiteImportModalOpen, setIsWebsiteImportModalOpen] =
    useState(false);
  const toggleWebsiteImportModal = () =>
    setIsWebsiteImportModalOpen((prev) => !prev);

  const handleItemClick = (index, item, type) => {
    console.log("Selected item:", index, item, type);
    setSelectedIndex(index);
    setSelectedItem(item);
    setItemType(type);
    fetchItemDetails(item, type);
  };

  useEffect(() => {
    if (clients.length > 0) {
      const firstClientWithAssistant = clients.find(
        (client) => client.assistantId,
      );
      if (firstClientWithAssistant) {
        handleItemClick(0, firstClientWithAssistant, "client");
      }
    }
  }, [clients]);

  const handleWebsiteSelect = async (websiteUrl) => {
    console.log("Importing website:", websiteUrl);
    try {
      let taskId;
      console.log("Selected item ID:", taskId);
      console.log("Item type:", itemType);
      if (itemType === "client") {
        taskId = selectedItem.clientId;
      } else if (itemType === "task") {
        taskId = selectedItem.id;
      }

      console.log("Selected item:", selectedItem);
      console.log("Selected assistant:", selectedItem.assistantId);
      console.log("Selected item ID:", taskId);

      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/scraper/start-crawler`,
        {
          data: websiteUrl,
          taskId: taskId,
          assistantId: selectedItem.assistantId,
        },
      );
      console.log("Website import response:", response);

      if (response.success) {
        console.log("Website import successful");
        console.log("Sources original:", sources);

        const newSource = {
          id: response.assistantSourceId,
          assistant_id: taskId,
          source_id: response.sourceId,
          assigned_spaces: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          rag_sources: {
            id: response.sourceId,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            source_type: "Website",
            source_description: websiteUrl.website,
          },
        };

        console.log("New source:", newSource);
        const updatedSources = [...sources, newSource].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        console.log("Sources updated:", updatedSources);
        setSources(updatedSources);
        console.log("Sources updated:", sources);
      } else {
        console.log("Website import failed");
      }
    } catch (err) {
      console.error("Error importing website:", err);
    }
  };

  const handleTestPdfProcessing = async () => {
    setIsTestingPdf(true);
    try {
      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/test-process-pdf`,
        { filePath: "4388.pdf" },
      );

      if (response.success) {
        console.log("PDF processing test successful:", response);
        //alert("PDF processing test successful!");
      } else {
        console.error("PDF processing test failed:", response);
        //alert("PDF processing test failed. Check console for details.");
      }
    } catch (error) {
      console.error("Error testing PDF processing:", error);
      //alert("Error testing PDF processing. Check console for details.");
    } finally {
      setIsTestingPdf(false);
    }
  };

  const fetchItemDetails = async (item, type) => {
    setIsLoading(true);
    setSources([]);
    console.log("Fetching item details:", item, type);
    try {
      let endpoint;
      let taskId = null;
      let assistantId = null;

      if (type === "client") {
        taskId = item.clientId;
        assistantId = item.assistantId;
      } else if (type === "task") {
        taskId = item.id;
        assistantId = item.assistantId;
      }

      endpoint = `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/confluence/fetch-documents?taskId=${taskId}&assistantId=${assistantId}`;
      const response = await apiCall("GET", endpoint);

      if (response.success) {
        console.log("Fetched data:", response);
        setSources(
          response.sources.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
          ),
        );
        setLastSyncedAt(
          response.lastSyncedAt
            ? new Date(response.lastSyncedAt).toLocaleString()
            : "Never",
        );
        setPagesSynced(response.pagesSynced);
      } else {
        setError(
          response.message ||
            `Failed to fetch ${type === "client" ? "tasks" : "sources"}.`,
        );
      }
    } catch (err) {
      console.error(
        `Error fetching ${type === "client" ? "tasks" : "sources"}:`,
        err,
      );
      setError(`Failed to fetch ${type === "client" ? "tasks" : "sources"}.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSpaceSelect = async (space) => {
    console.log("Selected Confluence Space:", space);
    console.log("Selected task:", selectedItem);

    if (!selectedItem || selectedItem.assistantId === undefined) {
      setError("No valid item selected. Please select a client or task first.");
      return;
    }

    try {
      let taskId;
      console.log("Selected item ID:", taskId);
      console.log("Item type:", itemType);
      if (itemType === "client") {
        taskId = selectedItem.clientId;
      } else if (itemType === "task") {
        taskId = selectedItem.id;
      }

      console.log("Selected item:", selectedItem);
      console.log("Selected assistant:", selectedItem.assistantId);
      console.log("Selected item ID:", taskId);

      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/confluence/add-space`,
        {
          taskId: taskId,
          assistantId: selectedItem.assistantId,
          space: space,
        },
      );

      if (response.success) {
        console.log("Successfully added the space to sources.");
        console.log("Response:", response);
        setSources((prevSources) => {
          const newSource = {
            id: response.data.id,
            assigned_spaces: [space.key],
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            rag_sources: {
              source_type: "Confluence Space",
              source_description: space.name,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
            },
          };
          const updatedSources = [...prevSources, newSource].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });

          return updatedSources;
        });

        console.log("Successfully added the space to sources.");
      } else {
        setError("Failed to save the selected space");
      }
    } catch (error) {
      console.error("Error importing space:", error);
      setError("Error importing space");
    }
  };

  const handleSiteSelect = async (selectedSites) => {
    console.log("Selected SharePoint Sites:", selectedSites);
    console.log("Selected task:", selectedItem);

    if (!selectedItem || selectedItem.assistantId === undefined) {
      setError("No valid item selected. Please select a client or task first.");
      return;
    }

    try {
      let taskId;
      if (itemType === "client") {
        taskId = selectedItem.clientId;
      } else if (itemType === "task") {
        taskId = selectedItem.id;
      }

      console.log("Selected item:", selectedItem);
      console.log("Selected assistant:", selectedItem.assistantId);
      console.log("Selected item ID:", taskId);

      const promises = selectedSites.flatMap((site) =>
        site.selectedFolders.map((folder) =>
          apiCall(
            "POST",
            `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/sharepoint/add-site`,
            {
              taskId: taskId,
              assistantId: selectedItem.assistantId,
              site: {
                id: site.id,
                name: site.name,
                description: site.description,
              },
              folder: {
                id: folder.id,
                name: folder.name,
                web_url: folder.web_url,
              },
              clientId: selectedItem.clientId,
            },
          ),
        ),
      );

      const responses = await Promise.all(promises);

      const successfulResponses = responses.filter(
        (response) => response.success,
      );

      if (successfulResponses.length > 0) {
        setSources((prevSources) => {
          const newSources = successfulResponses.map((response) => ({
            id: response.data.id,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at,
            rag_sources: {
              source_type: "SharePoint Folder",
              source_description: response.data.rag_sources.source_description,
              site_name: response.data.rag_sources.site_name,
              folder_name: response.data.rag_sources.folder_name,
              folder_path: response.data.rag_sources.folder_path,
              created_at: response.data.rag_sources.created_at,
              updated_at: response.data.rag_sources.updated_at,
            },
          }));

          const updatedSources = [...prevSources, ...newSources].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
          );

          return updatedSources;
        });

        console.log("Successfully added the folders to sources.");
      } else {
        setError("Failed to save the selected folders");
      }
    } catch (error) {
      console.error("Error importing folders:", error);
      setError("Error importing folders");
    }
  };

  const handleSync = (assistantId) => async () => {
    setSyncing(true);
    console.log("Selected task:", selectedItem);
    console.log("Syncing Confluence spaces for assistant ID:", assistantId);
    try {
      const response = await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/confluence/sync-confluence`,
        { assistantId },
      );

      console.log("Sync response:", response);
      setLastSyncedAt(response.lastSyncedTime);
      setPagesSynced(response.pagesSynced);

      if (!response.ok) {
        setError("Failed to start sync");
      }
    } catch (err) {
      console.error("Error starting sync:", err);
      setError("Failed to start sync");
    } finally {
      setSyncing(false);
    }
  };

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Paper
          elevation={theme.elevation}
          sx={{
            padding: theme.spacing(4),
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Training Data
          </Typography>
          <Grid
            container
            spacing={2}
            p={2}
            sx={{ marginTop: 3, marginBottom: 3 }}
          >
            {/* Left Column: Task List */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{ borderRight: "1px solid #333333", pr: 2 }}
            >
              <Typography variant="h6" gutterBottom>
                Clients
              </Typography>
              <List>
                {clients.length > 0 &&
                  clients
                    .filter((client) => client.assistantId)
                    .map((client, index) => (
                      <ListItemButton
                        key={client.clientId}
                        className="fade-in"
                        selected={
                          itemType === "client" && selectedIndex === index
                        }
                        onClick={() => handleItemClick(index, client, "client")}
                      >
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <img
                            className="fade-in"
                            src={client.url}
                            alt={client.clientName}
                            height="25px"
                            width="25px"
                            style={{ borderRadius: "5" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body1" color="textPrimary">
                            {client.clientName}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    ))}
              </List>

              {/* Task Section */}
              <Typography variant="h6" gutterBottom>
                Tasks
              </Typography>
              <List>
                {tasks.map((task, index) => (
                  <ListItemButton
                    className="fade-in"
                    key={task.id}
                    selected={itemType === "task" && selectedIndex === index}
                    onClick={() => handleItemClick(index, task, "task")}
                  >
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                      <Icon icon={task.icon} height="20px" width="20px" />
                    </ListItemIcon>
                    <ListItemText
                      primary={task.title}
                      sx={{
                        "& .MuiListItemText-primary": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        },
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Grid>

            {/* Right Panel: Task Details */}
            <Grid item xs={12} md={8} pl={2}>
              {selectedItem ? (
                <div className="fade-in">
                  <Typography variant="h5" gutterBottom>
                    {selectedItem.title || selectedItem.clientName}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {selectedItem.assistantId}
                  </Typography>
                  <Divider sx={{ my: 2 }} />

                  {/* Action Buttons */}
                  <Box display="flex" gap={2} mb={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={handleAddClick}
                    >
                      Add
                    </Button>
                  </Box>

                  <Typography variant="h6">Sources</Typography>
                  {isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: 300 }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : selectedItem ? (
                    sources.length > 0 ? (
                      <CollapsibleSourceList
                        sources={sources}
                        selectedItem={selectedItem}
                        lastSyncedAt={lastSyncedAt}
                        pagesSynced={pagesSynced}
                        setSources={setSources}
                        setError={setError}
                        itemType={itemType}
                      />
                    ) : (
                      <Typography>
                        No sources found for this {itemType}.
                      </Typography>
                    )
                  ) : (
                    <Typography>
                      Select a client or task to view details.
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Select a task from the list to view details.
                </Typography>
              )}
            </Grid>
          </Grid>

          <AddSourceMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onImportWebsite={() => {
              handleClose();
              console.log("Importing website");
              //handleWebsiteImport("https://www.fusion92.com");
              toggleWebsiteImportModal();
            }}
            onSyncConfluence={() => {
              handleClose();
              toggleConfluenceImportModal();
            }}
            onSyncSharePoint={() => {
              handleClose();
              toggleSharePointImportModal();
            }}
          />

          <ImportWebsiteModal
            open={isWebsiteImportModalOpen}
            handleClose={toggleWebsiteImportModal}
            onWebsiteSelect={handleWebsiteSelect}
          />
          <ImportConfluenceModal
            open={isConfluenceImportModalOpen}
            handleClose={toggleConfluenceImportModal}
            onSpaceSelect={handleSpaceSelect}
          />
          <ImportSharePointModal
            open={isSharePointImportModalOpen}
            handleClose={toggleSharePointImportModal}
            onSiteSelect={handleSiteSelect}
            sources={sources}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default TasksPage;
