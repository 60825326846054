import { Card, Box, CardContent, Typography, CardActionArea } from '@mui/material';
import { useThemeContext } from '../../context/ThemeContext';

const IntegrationCard = ({ title, description, icon, onClick, disabled }) => {
    const { darkMode, theme } = useThemeContext();

    return (
        <Card
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                minHeight: '140px',
                backgroundColor: disabled ? 'action.disabledBackground' : 'background.paper',
                cursor: disabled ? 'not-allowed' : 'pointer',
                boxShadow: 0,
                border: `1px solid ${theme.palette.border.main}`,
                opacity: disabled ? 0.25 : 1,
            }}
        >
            <CardActionArea
                onClick={disabled ? null : onClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    pointerEvents: disabled ? 'none' : 'auto',  // Disable interaction when needed
                }}
            >
                <Box
                    component="img"
                    src={icon}
                    alt={title}
                    sx={{
                        height: 40,
                        width: 40,
                        marginRight: 2,
                        margin: 3,
                    }}
                />
                <CardContent sx={{ marginLeft: 0, paddingLeft: 0 }}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default IntegrationCard;
