const tourSteps = [
    {
      target: ".first-step",
      content: "Welcome to the Dashboard! This is your starting point to dive into all the amazing features we have in store.",
      title: "Your Launchpad",
    },
    {
      target: ".second-step",
      content: "Ready for some AI magic? Click here to access our full version of ChatGPT, tailored just for Fusion92.",
      title: "AI Wonderland",
    },
    {
      target: ".dark-mode-toggle",
      content: "Feeling like a change? Toggle Dark Mode to switch up the look and feel of the app.",
      title: "Style Switcher",
    },
    {
      target: ".second-task-item",
      content: "Here's a sneak peek at our automation power! Click a task to launch automations or start GPT with a feeder prompt.",
      title: "Automation Magic",
    },
    {
      target: ".submit-task-idea",
      content: "Got a bright idea? Submit your task ideas here and help us build something for you!",
      title: "Idea Hub",
      placement: "top",
    },
  ];
  
  export default tourSteps;
  