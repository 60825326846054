import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import {
  Close as CloseIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const FilePreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderRadius: "10px",
  backgroundColor: theme.palette.mode === "light" ? "#f0f0f0" : "#333",
  marginBottom: "10px",
  boxShadow: theme.shadows[1],
  width: "100%",
  maxWidth: "700px",
}));

const FilePreview = ({ file, onRemove }) => {
  if (!file) return null;

  return (
    <FilePreviewContainer>
      <AttachFileIcon sx={{ marginRight: 1 }} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" noWrap>
          {file.name}
        </Typography>
        <Typography variant="caption" color="textSecondary" noWrap>
          {file.type} - {(file.size / 1024).toFixed(2)} KB
        </Typography>
      </Box>
      <IconButton size="small" onClick={onRemove} aria-label="remove file">
        <CloseIcon fontSize="small" />
      </IconButton>
    </FilePreviewContainer>
  );
};

FilePreview.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }),
  onRemove: PropTypes.func.isRequired,
};

export default FilePreview;
