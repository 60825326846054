import React, { createContext, useContext } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CustomSnackbar from "../components/common/CustomSnackbar";
import { useThemeContext } from "./ThemeContext";
import {
  removeNotification,
  addNotification,
} from "../reducers/notificationsSlice";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const { darkMode } = useThemeContext();
  const dispatch = useDispatch();
  const snackbars = useSelector((state) => state.notifications.notifications);

  const addSnackbar = (options) => {
    const {
      id,
      title,
      description,
      link = null,
      duration = 10000,
      isGlobal = false,
      notification = null
    } = options;

    dispatch(
      addNotification({
        id: id || Date.now() + Math.random(),
        title,
        description,
        link,
        duration,
        darkMode,
        isGlobal,
        notification
      }),
    );
  };

  const removeSnackbar = (id) => {
    dispatch(removeNotification(id));
  };

  return (
    <SnackbarContext.Provider value={{ addSnackbar, removeSnackbar }}>
      {children}
      <SnackbarContainer
        snackbars={snackbars}
        removeSnackbar={removeSnackbar}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);

const SnackbarContainer = ({ snackbars, removeSnackbar }) => (
  <Box
    sx={{
      position: "fixed",
      top: 15,
      right: 30,
      display: "flex",
      flexDirection: "column",
      gap: 1,
      zIndex: 1400,
      width: "auto",
      "@media (max-width: 600px)": {
        width: "100%",
        right: 0,
        left: 0,
      },
    }}
  >
    {snackbars.map((snackbar) => (
      <CustomSnackbar
        key={snackbar.id}
        {...snackbar}
        onClose={() => removeSnackbar(snackbar.id)}
      />
    ))}
  </Box>
);
