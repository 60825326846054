import React, { useState, useEffect, useRef, forwardRef, memo } from 'react';
import { LinearProgress, Typography, Box, Paper, useMediaQuery, IconButton } from '@mui/material';
import { Info as InfoIcon, Close as CloseIcon, Campaign as CampaignIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { useThemeContext } from '../../context/ThemeContext';
import { useUser } from '@/context/UserContext';
import { supabase } from "../../supabaseClient";

const SlideInSnackbar = styled(animated(Paper))(({ theme }) => ({
  minWidth: '350px',
  maxWidth: '350px',
  borderRadius: 10,
  display: 'flex',
  padding: 0,
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: theme.shadows[24],
  zIndex: 1400,
  overflow: 'hidden',
  opacity: 1,
  '&.open': {
    opacity: 1,
  },
  '&.closing': {
    opacity: 1,
  },
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    width: 'calc(100% - 30px)',
    minWidth: 'calc(100% - 30px)',
    maxWidth: 'calc(100% - 30px)',
    left: 15,
    right: 15,
    borderRadius: "10px",
  },
}));

const SnackbarContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 0,
  width: '100%',
  minHeight: 70,
}));

const SnackbarText = styled(Box)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(1),
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
}));

const CustomSnackbar = forwardRef(({ 
  id, 
  title, 
  description, 
  url, 
  duration = 10000, 
  onClose,
  isGlobal = false,
  notification = null
}, ref) => {
  const [progress, setProgress] = useState(100);
  const intervalRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const { darkMode } = useThemeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDragging, setIsDragging] = useState(false);
  const dragTimeoutRef = useRef(null);
  const { user } = useUser();

  const [springProps, api] = useSpring(() => ({
    x: isMobile ? 0 : 450,
    y: isMobile ? -130 : 0, 
    opacity: 1,
  }));

  useEffect(() => {
    setTimeout(() => setOpen(true), 50);
    api.start({ x: 0, y: 0, opacity: 1 });

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (dragTimeoutRef.current) clearTimeout(dragTimeoutRef.current);
    };
  }, [id, title, api]);

  useEffect(() => {
    if (!isGlobal) {
      const decrement = 100 / (duration / 100);
      intervalRef.current = setInterval(() => {
        setProgress((prev) => {
          const newValue = prev - decrement;
          if (newValue <= 0) {
            clearInterval(intervalRef.current);
            handleClose();
            return 0;
          }
          return newValue;
        });
      }, 100);

      return () => clearInterval(intervalRef.current);
    }
  }, [duration, isGlobal]);

  const handleClose = async () => {
    try {
      if (isGlobal && notification?.id) {
        console.log("✍️ Marking global notification as seen:", notification.id);
        const { error } = await supabase
          .from('notifications_global_status')
          .insert({
            user_email: user.email,
            global_notification_id: notification.id
          });

        if (error) {
          console.error("❌ Error marking notification as seen:", error);
        }
      } else if (!isGlobal && notification?.id) {
        // First mark as read and delivered
        console.log("📫 Marking user notification as read:", notification.id);
        const { error: updateError } = await supabase
          .from('notifications_user')
          .update({ 
            is_read: true,
            delivered: true 
          })
          .eq('id', notification.id);

        if (updateError) {
          console.error("❌ Error marking user notification as read:", updateError);
        }

        // Then delete after a short delay to ensure the update completes
        setTimeout(async () => {
          console.log("🗑️ Deleting user notification:", notification.id);
          const { error: deleteError } = await supabase
            .from('notifications_user')
            .delete()
            .eq('id', notification.id);

          if (deleteError) {
            console.error("❌ Error deleting user notification:", deleteError);
          }
        }, 500);
      }

      setIsClosing(true);
      setTimeout(() => {
        api.start({ x: isMobile ? 0 : 450, y: isMobile ? -130 : 0, opacity: 1 });
      }, 500);
      
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error('❌ Error in handleClose:', error);
      onClose();
    }
  };

  const handleDismiss = async () => {
    try {
      if (isGlobal && notification?.id) {
        console.log("✍️ Marking global notification as seen:", notification.id);
        const { error } = await supabase
          .from('notifications_global_status')
          .insert({
            user_email: user.email,
            global_notification_id: notification.id
          });

        if (error) {
          console.error("❌ Error marking notification as seen:", error);
        }
      } else if (!isGlobal && notification?.id) {
        console.log("📫 Marking and removing user notification:", notification.id);
        const { error: deleteError } = await supabase
          .from('notifications_user')
          .delete()
          .eq('id', notification.id);

        if (deleteError) {
          console.error("❌ Error deleting user notification:", deleteError);
        }
      }

      setIsClosing(true);
      setTimeout(() => {
        api.start({ x: isMobile ? 0 : 450, y: isMobile ? -130 : 0, opacity: 1 });
      }, 500);
      
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error('❌ Error in handleDismiss:', error);
      onClose();
    }
  };

  const bind = useDrag(({ down, movement: [mx, my], velocity }) => {
    const VELOCITY_THRESHOLD = 0.5;
    setIsDragging(down);

    if (isMobile) {
      api.start({ y: down ? my : 0, immediate: down });
  
      if (!down) {
        if (my > 0 && (my > 5 || velocity > VELOCITY_THRESHOLD)) {
          api.start({ y: 130, opacity: 1 });
          setTimeout(handleDismiss, 300);
        } else if (my < 0 && (my < -5 || velocity > VELOCITY_THRESHOLD)) {
          api.start({ y: -130, opacity: 1 });
          setTimeout(handleDismiss, 300);
        }
      }
    } else {
      api.start({ x: down ? mx : 0, immediate: down });
  
      if (!down) {
        if (mx < 0 && (mx < -5 || velocity > VELOCITY_THRESHOLD)) {
          api.start({ x: -450, opacity: 1 });
          setTimeout(handleDismiss, 300);
        } else if (mx > 0 && (mx > 5 || velocity > VELOCITY_THRESHOLD)) {
          api.start({ x: 450, opacity: 1 });
          setTimeout(handleDismiss, 300);
        }
      }
    }
  });
  
  const handleClick = (event) => {
    event.stopPropagation();
    if (!isDragging && url) {
      window.open(url, '_blank');
    }
  };

  return (
    <SlideInSnackbar
      className={isClosing ? 'closing' : open ? 'open' : ''}
      ref={ref}
      style={{ 
        ...springProps, 
        touchAction: 'none', 
        backgroundColor: '#fff',
        cursor: url ? 'pointer' : 'default' 
      }}
      elevation={24}
      {...bind()}
      onClick={handleClick} 
    >
      <SnackbarContent sx={{ padding: 1, overflow: 'hidden' }}>
        {isGlobal ? (
          <CampaignIcon 
            sx={{ 
              color: "#2196F3",  // Material UI Blue
              fontSize: 40, 
              alignSelf: 'center' 
            }} 
          />
        ) : (
          <InfoIcon 
            sx={{ 
              color: "#FF9800",  // Keep orange for user notifications
              fontSize: 40, 
              alignSelf: 'center' 
            }} 
          />
        )}
        <SnackbarText>
          <Typography 
            variant="body1" 
            color="#1a1a1a" 
            sx={{ userSelect: 'none', cursor:'default', fontWeight: 'bold' }}
          >
            {title || 'Notification'}
          </Typography>
          <Typography 
            variant="body2" 
            color="#1a1a1a" 
            sx={{ userSelect: 'none', cursor:'default' }}
          >
            {description || ''}
          </Typography>
        </SnackbarText>
        {isGlobal && (
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            sx={{ 
              color: '#1a1a1a',
              marginRight: 1
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </SnackbarContent>
      {!isGlobal && (
        <ProgressWrapper>
          <LinearProgress 
            variant="determinate" 
            color="primary" 
            value={progress} 
            sx={{ padding: 0, height: 3 }} 
          />
        </ProgressWrapper>
      )}
    </SlideInSnackbar>
  );
});

export default memo(CustomSnackbar);
