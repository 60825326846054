import React, { useEffect, useState } from "react";
import { Box, Typography, Chip, Grid, Paper, Card, CardContent, Skeleton, useMediaQuery, LinearProgress, Button, Container } from "@mui/material";
import PriorityCard from "@components/common/PriorityCard";
import NewsCard from "@components/common/NewsCard";
import NewsCardSkeleton from "@components/common/NewsCardSkeleton";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "@context/ThemeContext";
import NewsCarousel from "@/components/common/NewsCarousel";
import sanityClient from "../sanityClient";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { isAndroidOrIOS } from "../utils/deviceDetection";

const AINews = () => {
  const [pageContent, setPageContent] = useState(null);
  const { apiCall } = useApiWithAuth();
  const theme = useTheme();
  const { darkMode, handleThemeChange } = useThemeContext();
  const [articles, setArticles] = useState(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [aiInitiatives, setAiInitiativesContent] = useState(null);
  const navigate = useNavigate();
  const [selectedPhase, setSelectedPhase] = useState(null);
  const isMobile = isAndroidOrIOS();

  useEffect(() => {
    if (pageContent && pageContent.length > 0) {
      const firstPhase = pageContent[0].phase;
      setSelectedPhase(firstPhase);
    }
  }, [pageContent]);

  const handleChipClick = (phase) => {
    if (selectedPhase !== phase) {
      setSelectedPhase(phase);
    }
  };

  const filteredContent = selectedPhase
    ? pageContent.filter(item => item.phase === selectedPhase)
    : pageContent;
    
  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const data = await sanityClient.fetch('*[_type == "initiatives"]');
        const processedInitiatives = data.map(initiative => ({
          icon: initiative.myIcon.name,
          title: initiative.title,
          progress: initiative.progress,
        }));
        setAiInitiativesContent(processedInitiatives);
      } catch (error) {
        console.error("Error fetching initiatives:", error);
      }
    };

    fetchInitiatives();
  }, []);


  const fetchAttachments = async (pageId) => {
    try {
      const attachmentUrl = `https://fusion92.atlassian.net/wiki/rest/api/content/${pageId}/child/attachment`;
      const encodedAttachmentUrl = encodeURIComponent(attachmentUrl);
      const response = await apiCall(
        "GET",
        `/confluence?url=${encodedAttachmentUrl}`,
      );
      return response.results || [];
    } catch (error) {
      console.error("Error fetching attachments:", error);
      return [];
    }
  };

  const fetchImageAsBase64 = async (downloadUrl) => {
    try {
      const encodedDownloadUrl = encodeURIComponent(downloadUrl);
      const response = await apiCall(
        "GET",
        `/confluence/getConfluenceImage?url=${encodedDownloadUrl}`,
        {
          responseType: "arraybuffer",
        },
      );
      return response.base64;
    } catch (error) {
      return null;
    }
  };

  const extractImageUrls = async (bodyContent, pageId) => {
    const acImageRegex =
      /<ac:image[^>]*>.*?<ri:attachment[^>]*ri:filename="([^"]*)"/g;
    let matches;
    const imgUrls = [];

    while ((matches = acImageRegex.exec(bodyContent)) !== null) {
      const filename = matches[1];
      const attachments = await fetchAttachments(pageId);
      const attachment = attachments.find((att) => att.title === filename);

      if (attachment) {
        const downloadUrl = `https://fusion92.atlassian.net/wiki${attachment._links.download}`;
        const base64Image = await fetchImageAsBase64(downloadUrl);
        if (base64Image) {
          imgUrls.push(base64Image);
        }
      } else {
        console.error("Attachment not found for filename:", filename);
      }
    }

    return imgUrls;
  };

  useEffect(() => {
    const fetchPageContent = async (pageId) => {
      try {
        const url = `https://fusion92.atlassian.net/wiki/rest/api/content/${pageId}?expand=version,history,children.page.body.storage,children.page.history.lastUpdated,children.page.history,children.page._links.webui,children.page.history.createdBy.profilePicture`;
        const encodedUrl = encodeURIComponent(url);
        // console.log("fetchPageContent", `/confluence?url=${encodedUrl}`);
        const data = await apiCall("GET", `/confluence?url=${encodedUrl}`);
        // console.log("fetchPageContent", data);
        return data;
      } catch (error) {
        console.error("Error fetching page content:", error);
        return null;
      }
    };

    const fetchAllContent = async (pageId) => {
      // console.log("fetchAllContent", pageId);
      const topLevelData = await fetchPageContent(pageId);
      if (!topLevelData) return;

      let allPages = [];

      for (const page of topLevelData.children.page.results) {
        const parentPageTitle = page.title;
        const subPageData = await fetchPageContent(page.id);
        if (subPageData) {
          const subPages = subPageData.children.page.results.map((subPage) => {
            return {
              title: subPage.title,
              body: subPage.body?.storage?.value,
              createdBy: subPage.history?.createdBy?.displayName || "Unknown",
              lastUpdated:
                subPage.history?.lastUpdated?.friendlyWhen || "Unknown date",
              link: `https://fusion92.atlassian.net/wiki${subPage._links?.webui}`,
              profileImage: `https://fusion92.atlassian.net${subPage.history?.createdBy?.profilePicture?.path}`,
              id: subPage.id,
              imageUrls: null,
              phase: parentPageTitle,
              loading: true,
            };
          });
          allPages = [...allPages, ...subPages];
          // console.log("All pages including children:", allPages);
          setPageContent([...allPages]);
        }
      }

      // // Fetch images for each subPage and update state
      // for (const page of topLevelData.children.page.results) {
      //   const subPageData = await fetchPageContent(page.id);
      //   if (subPageData) {
      //     for (const subPage of subPageData.children.page.results) {
      //       const imageUrls = await extractImageUrls(
      //         subPage.body?.storage?.value,
      //         subPage.id,
      //       );
      //       const firstImageUrl = imageUrls.length > 0 ? imageUrls[0] : null;
      //       setPageContent((prevState) =>
      //         prevState.map(
      //           (item) =>
      //             item.id === subPage.id
      //               ? { ...item, imageUrls: firstImageUrl, loading: false }
      //               : item,
      //           setAnyImageLoaded(true),
      //         ),
      //       );
      //     }
      //   }
      // }
      // console.log("All pages including children:", allPages);
    };
    fetchAllContent("864551119");
  }, [apiCall]);

  const handleImageLoad = () => {
    setAnyImageLoaded(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      // console.log("Fetching articles...");
      try {
        const response = await apiCall("GET", "/ainews");
        console.log("Articles Full Response:", response);
        setArticles(response);
      } catch (err) {
        console.error("Error fetching articles:", err);
      }
    };
    fetchData();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const generateSkeletons = (count) => {
    return Array.from(new Array(count)).map((_, index) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={4}
        key={index}
        style={{ display: "flex" }}
      >
        <PriorityCard loading={true} /> {/* Show skeleton state */}
      </Grid>
    ));
  };

  return (
    <Container maxWidth="lg" disableGutters>
    <Box className="p-4 fade-in pt-20 pb-20">      
      {articles ? (
        <Box mb={3} >
          <NewsCarousel items={articles} className="fade-in" />
        </Box>
      ) : (
        <Box mb={3} >
          <Skeleton variant="rounded" width="100%" sx={{ borderRadius:"30px", height:isSmallScreen ? '300px' : '400px',}} /> 
        </Box>
      )}
      
      <Box>
        <Typography
          variant="h5"
          mt={3}
          component="h1"
          textAlign={"left"}
          gutterBottom
          sx={{ userSelect: "none", }}
        >
          AI News
        </Typography>
        <hr
          style={{
            width: "100%",
            border: "none",
            borderBottom: darkMode
              ? "1px dashed rgba(255,255,255,.25)"
              : "1px dashed rgba(0,0,0,.25)",
            margin: "10px 0 0 0",
          }}
        />
      </Box>


      <Grid
        container
        spacing={2}
        sx={{ mt: 0 }}
        style={{ display: "flex", alignItems: "stretch" }}
      >
        {!articles ? (
          Array.from(new Array(5)).map((_, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              key={index}
              style={{ display: "flex" }}
            >
              <NewsCardSkeleton />
            </Grid>
          ))
        ) : (
          articles.slice(1).map((article, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={index}
            style={{ display: "flex" }}
          >
            <NewsCard
              title={article.title}
              image={article.image}
              link={article.link}
              intro={article.intro}
              source={article.source}
              date={article.date}
              isMobile={isMobile}
            />
          </Grid>
          ))
        )}
      </Grid>

   </Box>
    </Container>
  );
};

export default AINews;
