import React, { useState } from "react";
import {
  Box,
  Typography,
  Collapse,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const CitationList = ({ citations }) => {
  const [selectedCitation, setSelectedCitation] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const openModal = (citation) => {
    setSelectedCitation(citation);
  };

  const closeModal = () => {
    setSelectedCitation(null);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={toggleExpand}
      >
        <Typography variant="h6" component="div" sx={{ marginRight: 0.5 }}>
          References
        </Typography>
        <IconButton size="small">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
          {citations.map((citation, index) => (
            <Button
              key={index}
              variant="outlined"
              size="small"
              onClick={() => openModal(citation)}
              sx={{
                minWidth: 0,
                padding: "4px 8px",
                fontSize: "0.75rem",
                color: "primary.main",
                borderColor: "primary.main",
                justifyContent: "flex-start", // Align text to the start
                marginTop: "2px",
              }}
            >
              {`[${index + 1}] ${citation.title}`}
            </Button>
          ))}
        </Box>
      </Collapse>

      <Dialog
        open={!!selectedCitation}
        onClose={closeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{selectedCitation?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Typography
              variant="body2"
              paragraph
              sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <a
                target="_blank"
                href={selectedCitation?.url}
                style={{ textDecoration: "underline", color: "inherit" }}
              >
                {selectedCitation?.url}
              </a>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Content:</strong>
            </Typography>
            <Box
              sx={{
                maxHeight: 400,
                overflow: "auto",
                mt: 1,
                p: 1,
                bgcolor: "background.paper",
              }}
            >
              <Typography
                variant="body2"
                component="pre"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {selectedCitation?.content}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CitationList;
