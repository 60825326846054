import React, { useEffect, useState } from "react";
import { 
  Box, 
  Typography, 
  Chip, 
  Grid, 
  Button,
  TextField,
  Paper, 
  Card, 
  CardContent, 
  Skeleton, 
  useMediaQuery, 
  LinearProgress, 
  Container, 
  IconButton, 
  Badge 
} from "@mui/material";
import PriorityCard from "@components/common/PriorityCard";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useTheme } from "@mui/material/styles";
import { useThemeContext } from "@context/ThemeContext";
import CustomCarousel from "@/components/common/CustomCarousel";
import sanityClient from "../sanityClient";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { keyframes } from "@emotion/react";
import { supabase } from "../supabaseClient";
import { useUser } from "@context/UserContext";
import axios from "axios";

const AITools = () => {
  const { user } = useUser();
  const [pageContent, setPageContent] = useState(null);
  const { apiCall } = useApiWithAuth();
  const theme = useTheme();
  const { darkMode, handleThemeChange } = useThemeContext();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [aiInitiatives, setAiInitiativesContent] = useState(null);
  const navigate = useNavigate();
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [carouselItems, setCarouselItems] = useState([]);
  const [liked, setLiked] = useState(false);
  const [likesData, setLikesData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Control whether to show the form
  const [taskDescription, setTaskDescription] = useState("");
  const [submitted, setSubmitted] = useState(false);
  
  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const sanityData = await sanityClient.fetch(
          `*[_type == "initiatives"]{_id, title, myIcon{name}, progress}`
        );
        const sanityIds = sanityData.map((item) => item._id);
  
        const { data: likesData, error: likesError } = await supabase
          .from('initiative_likes_summary')
          .select('*')
          .in('sanity_id', sanityIds);
  
        const { data: userLikes, error: userLikesError } = await supabase
          .from('initiative_likes')
          .select('sanity_id, liked')
          .eq('user_id', user.email)
          .in('sanity_id', sanityIds);
  
        if (likesError || userLikesError) {
          console.error("Error fetching likes:", likesError || userLikesError);
        } else {
          const likesMapping = likesData.reduce((acc, item) => {
            acc[item.sanity_id] = item.total_likes;
            return acc;
          }, {});
  
          const userLikesMapping = userLikes.reduce((acc, item) => {
            acc[item.sanity_id] = item.liked;
            return acc;
          }, {});
  
          const mappedInitiatives = sanityData.map((initiative) => ({
            _id: initiative._id,
            title: initiative.title,
            icon: initiative.myIcon.name,
            progress: initiative.progress,
            likes: likesMapping[initiative._id] || 0,
            liked: userLikesMapping[initiative._id] || false,
          }));
  
          setAiInitiativesContent(mappedInitiatives);
        }
      } catch (error) {
        console.error("Error fetching initiatives:", error);
      }

      const likesChannel = supabase
        .channel("likes_channel")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "initiative_likes",
          },
          (payload) => {
            fetchInitiatives();
          }
        )
        .subscribe();
    
      return () => {
        supabase.removeChannel(likesChannel);
      };

    };
  
    fetchInitiatives();
  }, [user.email]);

  const handleLikeClick = async (index, initiative) => {
    try {
      const sanityId = initiative._id;
      const { data: existingLike, error: fetchError } = await supabase
        .from('initiative_likes')
        .select('id, liked')
        .eq('sanity_id', sanityId)
        .eq('user_id', user.email)
        .maybeSingle();
      
      if (fetchError && fetchError.code !== 'PGRST116') {
        console.error("Error checking existing like:", fetchError);
        return;
      }
  
      const updatedInitiative = { ...initiative };
      let liked, updatedLikes;
  
      if (existingLike?.liked) {
        liked = false;
        updatedLikes = initiative.likes - 1;
  
        const { error: deleteError } = await supabase
          .from('initiative_likes')
          .delete()
          .eq('sanity_id', sanityId)
          .eq('user_id', user.email);
  
        if (deleteError) {
          console.error("Error removing like from Supabase:", deleteError);
          return;
        }
      } else {
        liked = true;
        updatedLikes = initiative.likes + 1;
  
        const { error: insertError } = await supabase
          .from('initiative_likes')
          .upsert({
            sanity_id: sanityId,
            user_id: user.email,
            liked: liked,
          });
  
        if (insertError) {
          console.error("Error inserting like into Supabase:", insertError);
          return;
        }
      }
  
      updatedInitiative.likes = updatedLikes;
      updatedInitiative.liked = liked;
  
      setAiInitiativesContent((prevInitiatives) => {
        const newInitiatives = [...prevInitiatives];
        newInitiatives[index] = updatedInitiative;
        return newInitiatives;
      });

    } catch (error) {
      console.error("Error handling like click:", error);
    }
  };

  const handleFormToggle = () => {
    setShowForm((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setSubmitted(true);
      const response = await axios.post(
        "https://hook.us1.make.com/vw9w85syyaa4qav4ukp8js31uhqkdx0h",
        {
          description: taskDescription,
        }
      );
      console.log("Webhook response:", response.data);
      setSubmitted(true);
    } catch (error) {
      console.error("Error calling webhook:", error);
    }
  };

  useEffect(() => {
    const fetchCarousel = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "aiCarousel"][0]{ title, banners[]->{ title, description, image, ctaText, url } }`,
        );
        //console.log("Carousel data:", data);  
        setCarouselItems(data.banners || []);
      } catch (error) {
        //console.error("Error fetching carousel items:", error);
      }
    };
    fetchCarousel();
  }, []);

  useEffect(() => {
    if (pageContent && pageContent.length > 0) {
      const firstPhase = pageContent[0].phase;
      setSelectedPhase(firstPhase);
    }
  }, [pageContent]);

  const handleChipClick = (phase) => {
    if (selectedPhase !== phase) {
      setSelectedPhase(phase);
    }
  };

  const scaleAnimation = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  `;

  const filteredContent = selectedPhase
    ? pageContent.filter(item => item.phase === selectedPhase)
    : pageContent;
  
  useEffect(() => {
    const fetchPageContent = async (pageId) => {
      try {
        const url = `https://fusion92.atlassian.net/wiki/rest/api/content/${pageId}?expand=version,history,children.page.body.storage,children.page.history.lastUpdated,children.page.history,children.page._links.webui,children.page.history.createdBy.profilePicture`;
        const encodedUrl = encodeURIComponent(url);
        const data = await apiCall("GET", `/confluence?url=${encodedUrl}`);
        return data;
      } catch (error) {
        console.error("Error fetching page content:", error);
        return null;
      }
    };

    const fetchAllContent = async (pageId) => {
      const topLevelData = await fetchPageContent(pageId);
      if (!topLevelData) return;

      let allPages = [];

      for (const page of topLevelData.children.page.results) {
        const parentPageTitle = page.title;
        const subPageData = await fetchPageContent(page.id);
        if (subPageData) {
          const subPages = subPageData.children.page.results.map((subPage) => {
            return {
              title: subPage.title,
              body: subPage.body?.storage?.value,
              createdBy: subPage.history?.createdBy?.displayName || "Unknown",
              lastUpdated:
                subPage.history?.lastUpdated?.friendlyWhen || "Unknown date",
              link: `https://fusion92.atlassian.net/wiki${subPage._links?.webui}`,
              profileImage: `https://fusion92.atlassian.net${subPage.history?.createdBy?.profilePicture?.path}`,
              id: subPage.id,
              imageUrls: null,
              phase: parentPageTitle,
              loading: true,
            };
          });
          allPages = [...allPages, ...subPages];
          // console.log("All pages including children:", allPages);
          setPageContent([...allPages]);
        }
      }
    };
    fetchAllContent("864551119");
  }, [apiCall]);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const generateSkeletons = (count) => {
    return Array.from(new Array(count)).map((_, index) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={4}
        key={index}
        style={{ display: "flex" }}
      >
        <PriorityCard loading={true} /> {/* Show skeleton state */}
      </Grid>
    ));
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <Box className="p-4 fade-in pt-20 pb-20">      

        {carouselItems ? (
          <Box mb={3} >
            <CustomCarousel items={carouselItems} />
          </Box>
        ) : (
          <Box mb={3} >
            <Skeleton variant="rounded" width="100%" sx={{ borderRadius:"30px", height:isSmallScreen ? '300px' : '400px',}} /> 
          </Box>
        )}

        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4} order={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}>
          <Box
            sx={{ padding: theme.spacing(0), borderRadius: theme.shape.borderRadius, height: "100%", width: "100%" }}
          >
            <React.Fragment>
              <Typography
                variant="h5"
                mt={0}
                component="h1"
                textAlign={"left"}
                gutterBottom
                sx={{ userSelect: "none" }}
              >
                AI Current Initiatives
              </Typography>
              <hr
                style={{
                  width: "100%",
                  border: "none",
                  borderBottom: darkMode
                    ? "1px dashed rgba(255,255,255,.25)"
                    : "1px dashed rgba(0,0,0,.25)",
                  margin: "px 0",
                }}
              />

            <div>
                {!aiInitiatives ? (
                  [...Array(7)].map((_, index) => (
                    <Card
                      className="fade-in"
                      elevation={0}
                      key={index}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        border: `1px solid ${theme.palette.border.main}`,
                        mt: 2,
                        mb: 2,
                        height: 91,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "100%",
                          mt:.5,
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          width={20}
                          height={20}
                          sx={{ mr: 2 }}
                        />
                        <Skeleton variant="rectangle" width="80%" sx={{ mb: 0 }} />
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  aiInitiatives ? (
                      aiInitiatives.map((initiative, index) => (
                        <Card
                          key={initiative.title + index}
                          elevation={0}
                          className="fade-in"
                          sx={{
                            borderRadius: theme.shape.borderRadius,
                            border: `1px solid ${theme.palette.border.main}`,
                            mt: 2,
                            mb: 2,
                            transition: "background-color 0.3s ease",
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              padding:1.5,
                            }}
                          >
                            <Icon
                              icon={initiative.icon}
                              color="primary"
                              width="24"
                              height="24"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            />
                            <Typography
                              variant="body2"
                              component="p"
                              sx={{
                                userSelect: "none",
                                ml: 2,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {initiative.title}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                ml: "auto",
                              }}
                            >
                              <IconButton
                                color="secondary"
                                size="small"
                                onClick={() => handleLikeClick(index, initiative)} // Pass initiative data
                                sx={{
                                  animation: `${scaleAnimation} 0.3s ease`,
                                  padding:0,
                                }}
                              >
                                {initiative.liked ? (
                                  <FavoriteIcon
                                    sx={{ color: "red", animation: `${scaleAnimation} 0.3s ease` }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon
                                    sx={{ animation: `${scaleAnimation} 0.3s ease` }}
                                  />
                                )}
                              </IconButton>
                              
                              <Typography
                                variant="caption"
                                component="p"
                                color="secondary"
                                sx={{
                                  userSelect: "none",
                                  mt: 0,
                                }}
                              >
                                {initiative.likes > 0 
                                  ? `${initiative.likes} ${initiative.likes === 1 ? 'Like' : 'Likes'}`
                                  : `0 Likes`
                                }
                              </Typography>
                            </Box>
                          </CardContent>
                          <Box sx={{ px: 0, pb: 0 }}>
                            <LinearProgress variant="determinate" value={initiative.progress} />
                          </Box>
                        </Card>
                      ))
                  ) : null
                )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minWidth: "100%",
                }}
              >
                {submitted ? (
                  <Box className="fade-in" align="center" sx={{ my: 7 }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                      Thank you for your idea!
                    </Typography>
                  </Box>
                ) : showForm ? (
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{ mt: 2, width: "100%" }}
                    onSubmit={handleSubmit}
                    className="fade-in"
                  >
                    <TextField
                      id="task-description"
                      label="Outline your new initiative..."
                      multiline
                      rows={4}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 2 }}
                      value={taskDescription}
                      onChange={(e) => setTaskDescription(e.target.value)}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{ mb: 2 }}
                    >
                      Submit
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Icon icon="mdi:chart-timeline-variant-shimmer" width={30} />}
                    size="large"
                    onClick={handleFormToggle}
                    sx={{ mb: 4, width: "100%", height: "60px" }}
                  >
                    Request a New Initiative
                  </Button>
                )}
              </Box>
            </div>

            </React.Fragment>


            
            
          </Box>
        </Grid>

          <Grid item xs={12} sm={12} md={12} lg={8} order={{ xs: 2, sm: 2 }}>
            <Typography
              variant="h5"
              mt={0}
              component="h1"
              textAlign={"left"}
              gutterBottom
              sx={{ userSelect: "none" }}
            >
              Tools in Evaluation
            </Typography>
            <hr
              style={{
                width: "100%",
                border: "none",
                borderBottom: darkMode
                  ? "1px dashed rgba(255,255,255,.25)"
                  : "1px dashed rgba(0,0,0,.25)",
                margin: "10px 0 0 0",
              }}
            />
          {pageContent && pageContent.length > 0 ? (
            <React.Fragment>
              

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt:"18px", mb: 0 }}>
              {Array.from(new Set(pageContent.map(item => item.phase))).map((phase, index) => (
                <Chip
                  key={index}
                  label={phase}
                  clickable
                  color={selectedPhase === phase ? 'primary' : 'default'}
                  onClick={() => handleChipClick(phase)}
                  sx={{
                    boxShadow: 0,
                    textTransform: 'uppercase',
                    borderRadius: 2,
                    cursor: 'pointer',
                    fontSize: '14px',
                    paddingY: '15px',
                    paddingX: '0px',
                    border: `1px solid ${theme.palette.border.main}`,
                    backgroundColor: selectedPhase === phase 
                      ? theme.palette.background.paper
                      : theme.palette.background.paper,
                    color: selectedPhase === phase 
                      ? theme.palette.primary.contrastText 
                      : theme.palette.text.primary,
                    '&:hover': {
                      color: theme.palette.black.main,
                    },
                  }}
                />
              ))}
            </Box>

            {Object.entries(
              filteredContent.reduce((acc, item) => {
                if (!acc[item.phase]) {
                  acc[item.phase] = [];
                }
                acc[item.phase].push(item);
                return acc;
              }, {}),
            ).map(([parentTitle, items], index) => (
              <React.Fragment key={index}>
                
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 0 }}
                  style={{ display: "flex", alignItems: "stretch" }}
                >
                  {items.map((item, idx) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={4}
                      key={idx}
                      style={{ display: "flex" }}
                    >
                      <PriorityCard
                        brand={item.brand}
                        darkMode={darkMode}
                        title={item.title}
                        body={removeTags(
                          truncateText(
                            item.body || "No description available",
                            200,
                          ),
                        )}
                        updatedAt={item.lastUpdated || "Unknown date"}
                        owner={item.createdBy || "Unknown owner"}
                        url={item.link}
                        profileImage={item.profileImage}
                        imageUrls={item.imageUrls}
                        phase={item.phase}
                        loading={false}
                      />
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt:"18px", mb: 2 }}>
              <Skeleton
                variant="rounded"
                width="30%"
                height={42}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mt: 0,
                justifyContent: "center",
              }}
            >
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ mt: 0 }}
              style={{ display: "flex", alignItems: "stretch" }}
            >
              {generateSkeletons(6)}
            </Grid>
          </React.Fragment>
        )}
          </Grid>

          
      </Grid>
      
    </Box>
    </Container>
  );
};

export default AITools;
