import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material";
import axios from "axios";

const SubmitIdea = ({ darkMode }) => {
  const theme = useTheme();
  const [taskDescription, setTaskDescription] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://hook.us1.make.com/vw9w85syyaa4qav4ukp8js31uhqkdx0h",
        {
          description: taskDescription,
        },
      );
      console.log("Webhook response:", response.data);
      // Set the submitted state to true to show the message
      setSubmitted(true);
    } catch (error) {
      console.error("Error calling webhook:", error);
    }
  };

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Paper
          elevation={theme.elevation}
          sx={{
            padding: theme.spacing(4),
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <div>
            <Typography variant="body1" gutterBottom>
              Your ideas help us build the best product possible. They're also a
              great way to share feedback with the team.
            </Typography>
            {submitted ? (
              <Box align="center" sx={{ my: 7 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                  Thank you for your idea!
                </Typography>
                <Typography variant="body1" gutterBottom>
                  This will get reviewed and prioritized by the team.
                </Typography>
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ mt: 2 }}
                onSubmit={handleSubmit}
              >
                <TextField
                  id="task-description"
                  label="Outline your idea..."
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  value={taskDescription}
                  onChange={(e) => setTaskDescription(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Submit
                </Button>
              </Box>
            )}
          </div>
        </Paper>
      </Container>
    </Box>
  );
};

export default SubmitIdea;
