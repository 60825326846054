import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authSlice";
import chatgptReducer from "../reducers/chatgptSlice";
import notificationsReducer from "../reducers/notificationsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    chatgpt: chatgptReducer,
    notifications: notificationsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
