import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Avatar,
  TextField,
  Switch,
  Divider,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useUser } from "@context/UserContext";
import { useAuth } from "@context/AuthContext";
import { useSnackbar } from "@context/SnackbarContext";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SecurityIcon from "@mui/icons-material/Security";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const Settings = () => {
  const theme = useTheme();
  const { user, setUser } = useUser();
  const { addSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [releaseInfo, setReleaseInfo] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    chatNotifications: true,
    taskUpdates: true,
    weeklyDigest: true,
  });
  const [profileData, setProfileData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    jobTitle: user?.jobTitle || "",
    department: user?.department || "",
  });

  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const commitHash = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA;
    if (commitHash) {
      setReleaseInfo(`Release: ${commitHash.slice(0, 7)}`);
    } else {
      setReleaseInfo("Release information not available");
    }
  }, []);

  const handleNotificationChange = (setting) => {
    setNotificationSettings((prev) => ({
      ...prev,
      [setting]: !prev[setting],
    }));
  };

  const handleProfileUpdate = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({
          name: profileData.name,
          job_title: profileData.jobTitle,
          department: profileData.department,
        })
        .eq("email", user.email);

      if (error) throw error;

      setUser((prev) => ({
        ...prev,
        name: profileData.name,
        jobTitle: profileData.jobTitle,
        department: profileData.department,
      }));

      addSnackbar("Profile updated successfully", "success");
      setEditMode(false);
    } catch (error) {
      addSnackbar("Failed to update profile", "error");
    }
  };

  const handleLogout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      });
    } catch (error) {
      console.error("Logout failed:", error);
      navigate("/login");
    }
  };

  return (
    <Box className="fade-in p-4 pt-20 pb-20">
      <Container maxWidth="lg" disableGutters>
        <Grid container spacing={3}>
          {/* Account Management Section (replacing Profile) */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={theme.elevation}
              sx={{
                padding: theme.spacing(4),
                borderRadius: theme.shape.borderRadius,
                height: "100%",
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                Account Management
              </Typography>
              
              <Box display="flex" flexDirection="column" gap={2}>
                <Card
                  sx={{
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="flex-start" gap={2}>
                      <PersonIcon color="primary" />
                      <Box>
                        <Typography variant="h6">Profile Details</Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          View and manage your profile information
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {user?.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {user?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="flex-start" gap={2}>
                      <SecurityIcon color="primary" />
                      <Box>
                        <Typography variant="h6">Security</Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Manage your account security settings
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                        >
                          Manage Security
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>

                <Card
                  sx={{
                    border: `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="flex-start" gap={2}>
                      <LogoutIcon color="error" />
                      <Box>
                        <Typography variant="h6">Sign Out</Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Log out of your account
                        </Typography>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={handleLogout}
                        >
                          Logout
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Paper>
          </Grid>

          {/* Notification Settings */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={theme.elevation}
              sx={{
                padding: theme.spacing(4),
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <Typography variant="h5" component="h2" gutterBottom>
                Notification Settings
              </Typography>
              
              <Grid container spacing={2}>
                {Object.entries(notificationSettings).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <Card
                      sx={{
                        border: `1px solid ${theme.palette.border.main}`,
                        borderRadius: theme.shape.borderRadius,
                      }}
                    >
                      <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box>
                          <Typography variant="h6">
                            {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {getNotificationDescription(key)}
                          </Typography>
                        </Box>
                        <Switch
                          checked={value}
                          onChange={() => handleNotificationChange(key)}
                          color="primary"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ display: "block", textAlign: "center", mt: 4 }}
        >
          {releaseInfo}
        </Typography>
      </Container>
    </Box>
  );
};

const getNotificationDescription = (key) => {
  const descriptions = {
    emailNotifications: "Receive important updates and announcements via email",
    chatNotifications: "Get notified about new chat messages and mentions",
    taskUpdates: "Receive updates about task assignments and changes",
    weeklyDigest: "Get a weekly summary of your activity and team updates",
  };
  return descriptions[key];
};

export default Settings;
