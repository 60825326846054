import React, { createContext, useContext, useState } from "react";

const ChatSettingsContext = createContext();

export const useChatSettingsContext = () => useContext(ChatSettingsContext);

export const ChatSettingsProvider = ({ children }) => {
  const [chatSettings, setChatSettings] = useState({
    title: "Untitled Chat",
    prompt: "You are a friendly, helpful AI assistant.",
    model: "gpt-3.5-turbo",
    temperature: 0.5,
    max_tokens: 100,
  });

  return (
    <ChatSettingsContext.Provider value={{ chatSettings, setChatSettings }}>
      {children}
    </ChatSettingsContext.Provider>
  );
};
