import { useCallback, useState } from "react";
import { useAuth } from "@context/AuthContext";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import api from "@api/axiosConfig";
import { useNavigate } from "react-router-dom";

const MAX_RETRIES = 3;
const TOKEN_REFRESH_COOLDOWN = 60000;

export const useApiWithAuth = () => {
  const { acquireToken } = useAuth();
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();
  const [lastTokenRefresh, setLastTokenRefresh] = useState(0);

  const apiCall = useCallback(
    async (method, endpoint, data = null, config = {}) => {
      const makeApiCall = async (token, retries = 0) => {
        if (token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
        }

        try {
          const response = await api({
            method,
            url: endpoint,
            data,
            ...config,
          });

          return response.data;
        } catch (error) {
          if (
            error.response &&
            error.response.status === 401 &&
            retries < MAX_RETRIES
          ) {
            const currentTime = Date.now();
            if (currentTime - lastTokenRefresh < TOKEN_REFRESH_COOLDOWN) {
              console.warn("Token refresh attempted too soon. Waiting...");
              await new Promise((resolve) =>
                setTimeout(resolve, TOKEN_REFRESH_COOLDOWN),
              );
            }

            try {
              const newToken = await acquireToken(accounts[0], 'id');
              setLastTokenRefresh(Date.now());
              console.log("Successfully refreshed token:", newToken);
              return await makeApiCall(newToken, retries + 1);
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);
              instance.loginRedirect({
                scopes: ["User.ReadBasic.All"],
              });
              throw refreshError;
            }
          } else {
            console.error("API call failed:", error);
            // window.location = "/";
            throw error.response ? error.response.data : error;
          }
        }
      };

      try {
        if (accounts.length > 0) {
          const token = await acquireToken(accounts[0], 'id');
          return await makeApiCall(token);
        } else {
          throw new Error("No accounts found");
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.error("Interaction required, redirecting to login:", error);
          instance.loginRedirect({
            scopes: ["User.ReadBasic.All"],
          });
        } else {
          console.error("API call failed:", error);
          //navigate("/login");
          // navigate("/error", { state: { errorCode: error.message || 500 } });
        }
        throw error;
      }
    },
    [acquireToken, accounts, instance, navigate, lastTokenRefresh],
  );

  return { apiCall };
};
