import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const FindToolSection = ({
  darkMode,
  theme,
  loading,
  taskDescription,
  setTaskDescription,
  handleSubmit,
  response
}) => {
  return (
    <Box className="fade-in">
      <Typography
        variant="h5"
        mt={4}
        component="h1"
        textAlign={"left"}
        gutterBottom
        sx={{ userSelect: "none" }}
      >
        Find an AI Tool
      </Typography>
      <hr
        style={{
          width: "100%",
          border: "none",
          borderBottom: darkMode
            ? "1px dashed rgba(255,255,255,.25)"
            : "1px dashed rgba(0,0,0,.25)",
          margin: "10px 0 0 0",
        }}
      />

      <Box
        className="fade-in"
        sx={{
          marginTop: "20px",
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: "6px",
          padding: 3,
        }}
      >
        {/* Input Form for Task Description */}
        <form onSubmit={handleSubmit}>
          <FormControl
            sx={{ width: "100%", marginBottom: 2 }}
            fullWidth
            disabled={loading}
          >
            <OutlinedInput
              id="outlined-ai-search"
              value={taskDescription}
              autoComplete="off"
              onChange={(e) => setTaskDescription(e.target.value)}
              xs={{ p: 0 }}
              className="fade-in"
              placeholder='Find the best avatar generator.'
              startAdornment={
                <InputAdornment position="start">
                  {loading ? (
                    <CircularProgress size={24} color="white" />
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </form>

        {/* Cards for AI tools */}
        {response && response.length > 0 ? (
          response.map((tool, index) => (
            <Card
              elevation={0}
              key={`ai-cards-${index}`}
              className="fade-in"
              sx={{
                color: theme.palette.text.primary,
                backgroundColor: "transparent",
                boxShadow: "0",
                borderBottom: `0px solid ${theme.palette.border.main}`,
                borderRadius: "6px",
                textDecoration: "none",
                marginBottom: 2,
                transition:
                  "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  boxShadow: 0,
                },
                cursor: "pointer",
              }}
            >
              <CardActionArea
                component="a"
                href={tool.URL}
                target="_blank"
                rel="noopener"
              >
                <CardContent sx={{ color: theme.palette.text.primary }}>
                  <Typography variant="h6" color="textPrimary" mb={1}>
                    {tool.Name}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                    sx={{ display: "block", marginBottom: 1 }}
                  >
                    {tool.Description.replace(tool.Name, "")
                      .trimStart()
                      .charAt(0)
                      .toUpperCase() +
                      tool.Description.replace(tool.Name, "")
                        .trimStart()
                        .slice(1)}
                  </Typography>
                  <ul
                    style={{
                      listStyle: "circle",
                      paddingLeft: "20px",
                      margin: "5px 0",
                    }}
                  >
                    {tool.Features.map((feature, index) => (
                      <li
                        key={`skeleton-${index}`}
                        style={{ marginBottom: "4px" }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {feature}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        ) : (
          <Typography
            className="fade-in"
            variant="body2"
            color="white"
            sx={{ marginTop: 2 }}
          >
            I can help you find an AI tool for your need. Type in{" "}
            <span
              style={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              Email Summary
            </span>
            ,{" "}
            <span
              style={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              Image Generation
            </span>
            ,{" "}
            <span
              style={{
                fontWeight: "bold",
                color: theme.palette.text.primary,
              }}
            >
              Blog Writing
            </span>
            , etc.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FindToolSection;
