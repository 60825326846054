import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useChat } from "ai/react";
import { useAuth } from "@/context/AuthContext";
import { useMsal } from "@azure/msal-react";
import TypeIt from "typeit-react";

const CustomCarouselItem = ({
  theme,
  isSmallScreen,
  handlePromptStarter,
  promptStarter,
}) => {
  const [showTypeIt, setShowTypeIt] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showChips, setShowChips] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleVisible, setTitleVisible] = useState(true);
  const [chipVisible, setChipVisible] = useState(false);
  const { acquireToken } = useAuth();
  const { accounts } = useMsal();
  const [chatId, setChatId] = useState(null);

  const customFetch = useCallback(
    async (url, options) => {
      const token = await acquireToken(accounts[0], 'id');
      const headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };

      return fetch(url, {
        ...options,
        headers,
      });
    },
    [acquireToken, accounts],
  );

  const initialSystemMessage = {
    role: "system",
    content:
      "I want you to provide any response centered around AI, Fusion92, Automation, Marketing. Do not respond to anything offensive or not safe for work. Fusion92 is a marketing transformation firm. Fusion92 is not an agency, replace the words agency with company or firm. Respond with one sentence on this topic: ",
  };

  const { messages, input, handleInputChange, handleSubmit, append } = useChat({
    keepLastMessageOnError: true,
    api: `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/stream`,
    initialMessages: [initialSystemMessage],
    onFinish: async (message) => {
      setLoading(false);
      setTitleVisible(false);
      setChipVisible(false);
      const token = await acquireToken(accounts[0], 'id');

      const messageBody = {
        clientId: "b00b24ba-2933-4d5e-919d-835cc05057a6",
        chatId: chatId || null,
        folderId: null,
        messages,
        userContent: input,
        assistantContent: message.content,
        chatSettings: {
          language: "en",
          temperature: 0.7,
          prompt: initialSystemMessage,
        },
      };

      const res = await fetch(
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/chat/create-message`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messageBody),
        },
      );

      const data = await res.json();
      setChatId(data.newChatId);
    },
    fetch: customFetch,
  });

  useEffect(() => {
    if (messages.length <= 1) {
      setChipVisible(true);
    } else {
      setChipVisible(false);
    }
  }, [messages]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setTitleVisible(false);
    setChipVisible(false);
    setLoading(true);
    handleSubmit(event);
  };

  useEffect(() => {
    if (showSearch) {
      setTimeout(() => setShowChips(true), 500);
    }
  }, [showSearch]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowSearch(true);
  //   }, 1500);
  // }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--ti-cursor-font-size', '1.1em');
    document.documentElement.style.setProperty('--ti-cursor-transform', 'translateY(-.1em)');
    if (isSmallScreen) {
      document.documentElement.style.setProperty('--ti-cursor-transform', 'translateY(.08em)');
      document.documentElement.style.setProperty('--ti-cursor-font-size', '1.3em');
    }
  }, [isSmallScreen]);

  const starters = [ 
    "Show me the latest AI trends",
    "How can I automate my workflow?",
    "Give me some AI-powered insights",
    "How can I automate my tasks?",
    "Show me the latest AI news",
    "Tell me a joke",
  ];

  const handleChipClick = (prompt) => {
    handleInputChange({ target: { value: prompt } });
    append({ role: "user", content: prompt });
    setLoading(true);
    setChipVisible(false);
    setTitleVisible(false);
  };

  const messageBoxStyle = {
    maxWidth: isSmallScreen ? "95%" : "75%",
    transform: titleVisible ? "translateY(0)" : "translateY(-80px)",
    transition: "all 0.3s ease-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <Box
      className="gradientBG fade-in"
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        borderRadius: "3px",
        color: theme.palette.black.main,
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url("https://img.freepik.com/free-vector/yellow-orange-business-abstract-banner-background-with-fluid-gradient-wavy-shapes-vector-design-post_1340-22783.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.25,
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          transform: showSearch ? "translateY(50px)" : "translateY(130px)",
          transition: "all 0.3s ease-out",
        }}
      >
        <Typography 
          variant={isSmallScreen ? "h4" : "h2"}
          sx={{
            opacity: titleVisible ? 1 : 0,
            transition: 'opacity 0.3s ease-out',
          }}
        >
          <TypeIt
            element={"span"}
            options={{
              strings: ["Hi, I'm Nucleus!"],
              lifeLike: true,
              speed: 70,
              startDelay: 1000,
              waitUntilVisible: true,
              afterComplete: function (instance) {
                setTimeout(() => {
                  setShowSearch(true);
                }, 1000);
              },
            }}
          />
        </Typography>

        <Box
          sx={{
            opacity: showSearch ? 1 : 0,
            transform: showSearch ? "scale(1)" : "scale(0.8)",
            transform: titleVisible ? "translateY(0)" : "translateY(-80px)",
            transition: "all 0.3s ease-out",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <FormControl
            sx={{
              width: "100%",
              maxWidth: "600px",
              marginBottom: "20px",
            }}
            variant="outlined"
            component="form"
            color="primary"
            disabled={loading}
            focused={false}
            onSubmit={onSubmitHandler}
          >
            <OutlinedInput
              id="outlined-ai-search"
              autoComplete="off"
              value={input}
              disabled={loading}
              onChange={handleInputChange}
              onFocus={(e) => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSubmitHandler(e);
                }
              }}
              sx={{
                color: "#000000",
                width: "100%",
                backgroundColor: "#FFF",
                borderRadius: "50px",
                marginBottom: "10px",
                height: "64px",
                fontSize: "18px",
                paddingLeft: 1.5,
                backgroundColor: loading ? "#dfdfdf" : "#FFF",
                boxShadow: "0px 0px 50px rgba(255,255,255,.5)",
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ color: theme.palette.primary.main }}
                >
                  <IconButton
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "#121212",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "#ffffff" }} />
                    ) : (
                      <SearchIcon sx={{ fontSize: "28px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Ask me anything..."
            />
          </FormControl>
        </Box>

        {chipVisible && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
              gap: 1,
              maxWidth: isSmallScreen ? "100%" : "75%",
              opacity: showChips ? 1 : 0,
              transform: showChips ? "translateY(0)" : "translateY(-25px)",
              transition: "all 0.3s ease-out",
            }}
          >
            {starters
              .slice(0, isSmallScreen ? 3 : starters.length)
              .map((prompt, index) => (
                <Chip
                  key={`custom-chip-${index}`}
                  label={prompt}
                  clickable
                  variant="outlined"
                  onClick={() => handleChipClick(prompt)}
                  sx={{
                    boxShadow: 0,
                    borderRadius: 3,
                    cursor: "pointer",
                    fontSize: "14px",
                    paddingY: "15px",
                    paddingX: "5px",
                    color: theme.palette.black.main,
                    border: `1px solid ${theme.palette.black.main}`,
                    "&:hover": {
                      color: theme.palette.white.main,
                      backgroundColor: `${theme.palette.black.main} !important`,
                    },
                  }}
                />
              ))}
          </Box>
        )}

        {messages.length > 0 && (
          <Box
            sx={{
              maxWidth: isSmallScreen ? "95%" : "75%",
              transform: titleVisible ? "translateY(0)" : "translateY(-80px)",
              transition: "all 0.3s ease-out",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {messages
              .filter(
                (message) =>
                  message.role !== "system" && message.role !== "user",
              )
              .slice(-1)
              .map((message) => (
                <Typography
                  key={message.id}
                  variant={isSmallScreen ? "body1" : "h5"}
                >
                  {message.content}
                </Typography>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomCarouselItem;
