import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useChat, useAssistant } from "ai/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@context/AuthContext";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useUIContext } from "@context/UIContext";
import sanityClient from "../sanityClient";
import {
  LOADING_MESSAGES,
  CONVERSATION_STAGES,
  CHAT_API_ENDPOINTS,
} from "../utils/chatConstants";
import { fetchClientInfo } from "../utils/chatFunctions";
import { ClientContext } from "@context/ClientContext";
import { setCurrentClientColor } from "@reducers/chatgptSlice";
import { useDispatch } from "react-redux";
import { supabase } from "../supabaseClient";
import { useSnackbar } from "@context/SnackbarContext";
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Note: Only use this if your API key is properly restricted
});

const useChatLogic = (
  clientId,
  chatId,
  taskItem = null,
  accounts,
  {
    selectedFile,
    handleFileChange,
    handleRemoveFile,
    showFilePreview,
    fileInputRef,
    setShowFilePreview,
  },
) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { acquireToken } = useAuth();
  const { apiCall } = useApiWithAuth();
  const [chatName, setChatName] = useState("");
  const [isUsingAssistant, setIsUsingAssistant] = useState(false);
  const [conversationStage, setConversationStage] = useState(
    CONVERSATION_STAGES.INITIAL,
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const lastProcessedMessageRef = useRef(-1);
  const [assistantId, setAssistantId] = useState(null);
  const { uiSettings } = useUIContext();
  const { setIsColorDark } = useUIContext();
  const [assistantLoading, setAssistantLoading] = useState(false);
  const lastMessageRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [webhook, setWebhook] = useState(null);
  const [chatThreadId, setChatThreadId] = useState(null);
  const { clients } = useContext(ClientContext);
  const dispatch = useDispatch();
  const [currentClientObject, setCurrentClientObject] = useState(null);
  const [permissionLevel, setPermissionLevel] = useState(null);
  const [shareLinkDialogOpen, setShareLinkDialogOpen] = useState(false);
  const { addSnackbar } = useSnackbar();
  const [isMessageStreaming, setIsMessageStreaming] = useState(false);
  const [sourceSiteIds, setSourceSiteIds] = useState(null);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [isUsingTaskWithContext, setIsUsingTaskWithContext] = useState(false);
  const [useAzureContext, setUseAzureContext] = useState(false);
  const [fileName, setFileName] = useState(null);

  const uploadFileToOpenAI = async (file) => {
    try {
      const uploadedFile = await openai.files.create({
        file: new File([await file.arrayBuffer()], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        }),
        purpose: "assistants",
      });
      return uploadedFile.id;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const customFetch = useCallback(
    async (url, options) => {
      const token = await acquireToken(accounts[0], "id");
      let body;
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const currentThreadId = chatThreadId || threadId;

      if (options.body) {
        const data = JSON.parse(options.body);
        data.username = accounts[0].username;
        data.useAzureContext = useAzureContext;
        if (sourceSiteIds) {
          data.sourceSiteIds = sourceSiteIds; // not settings
        } else {
          data.sourceSiteIds = [];
        }
        data.username = accounts[0].username; // Always include username

        if (selectedFile) {
          // This was in the very onld chatGPT file.Authorization

          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastUserMessageIndex = updatedMessages.findLastIndex(
              (msg) => msg.role === "user",
            );

            if (lastUserMessageIndex !== -1) {
              updatedMessages[lastUserMessageIndex] = {
                ...updatedMessages[lastUserMessageIndex],
                file: { name: selectedFile.name },
              };
            }

            return updatedMessages;
          });

          setFileName(selectedFile.name);

          body = new FormData();
          const fileType = selectedFile.type.toLowerCase();
          const isSpreadsheet = [
            "csv",
            "xlsx",
            "xls",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "spreadsheet",
          ].some((ext) => fileType.includes(ext));
          let fileId = null;

          if (!isSpreadsheet) {
            fileId = await uploadFileToOpenAI(selectedFile);
            console.warn(
              "Warning: Please consider using a different method for handling different file sizes.",
            );
          } else {
            body.append("file", selectedFile);
          }

          body.append("fileId", fileId);
          body.append("fileName", selectedFile.name);
          body.append("username", accounts[0].username); // Always include username
          body.append("messages", JSON.stringify(data.messages));
          body.append("message", JSON.stringify(data.message));
          if (useAzureContext) {
            body.append("useContext", true);
          }
          if (currentThreadId) {
            body.append("threadId", currentThreadId);
          }
        } else {
          if (currentThreadId) {
            body = JSON.stringify({
              ...data,
              threadId: currentThreadId,
            });
          } else {
            body = JSON.stringify(data);
          }
          headers["Content-Type"] = "application/json";
        }
      }
      setLoading(false);
      return fetch(url, {
        ...options,
        headers,
        body,
      });
    },
    [
      acquireToken,
      accounts,
      selectedFile,
      chatThreadId,
      isUsingAssistant,
      sourceSiteIds,
      useAzureContext,
    ],
  );

  const handleError = (error) => {
    console.error("Chat error:", error);
    let errorMessage;
    try {
      const parsedError = JSON.parse(error.message);
      errorMessage = parsedError.error || "An error occurred";
    } catch (e) {
      errorMessage = error.message || "An error occurred";
    }
    addSnackbar("Chat Error", errorMessage, null, 6000);
  };

  const chatHook = useChat({
    api: CHAT_API_ENDPOINTS.STREAM,
    onFinish: async (message) => {
      const token = await acquireToken(accounts[0], "id");
      const dalleResult = message?.toolInvocations
        ? message?.toolInvocations[0]?.result
        : null;
      const messageBody = {
        clientId,
        chatId: chatId || currentChatId || null,
        messages: chatHook.messages,
        userContent: chatHook.input,
        assistantContent: message.content || dalleResult,
        file: chatHook.selectedFile
          ? {
              name: chatHook.selectedFile.name,
              type: chatHook.selectedFile.type,
              size: chatHook.selectedFile.size,
              content: await readFileAsBase64(chatHook.selectedFile),
            }
          : null,
      };

      const res = await fetch(CHAT_API_ENDPOINTS.CREATE_MESSAGE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageBody),
      });

      const data = await res.json();
      if (data.newChatId && !chatId) {
        setCurrentChatId(data.newChatId);
        setChatName(data.title);
        // navigate(`/chatgpt/${clientId}/chat/${data.newChatId}`);
      }
    },
    fetch: customFetch,
    onError: handleError, // Pass the handleError function here
  });

  const chatTasksHook = useChat({
    api: `${CHAT_API_ENDPOINTS.STREAM}/tasks`,
    // streamProtocol: "data",
    onResponse: () => {
      // This function will be called when the response is received
      setAssistantLoading(false);
    },
    onFinish: async (message) => {
      const token = await acquireToken(accounts[0], "id");

      const messageBody = {
        clientId,
        chatId: chatId || currentChatId || null,
        messages: chatTasksHook.messages,
        userContent: chatTasksHook.input,
        assistantContent: message.content,
        // file: chatHook.selectedFile
        //   ? {
        //       name: chatHook.selectedFile.name,
        //       type: chatHook.selectedFile.type,
        //       size: chatHook.selectedFile.size,
        //       content: await readFileAsBase64(chatHook.selectedFile),
        //     }
        //   : null,
      };

      const res = await fetch(CHAT_API_ENDPOINTS.CREATE_MESSAGE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageBody),
      });

      const data = await res.json();
      if (data.newChatId && !chatId) {
        setCurrentChatId(data.newChatId);
        setChatName(data.title);
        // navigate(`/chatgpt/${clientId}/chat/${data.newChatId}`);
      }
    },
    fetch: customFetch,
    onError: handleError, // Pass the handleError function here
  });

  const chatAzureHook = useChat({
    api: `${CHAT_API_ENDPOINTS.STREAM}/azure`,
    // streamProtocol: "data",
    onResponse: () => {
      // This function will be called when the response is received
      setAssistantLoading(false);
    },
    onFinish: async (message) => {
      const token = await acquireToken(accounts[0], "id");

      const messageBody = {
        clientId,
        chatId: chatId || currentChatId || null,
        messages: chatAzureHook.messages,
        userContent: chatAzureHook.input,
        assistantContent: message.content,
        // file: chatHook.selectedFile
        //   ? {
        //       name: chatHook.selectedFile.name,
        //       type: chatHook.selectedFile.type,
        //       size: chatHook.selectedFile.size,
        //       content: await readFileAsBase64(chatHook.selectedFile),
        //     }
        //   : null,
      };

      const res = await fetch(CHAT_API_ENDPOINTS.CREATE_MESSAGE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageBody),
      });

      const data = await res.json();
      if (data.newChatId && !chatId) {
        setCurrentChatId(data.newChatId);
        setChatName(data.title);
        // navigate(`/chatgpt/${clientId}/chat/${data.newChatId}`);
      }
    },
    fetch: customFetch,
    onError: handleError, // Pass the handleError function here
  });

  const assistantHook = useAssistant({
    api: `${CHAT_API_ENDPOINTS.STREAM}/assistant?assistantId=${taskItem?.assistantId || assistantId}&webhook=${encodeURIComponent(taskItem?.webhook || webhook)}`,
    fetch: customFetch,
  });

  const getChatHook = useCallback(() => {
    if (isUsingAssistant) {
      return assistantHook;
    } else if (isUsingTaskWithContext) {
      return chatTasksHook;
    } else if (currentClientObject?.name.toLowerCase() === "chatgpt") {
      return chatHook;
    } else {
      return chatAzureHook;
    }
  }, [
    isUsingAssistant,
    assistantHook,
    chatHook,
    chatAzureHook,
    currentClientObject?.name,
    clientId,
  ]);

  const {
    messages,
    setMessages,
    input,
    handleInputChange,
    handleSubmit,
    status,
    submitMessage,
    threadId,
    error,
    isLoading,
  } = getChatHook();

  useEffect(() => {
    if (isLoading) {
      setAssistantLoading(true);
    } else {
      setAssistantLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isUsingAssistant && messages.length > 0) {
      setLoading(false);
      const lastMessage = messages[messages.length - 1];
      if (
        lastMessage.role === "assistant" &&
        lastMessage !== lastMessageRef.current
      ) {
        setAssistantLoading(false);
        lastMessageRef.current = lastMessage;
      }
    }
  }, [messages, isUsingAssistant]);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        setAssistantId(null);
        const endpoint = `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/sources?clientId=${clientId}`;
        const { sources } = await apiCall("get", endpoint);
        setSourceSiteIds(sources);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSources();
  }, [clientId, apiCall, chatId, navigate]);

  const handleMessageComplete = useCallback(
    async (message) => {
      if (isProcessing || conversationStage === CONVERSATION_STAGES.INITIAL) {
        return;
      }
      setIsProcessing(true);

      try {
        const token = await acquireToken(accounts[0], "id");
        const lastUserMessage = messages
          .slice()
          .reverse()
          .find((msg) => msg.role === "user");
        const taskItemTitle = taskItem?.title ?? false;

        const messageBody = {
          clientId,
          chatId: chatId || currentChatId || null,
          messages,
          userContent: lastUserMessage.content,
          assistantContent: message.content,
          fileName,
          assistantId: taskItem?.assistantId || assistantId,
          webhook: taskItem?.webhook || webhook,
          taskItemTitle,
          folderId: taskItem?.assistantId
            ? uiSettings.folders.find((folder) => folder.type === "tasks").id
            : null,
          threadId,
        };

        const res = await fetch(CHAT_API_ENDPOINTS.CREATE_MESSAGE, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(messageBody),
        });
        const data = await res.json();
        if (data.newChatId && !chatId) {
          // navigate(`/chatgpt/${clientId}/chat/${data.newChatId}`);
          setCurrentChatId(data.newChatId);
          setChatName(data.title);
        }

        lastProcessedMessageRef.current = messages.length - 1;
        setFileName(null);
      } catch (error) {
        console.error("Error creating message:", error);
      } finally {
        setIsProcessing(false);
        setConversationStage(CONVERSATION_STAGES.INITIAL);
      }
    },
    [
      acquireToken,
      clientId,
      chatId,
      messages,
      navigate,
      assistantId,
      webhook,
      uiSettings,
      threadId,
      taskItem,
    ],
  );

  useEffect(() => {
    if (status === "in_progress") {
      setIsMessageStreaming(true);
      setConversationStage(CONVERSATION_STAGES.IN_PROGRESS);
    } else if (status === "awaiting_message") {
      setIsMessageStreaming(false);
      if (
        messages.length > 0 &&
        lastProcessedMessageRef.current <= messages.length - 1 &&
        conversationStage !== CONVERSATION_STAGES.INITIAL
      ) {
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.role === "assistant") {
          handleMessageComplete(lastMessage);
        }
        setConversationStage(CONVERSATION_STAGES.INITIAL);
      }
    }
  }, [status, messages, handleMessageComplete, conversationStage]);

  const fetchConversationHistory = useCallback(async () => {
    setLoading(true);
    if (chatId) {
      try {
        const response = await apiCall("get", `/chats/${chatId}/messages`);
        if (response.messages) {
          const formattedMessages = await Promise.all(
            response.messages.map(async (msg) => {
              let fileInfo = null;
              if (msg.file_id && msg.role === "user") {
                const fileResponse = await apiCall(
                  "get",
                  `/files/${msg.file_id}`,
                );
                fileInfo = fileResponse;
              }
              if (msg.assistant_id) {
                setAssistantId(msg.assistant_id);
                setIsUsingAssistant(true);
              }
              return {
                id: msg.id,
                role: msg.role,
                content: msg.content,
                file: fileInfo,
                sender_email: msg.sender_email,
                file_name: msg.file_name,
              };
            }),
          );
          setMessages(formattedMessages);
          if (response.chatName) {
            setChatName(response.chatName);
          }
        }
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      } finally {
        setLoading(false);
      }
    } else if (taskItem) {
      setIsUsingAssistant(true);
      setChatName(taskItem.title);
      setAssistantId(taskItem?.assistantId);
      // if (taskItem?.needsContext) {
      //   // subject to change
      //   setIsUsingTaskWithContext(true);
      // }
      setLoading(false);
    } else if (clientId === "b00b24ba-2933-4d5e-919d-835cc05057a6") {
      setIsUsingAssistant(true);
      setAssistantId("asst_OhZ2BvXNF7f2A1vP1j5Mi5Rt");
    } else {
      setMessages([]);
      setIsUsingAssistant(false);
      setAssistantId(null);
    }
    setLoading(false);
  }, [chatId, setMessages, apiCall, taskItem, clientId]);

  useEffect(() => {
    fetchConversationHistory();
  }, [fetchConversationHistory]);

  useEffect(() => {
    // Set up real-time listener
    const subscription = supabase
      .channel("messages")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "messages",
          filter: `chat_id=eq.${chatId}`,
        },
        (payload) => {
          const recentMessages = messages.slice(-2);
          const existsInRecentMessages = recentMessages.some(
            (msg) => msg.content === payload.new.content,
          );

          if (!existsInRecentMessages) {
            setMessages((prevMessages) => [...prevMessages, payload.new]);
          }
        },
      )
      .subscribe();

    // Cleanup subscription
    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  useEffect(() => {
    if (chatId) {
      clearMessages();
      const fetchChatInfo = async () => {
        const response = await apiCall("get", `/chats/${chatId}`);
        const token = await acquireToken(accounts[0], "id");
        const res = await fetch(`${CHAT_API_ENDPOINTS.PERMISSION}/${chatId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        const permission = data?.permission_level || "engage";
        setPermissionLevel(permission);
        setChatThreadId(response.thread_id);
        setWebhook(response.webhook);
      };
      fetchChatInfo();
    } else {
      setCurrentChatId(null);
    }
  }, [chatId, apiCall]);

  useEffect(() => {
    let intervalId;
    if (assistantLoading) {
      let index = 0;
      setLoadingMessage(LOADING_MESSAGES[0]);
      intervalId = setInterval(() => {
        index = (index + 1) % LOADING_MESSAGES.length;
        setLoadingMessage(LOADING_MESSAGES[index]);
      }, 2500);
    }
    return () => clearInterval(intervalId);
  }, [assistantLoading]);

  useEffect(() => {
    const fetchClientData = async () => {
      const clientInfo = await fetchClientInfo(clientId, clients, sanityClient);
      setCurrentClientObject(clientInfo);
      dispatch(setCurrentClientColor(clientInfo?.color));
      setIsColorDark(clientInfo?.isColorDark, clientInfo);
      // if (clientInfo?.assistantId) {
      //   setAssistantId(clientInfo?.assistantId);
      //   setIsUsingAssistant(true);
      // } we dont need to set assistant id anymore for clients, but keeping for now just in case

      if (clientInfo && !chatId && !currentChatId) {
        setChatName(
          `${clientInfo.name}${clientInfo.name !== "ChatGPT" ? " ChatGPT" : ""}`,
        );
        setLoading(false);
      }
    };
    fetchClientData();
  }, [clientId, clients, chatId, currentChatId]);

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (status === "in_progress") return;

    let messageToSend = input.trim();

    try {
      if (selectedFile) {
        messageToSend = `[File Attached: ${selectedFile.name}] ${messageToSend}`;
      }

      setConversationStage(CONVERSATION_STAGES.IN_PROGRESS);
      if (isUsingAssistant) {
        setAssistantLoading(true);
        lastMessageRef.current = null;
        submitMessage(messageToSend);
      } else {
        setAssistantLoading(true);
        handleSubmit(event);
      }
      handleRemoveFile();
      handleInputChange({ target: { value: "" } });
    } catch (error) {
      addSnackbar("Error", "Failed to upload file", null, 6000);
      console.error("Error handling message:", error);
    }
  };

  const handleNewChat = () => {
    navigate(`/chatgpt/${clientId}/chat`);
  };

  const handleOpenShareDialog = () => {
    setShareLinkDialogOpen(true);
  };

  const handleCloseShareLinkDialog = () => {
    setShareLinkDialogOpen(false);
  };

  const clearMessages = () => {
    setMessages([]);
  };

  const handleChatSelect = async (newChatId) => {
    clearMessages();
    await fetchConversationHistory(newChatId);
  };

  return {
    messages,
    setMessages,
    clearMessages,
    handleChatSelect,
    input,
    handleInputChange,
    handleSendMessage,
    handleNewChat,
    status,
    chatName,
    currentClientObject,
    assistantLoading,
    loadingMessage,
    isUsingAssistant,
    error,
    chatThreadId,
    permissionLevel,
    loading,
    handleOpenShareDialog,
    shareLinkDialogOpen,
    handleCloseShareLinkDialog,
    isUsingAssistant,
    isMessageStreaming,
    useAzureContext,
    setUseAzureContext,
  };
};

export default useChatLogic;
