import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { Check, ContentCopy, Download } from "@mui/icons-material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import {
  programmingLanguages,
  fileExtensions,
  generateRandomString,
} from "./utils";
import useCopyToClipboard from "./useCopyToClipboard";

const MessageCodeBlock = React.memo(({ language, value }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 });

  const downloadAsFile = () => {
    if (typeof window === "undefined") {
      return;
    }
    const fileExtension =
      programmingLanguages[language] || fileExtensions[language] || ".file";
    const suggestedFileName = `file-${generateRandomString(
      3,
      true,
    )}${fileExtension}`;
    const fileName = window.prompt("Enter file name", suggestedFileName);

    if (!fileName) {
      return;
    }

    const blob = new Blob([value], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onCopy = () => {
    if (isCopied) return;
    copyToClipboard(value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        fontFamily: "sans-serif",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.700",
          px: 2,
          color: "white",
        }}
      >
        <Typography variant="caption" sx={{ textTransform: "lowercase" }}>
          {language}
        </Typography>
        <Box>
          <Button
            variant="text"
            size="small"
            onClick={downloadAsFile}
            sx={{ minWidth: 0, p: 1, color: "white" }}
          >
            <Download fontSize="small" />
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={onCopy}
            sx={{ minWidth: 0, p: 1, color: "white" }}
          >
            {isCopied ? (
              <Check fontSize="small" />
            ) : (
              <ContentCopy fontSize="small" />
            )}
          </Button>
        </Box>
      </Box>
      <SyntaxHighlighter
        language={language}
        style={oneDark}
        customStyle={{
          margin: 0,
          width: "100%",
          background: "transparent",
        }}
        codeTagProps={{
          style: {
            fontSize: "14px",
            fontFamily: "monospace",
          },
        }}
      >
        {value}
      </SyntaxHighlighter>
    </Box>
  );
});

MessageCodeBlock.displayName = "MessageCodeBlock";

export default MessageCodeBlock;
