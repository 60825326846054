import React, { useState } from 'react';
import { useTheme, Typography, Paper, Box, Skeleton, useMediaQuery, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const NewsCarousel = ({ items }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ 
      borderRadius: 20, 
      position: 'relative', 
      width: '100%', 
      height: '400px',
      borderRadius: '30px',
      overflow: 'hidden',
    }}>
      <Carousel
        autoPlay={true}
        swipe={true}
        cycleNavigation={true}
        animation="slide"
        navButtonsAlwaysInvisible
        interval={10000}
        duration={500}
        indicators={false}
        height="400px"
      >
        
        {items.map((item, index) => (
          <CarouselItem
            key={index}
            item={item}
            theme={theme}
          />
        ))}
      </Carousel>
    </Box>
  );
};

const CarouselItem = ({ item, theme}) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
    <div style={{ position: 'relative', opacity: 0 }} className={'task-item-wrapper fade-in'} >
      <img
        className='fade-in'
        src={item.image}
        alt={item.title}
        style={{
          width: '100%',
          height:'400px',
          objectFit: 'cover',
          borderRadius: 3,
          pointerEvents: 'none',
        }}
      />
      <Paper
        sx={{
          boxShadow: 'none',
          position: 'absolute',
          bottom: '30px',
          right: '30px',
          padding: '30px',
          borderRadius: 5,
          maxWidth: '500px',
          transition: `all ${theme.custom.animation.fast} ease-in-out`,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.93)' : 'rgba(255, 255, 255, 0.93)',
          boxShadow: theme.palette.mode === 'dark' ? '5px 5px 60px rgba(0,0,0, 1)' : '0px 0px 40px rgba(0, 0, 0, .5)',
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          '@media (max-width: 600px)': {
            bottom: '15px',
            left: '15px',
            right: '15px',
            padding: '20px',
          },
        }}
      >
        <Box>
          <Typography 
            variant={ isSmallScreen ? "h5" : "h5"} 
            gutterBottom 
            sx={{
              userSelect: "none", 
              cursor:'default',
            }}
          >
              {item.title}
            </Typography>
            {!isSmallScreen && (
              <Typography 
                variant="body1" 
                sx={{
                  userSelect: "none", 
                  cursor:'default',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 4,
                  marginBottom: 1,
              }}>
                {item.intro}
              </Typography>
            )}
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 1 }}
            onClick={() => window.open(item.link, '_blank', 'noopener,noreferrer')}
          >
            Read More
          </Button>
        </Box>
      </Paper>
    </div>
    
  );
};

export default NewsCarousel;
