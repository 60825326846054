import React from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useThemeContext } from '../../context/ThemeContext';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const Search = styled('div')(({ theme, darkMode }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: darkMode ? alpha(theme.palette.common.white, 0.15) : alpha(theme.palette.common.white, 0.5),
  '&:hover': {
    color: alpha(theme.palette.common.black, 0.5),
    backgroundColor: darkMode ? alpha(theme.palette.common.white, 0.25) : alpha(theme.palette.common.white, 1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
    marginRight: theme.spacing(1),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: `all ${theme.custom.animation.fast} ease-in-out`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Header = ({ title }) => {
  const { darkMode, theme, handleThemeChange } = useThemeContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <AppBar
        position="absolute"
        style={{ paddingLeft: !isSmallScreen ? '220px' : '60px' }}
        className='fade-in'
        elevation={0}
        sx={{
          transition: `all ${theme.custom.animation.fast} ease-in-out`,
          backgroundColor: darkMode ? '#1d1d1d' : theme.palette.white.main,
          boxShadow: 'none',
        }}
      >
        <Toolbar
          className="flex justify-between"
          variant="dense"
          sx={{ paddingTop: '5px', paddingBottom: '5px' }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ 
              fontSize: 16, 
              lineHeight:'2.75rem', 
              color: theme.palette.mode === 'dark' ? theme.palette.white.main : theme.palette.black.main, 
              fontWeight: 'normal', 
              userSelect: 'none', 
              cursor:'default' }}
          >
            {title}
          </Typography>
          <div className="flex items-center">
            <IconButton className="dark-mode-toggle" onClick={handleThemeChange} sx={{ color: theme.palette.primary.contrastText }}>
              {darkMode ? <LightModeIcon /> : <DarkModeIcon sx={{ color: theme.palette.mode === 'dark' ? theme.palette.white.main : theme.palette.black.main }} />
            }
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
