import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

const Logout = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await instance.logoutRedirect({
          postLogoutRedirectUri: window.location.origin,
        });
      } catch (error) {
        console.error("Logout failed:", error);
        // Fallback: redirect to login page if logout fails
        navigate("/login");
      }
    };

    performLogout();
  }, [instance, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "rgb(30, 30, 30)",
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Logout; 