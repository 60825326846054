import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import PostActions from "./PostActions";
import CustomAvatar from "../common/CustomAvatar";
import { useThemeContext } from "../../context/ThemeContext";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const Post = ({
  description,
  media_url,
  profileImage,
  name,
  timestamp,
  mediaType,
  initialLikes,
  postId,
  email,
  postOwnerEmail,
  videoRef: propVideoRef,
  title,
  liked,
  isArchived,
  refreshPosts,
  handleClickOpen,
  user,
  onDelete,
  isAdmin,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { darkMode } = useThemeContext();
  const [muted, setMuted] = useState(true);
  const [postView, setPostView] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("post")) {
      setPostView(true);
      console.log("The pathname includes 'post'");
    }
  }, []);

  const localVideoRef = useRef(null);
  const videoRef = propVideoRef || localVideoRef;

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/social/post/${postId}`;

    try {
      await navigator.share({
        title: title || "Check out this post",
        text: description || "Check out this interesting post",
        url: shareUrl,
      });
    } catch (err) {
      console.error("Error sharing:", err);
    }
  };

  const handleMediaLoad = () => {
    setLoaded(true);
  };

  const handlePlayPause = () => {
    if (videoRef && videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
        if (handleVideoPause) {
          handleVideoPause();
        }
      } else {
        videoRef.current.muted = false;
        videoRef.current.play();
        setIsPlaying(true);
        setMuted(false);
        if (handleVideoPlay) {
          handleVideoPlay();
        }
      }
    }
  };

  const handleMuteToggle = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setMuted(videoRef.current.muted);
    }
  };

  const handleVideoPause = () => {
    setIsPlaying(false);
  };

  const handleVideoPlay = () => {
    setIsPlaying(true);
  };
  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const intervals = {
      year: 31536000, // 365 * 24 * 60 * 60
      month: 2592000, // 30 * 24 * 60 * 60
      week: 604800, // 7 * 24 * 60 * 60
      day: 86400, // 24 * 60 * 60
      hour: 3600, // 60 * 60
      minute: 60,
      second: 1,
    };

    for (const interval in intervals) {
      const timeInterval = Math.floor(diffInSeconds / intervals[interval]);
      if (timeInterval >= 1) {
        return `${timeInterval} ${interval}${timeInterval > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };

  return (
    <Box
      sx={{
        position: "relative",
        scrollSnapAlign: "center",
        overflow: "hidden",
        //opacity: loaded ? 1 : 0,
        transition: "opacity .3s ease-in-out",
        borderRadius: isDesktop ? 20 : 0,
        overflow: "hidden",
      }}
    >
      {mediaType === "video" ? (
        <Box
          sx={{ position: "relative" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <video
            ref={videoRef}
            muted={muted}
            loop
            playsInline={true}
            webkit-playsinline="true"
            src={media_url}
            preload="false"
            onClick={() => {
              if (!postView) {
                window.location.href = `${window.location.origin}/social/post/${postId}`;
              }
            }}
            onLoadedData={handleMediaLoad}
            style={{
              objectFit: "cover",
              width: "100%",
              height: isDesktop ? "100%" : "100dvh",
              minHeight: isDesktop ? "711px" : "100dvh",
              //opacity: loaded ? 1 : 0,
              transition: "opacity .3s ease-in-out",
              cursor: "pointer",
              //height: '100dvh',
              //height: '100vh',
            }}
          />

          <IconButton
            onClick={handlePlayPause}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              //width: '100vw',
              //height: '100vh',
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              backgroundColor: darkMode
                ? "rgba(0, 0, 0, 0.5)"
                : "rgba(255, 255, 255, 0.5)",
              color: "white",
              transition: "opacity 0.2s ease",
              opacity: isPlaying ? (isDesktop ? (isHovered ? 1 : 0) : 0) : 1, // Modified this line
              "&:hover": {
                backgroundColor: darkMode
                  ? "rgba(0, 0, 0, 0.7)"
                  : "rgba(255, 255, 255, 0.7)",
              },
              fontSize: "3rem",
              zIndex: 2,
            }}
          >
            {isPlaying ? (
              <PauseIcon sx={{ fontSize: "3rem" }} />
            ) : (
              <PlayArrowIcon sx={{ fontSize: "3rem" }} />
            )}
          </IconButton>
          {/* Volume Controls */}
          <IconButton
            onClick={handleMuteToggle}
            sx={{
              position: "absolute",
              bottom: "20px",
              right: "20px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              zIndex: 2,
            }}
          >
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </Box>
      ) : (
        <img
          src={media_url}
          alt="post-media"
          onLoad={handleMediaLoad}
          onClick={() => {
            if (!postView) {
              window.location.href = `${window.location.origin}/social/post/${postId}`;
            }
          }}
          loading="lazy"
          style={{
            objectFit: "cover",
            minHeight: "711px",
            height: isDesktop ? "711px" : "100dvh",
            opacity: loaded ? 1 : 0,
            transition: "opacity .3s ease-in-out",
            cursor: "pointer",
          }}
        />
      )}

      {/* Post information and actions */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "40%",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, .75), rgba(0, 0, 0, 0))",
          pointerEvents: "none",
          //opacity: loaded ? 1 : 0,
          transition: "opacity .3s ease-in-out",
        }}
      />
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          bottom: 25,
          left: 25,
          right: 25,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "flex-start",
          color: "#fff",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <CustomAvatar
            name={name}
            width="50px"
            height="50px"
            src={profileImage}
            style={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              marginLeft: 2,
              display: "flex", // Enable Flexbox
              flexDirection: "column", // Stack items vertically
              justifyContent: "center", // Center vertically
              alignItems: "left", // Center horizontally if needed
              height: "100%", // Ensure the box takes full height of its container
            }}
          >
            <Typography
              sx={{
                userSelect: "none",
                cursor: "default",
                lineHeight: 1.25,
                paddingTop: 0,
                marginTop: 0,
              }}
              variant="h6"
            >
              {name}
            </Typography>
            <Typography
              sx={{
                userSelect: "none",
                cursor: "default",
                lineHeight: 1.25,
              }}
              variant="caption"
            >
              {timestamp ? getRelativeTime(timestamp) : "Date not available"}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ userSelect: "none", cursor: "default", lineHeight: 1.5 }}
            variant="body1"
          >
            {title}
          </Typography>
          <Typography
            sx={{ userSelect: "none", cursor: "default" }}
            variant="body2"
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          position: "absolute",
          top: 15,
          right: 15,
          backgroundColor: darkMode
            ? "rgba(0, 0, 0, 0.5)"
            : "rgba(255, 255, 255, 0.5)",
          borderRadius: "60px",
          padding: "15px 10px 15px 10px",
        }}
      >
        <PostActions
          postId={postId}
          email={email}
          postOwnerEmail={postOwnerEmail}
          initialLikes={initialLikes}
          liked={liked}
          isArchived={isArchived}
          refreshPosts={refreshPosts}
          handleClickOpen={handleClickOpen}
          dark={darkMode}
          onDelete={onDelete}
          postView={postView}
          isAdmin={isAdmin}
        />
      </Box>
    </Box>
  );
};

export default Post;
