import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Make sure to get location from useLocation
  const { errorCode } = location.state || { errorCode: "Unknown" };

  const handleGoBack = () => {
    navigate("/"); // Redirect to home page or any other appropriate pag
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Something went wrong
      </Typography>
      <Typography variant="body1" gutterBottom>
        We encountered an error. Please try again later.
      </Typography>
      <Typography variant="body1" gutterBottom>
        {errorCode}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={{
          marginTop: "20px",
        }}
      >
        Go Back to Home
      </Button>
    </Box>
  );
};

export default ErrorPage;
