import React, { useEffect } from "react";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import { useNavigate, useLocation } from "react-router-dom";

const ChatGPTRedirect = () => {
  const { apiCall } = useApiWithAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const clientName = location.search.split("=")[1];

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await apiCall("get", "/clients");
        const firstClient = response[0]?.id;
        if (clientName) {
          const decodedClientName = decodeURIComponent(clientName);
          const client = response.find(
            (client) => client.name === decodedClientName,
          );
          if (client) {
            navigate(`/chatgpt/${client.id}/chat`);
            return;
          }
        } else {
          if (firstClient) {
            if (location.state && location.state.taskItem) {
              navigate(`/chatgpt/${firstClient}/chat`, {
                replace: true,
                state: { taskItem: location.state.taskItem },
              });
              return;
            } else {
              navigate(`/chatgpt/${firstClient}/chat`, { replace: true });
            }
          }
        }
      } catch (error) {
        console.error("Failed to fetch clients:", error);
      }
    };
    fetchClients();
  }, [apiCall, navigate, location]);

  return null;
};

export default ChatGPTRedirect;
