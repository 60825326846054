import React from 'react';
import { Card, CardContent, Box, CardMedia, Typography, CardActionArea } from '@mui/material';
import { useThemeContext } from '../../context/ThemeContext';

const NewsCard = ({ title, image, link, intro, source, date, isMobile }) => {
    const { darkMode, theme } = useThemeContext();

    const parseDate = (dateString) => {
      // Create a new Date object from the date string
      const date = new Date(dateString);
    
      // Format the date to show the day, date, and month
      const formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'short', // "Thu"
        day: '2-digit',   // "15"
        month: 'short'    // "Aug"
      });
    
      return formattedDate; // "Thu, 15 Aug"
    };

    return (
        <Card 
            elevation={0} 
            sx={{ 
                border: `1px solid ${theme.palette.border.main}`,
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                borderRadius: theme.shape.borderRadius,
                width: '100%',
                boxShadow: 0,
              transition: `all .2s ease-in-out`,
              ...(isMobile
                ? {}
                : {
                    "&:hover": {
                      transform: "scale(1.03)",
                      boxShadow: 10,
                      zIndex: 1,
                      borderRadius: 10,
                      transition: `all .2s ease-in-out`,
                    },
                  }),
                  }}
              >
            <CardActionArea 
                component="a" 
                href={link} 
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%' 
                }}
            >
                <CardMedia
                    sx={{ height: 200, width: '100%' }}
                    alt={title}
                    image={image}
                    title={title}
                />
                <CardContent 
                    sx={{ 
                        flexGrow: 1, 
                        display: 'flex', 
                        flexDirection: 'column',
                        justifyContent: 'start', 
                        padding: 3,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography gutterBottom variant="overline">
                        {source}
                      </Typography>
                      <Typography gutterBottom variant="overline">
                        {parseDate(date)}
                      </Typography>
                    </Box>
                    <hr
                      style={{
                        width: "100%",
                        border: "none",
                        borderBottom: darkMode
                          ? "1px dashed rgba(255,255,255,.25)"
                          : "1px dashed rgba(0,0,0,.25)",
                        margin: "0px 0",
                      }}
                    />
                    <Typography gutterBottom variant="h5"
                      style={{
                        marginTop: 10,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 3,
                      }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 3,
                        }}
                    >
                        {intro}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default NewsCard;
