import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainLayout from "./components/layouts/MainLayout";
import ScrollToTop from "./components/common/ScrollToTop";
import { UserProvider } from "./context/UserContext";
import AppRoutes from "./components/AppRoutes";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { ThemeProvider } from "./context/ThemeContext";
import { AuthProvider } from "@context/AuthContext";
import { ChatSettingsProvider } from "@context/ChatSettingsContext";
import { UIContextProvider } from "@context/UIContext";
import { SnackbarProvider } from "@context/SnackbarContext";
import { Analytics } from "@vercel/analytics/react";
import * as serviceWorker from "./serviceWorkerRegistration";
import { ClientProvider } from "@context/ClientContext";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./msalConfig";

const App = () => {
  const { instance, accounts } = useMsal(); 
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.handleRedirectPromise();
        setIsMsalInitialized(true);
      } catch (error) {
        console.error('Error handling MSAL redirect promise:', error);
        setIsMsalInitialized(true); // Allow app to load even if there was an error
      }
    };
  
    initializeMsal();
  }, [instance]);

  useEffect(() => {
    if (isMsalInitialized && accounts.length === 0) {
      const handleInitialLogin = async () => {
        try {
          await instance.handleRedirectPromise();
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            await instance.loginRedirect(loginRequest);
          }
        } catch (error) {
          console.error('Login Redirect Error:', error);
        }
      };
      
      handleInitialLogin();
    }
  }, [isMsalInitialized, accounts, instance]);

  // useEffect(() => {
  //   if (isMsalInitialized) {
  //     serviceWorker.register({
  //       onUpdate: (registration) => {
  //         if (registration && registration.waiting) {
  //           // Trigger custom notification logic
  //           registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //           alert('New version available! The page will reload to apply updates.');
  //           window.location.reload();
  //         }
  //       },
  //       onSuccess: (registration) => {
  //         console.log('Service worker registered successfully.');
  //       },
  //     });
  //   }
  // }, [isMsalInitialized]);

  return (
    <ThemeProvider>
      <AuthProvider>
        <UserProvider accounts={accounts}>
          <ErrorBoundary>
            <Router>
              <ChatSettingsProvider>
                <UIContextProvider>
                  <ClientProvider>
                    <ScrollToTop />
                    <SnackbarProvider>
                      <MainLayout>
                        <AppRoutes />
                        <Analytics />
                        <SpeedInsights />
                      </MainLayout>
                    </SnackbarProvider>
                  </ClientProvider>
                </UIContextProvider>
              </ChatSettingsProvider>
            </Router>
          </ErrorBoundary>
        </UserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;