// WelcomeModal.js
import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, Fade, useMediaQuery } from '@mui/material';
import { useThemeContext } from "../../context/ThemeContext";
import { useTheme } from "@mui/material/styles";
import TypeIt from "typeit-react";

const WelcomeModal = ({ open, onStart, onClose, firstName }) => {
    const { darkMode } = useThemeContext();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showButtons, setShowButtons] = useState(false);

    const welcomeMessage = firstName 
        ? `Welcome to <b>Nucleus, ${firstName}!</b>` 
        : "Welcome to <b>Nucleus!</b>";

    return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? "90%" : "50%",
            height: isSmallScreen ? "90%" : "50%",
            color: "#fff",
            bgcolor: theme.palette.primary.main,
            boxShadow: 24,
            border: '0px solid',
            borderRadius: theme.shape.borderRadius,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            outline: 'none',
            outlineColor: 'transparent',
          }}
        >
          <Typography variant="h4" sx={{ fontWeight:"light", lineHeight:1.1, textAlign: "left", mt: 2 }}>
            <TypeIt          
              element={"span"}
              options={{
                strings: [welcomeMessage, "<br>I'm your dedicated resource for all things AI and automation.", "<br>Do you want to get a quick tour?"],
                lifeLike:true,
                speed:10,
                startDelay:900,
                waitUntilVisible:true,
                afterComplete: function (instance) {
                  setShowButtons(true);
                  console.log("afterComplete");
                }
              }}
            />
          </Typography>

          { showButtons && (
            <Box className="fade-in" sx={{ display: 'flex', alignSelf:'center', justifyContent: 'flex-end', gap: 2 }}>
              <Button variant="text" color="white" onClick={onClose}>
                No Thanks
              </Button>
              <Button variant="contained" size='large' color="white" sx={{color:"black"}} onClick={onStart}>
                Let's do it!
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default WelcomeModal;
