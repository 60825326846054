import React from "react";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import ReactMarkdown from "react-markdown";
import { Typography, Box } from "@mui/material";
import MessageCodeBlock from "./MessageCodeBlock";
import CitationDropdown from "./CitationsDropdown";

const MessageMarkdownMemoized = React.memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
);

const MessageMarkdown = ({ content, toolInvocations }) => {
  const [mainContent, citationsData] = React.useMemo(() => {
    const citationsStart = content?.indexOf("\nCITATIONS_START");
    if (citationsStart === -1 || !content?.includes("CITATIONS_END")) {
      return [content, null];
    }

    const mainPart = content?.slice(0, citationsStart);
    const citationsPart = content?.slice(citationsStart + 1);

    try {
      const citationsMatch = citationsPart.match(
        /CITATIONS_START(.*)CITATIONS_END/,
      );
      if (citationsMatch) {
        const citationsJson = JSON.parse(citationsMatch[1]);
        return [mainPart, citationsJson.citations];
      }
    } catch (error) {
      console.error("Failed to parse citations:", error);
    }

    return [mainPart, null];
  }, [content]);

  const renderToolInvocation = (toolInvocation) => {
    const { toolCallId, toolName, args, result } = toolInvocation;

    if (toolName === "generateImage") {
      if (result && result.startsWith("![DALL-E generated image]")) {
        // Extract URL from markdown format
        const urlMatch = result.match(/\((.*?)\)/);
        const imageUrl = urlMatch ? urlMatch[1] : null;

        if (imageUrl) {
          return (
            <Box key={toolCallId}>
              <Box
                component="img"
                src={imageUrl}
                alt="DALL-E Generated"
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: 1,
                  boxShadow: 1,
                  mt: 2,
                  mb: 2,
                }}
              />
            </Box>
          );
        }
      } else if (result && result.startsWith("Error:")) {
        return (
          <Alert severity="error" sx={{ mt: 1 }}>
            {result.includes("content policy violation")
              ? "The image couldn't be generated due to content policy violation. Please try a different prompt."
              : "There was an error generating the image. Please try again later."}
          </Alert>
        );
      }
    }

    // Handle other tool invocations if needed
    return null;
  };

  return (
    <>
      <MessageMarkdownMemoized
        components={{
          p: ({ children }) => (
            <Typography
              variant="body1"
              sx={{ mb: 2, "&:last-child": { mb: 0 } }}
            >
              {children}
            </Typography>
          ),
          img: ({ node, ...props }) => (
            <Box component="img" sx={{ maxWidth: "67%" }} {...props} />
          ),
          a: ({ node, ...props }) => (
            <a target="_blank" rel="noopener noreferrer" {...props} />
          ),
          code: ({ node, className, children, ...props }) => {
            const childArray = React.Children.toArray(children);
            const firstChild = childArray[0];
            const firstChildAsString = React.isValidElement(firstChild)
              ? firstChild.props.children
              : firstChild;

            const match = /language-(\w+)/.exec(className || "");

            if (
              typeof firstChildAsString === "string" &&
              !firstChildAsString.includes("\n")
            ) {
              return (
                <Typography component="code" className={className} {...props}>
                  {childArray}
                </Typography>
              );
            }

            return (
              <MessageCodeBlock
                key={Math.random()}
                language={(match && match[1]) || ""}
                value={String(childArray).replace(/\n$/, "")}
                {...props}
              />
            );
          },
        }}
        remarkPlugins={[remarkGfm, remarkMath]}
      >
        {mainContent}
      </MessageMarkdownMemoized>
      {toolInvocations && toolInvocations.map(renderToolInvocation)}
      {citationsData && citationsData.length > 0 && (
        <Box mt={2}>
          <CitationDropdown citations={citationsData} />
        </Box>
      )}
    </>
  );
};

export default MessageMarkdown;
