import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useApiWithAuth } from "@hooks/useApiWithAuth";

const ImportConfluenceModal = ({ open, handleClose, onSpaceSelect }) => {
    const [selectedSpace, setSelectedSpace] = useState('');
    const [selectedSpaceObject, setSelectedSpaceObject] = useState(null);
    const [spaces, setSpaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { apiCall } = useApiWithAuth();

    useEffect(() => {
        if (open) {
            const fetchSpaces = async () => {
                try {
                    setLoading(true);
                    console.log("Fetching spaces...");
    
                    const response = await apiCall(
                        "GET",
                        `${process.env.REACT_APP_API_MESSAGE_URL}/api/rag/confluence/fetch-spaces`
                    );
    
                    console.log("Response:", response);
    
                    if (response.success) {
                        // Assuming the response contains the list of spaces
                        console.log("Fetched spaces:", response.spaces);
                        setSpaces(response.spaces);  // Set the correct data to spaces state
                    } else {
                        console.error("Failed to fetch spaces");
                        setError('Failed to load spaces');
                    }
    
                } catch (err) {
                    console.error('Error fetching spaces', err);
                    setError('Error fetching spaces');
                } finally {
                    setLoading(false);
                }
            };
    
            fetchSpaces();
        }
    }, [open]);
    


    useEffect(() => {
        if (selectedSpace) {
            // Find the space object based on the selected space key
            const spaceObj = spaces.find(space => space.key === selectedSpace);
            setSelectedSpaceObject(spaceObj); // Update the selected space object
        }
    }, [selectedSpace, spaces]);

    const handleImport = () => {
        const selectedSpaceObject = spaces.find(space => space.key === selectedSpace);
        if (selectedSpaceObject) {
            console.log("Selected Space Object:", selectedSpaceObject);
            onSpaceSelect(selectedSpaceObject);
        } else {
            console.error('Space not found for the selected key:', selectedSpace);
        }
        handleClose();
    };


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" gutterBottom>
                    Import a Space from Confluence
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Changes you make in Confluence will be synced once a day.
                </Typography>

                <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel>Select a Space</InputLabel>
                    <Select
                        value={selectedSpace}
                        onChange={(e) => setSelectedSpace(e.target.value)}
                        label="Select a Space"
                        disabled={loading} 
                    >
                        {spaces.map(space => (
                            <MenuItem key={space.key} value={space.key}>
                                {space.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {error && <Typography color="error">{error}</Typography>}

                <Box mt={3} display="flex" justifyContent="flex-end">
                    <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleImport} variant="contained" color="primary" disabled={loading}>
                        {loading ? 'Importing...' : 'Import'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ImportConfluenceModal;