// UIContext.js
import React, { createContext, useContext, useState } from "react";
import { useThemeContext } from "@context/ThemeContext";
const UIContext = createContext();
export const useUIContext = () => useContext(UIContext);
export const UIContextProvider = ({ children }) => {
  const { darkMode } = useThemeContext();
  const [uiSettings, setUISettings] = useState({
    sidebarOpen: false,
    chatSidebarOpen: false,
    folders: [],
    chats: [],
    clientColor: darkMode ? "#FFFFFF" : "#000000",
    isColorDark: true,
  });

  const setIsColorDark = (isColorDark, client) => {
    setUISettings((prev) => ({
      ...prev,
      isColorDark: isColorDark,
      clientColor: prev.chatSidebarOpen
        ? darkMode ? "#FFFFFF" : "#000000"
        : isColorDark ? "#FFFFFF" : "#000000",
    }));
  };

  const toggleSidebar = () => {
    setUISettings((prev) => ({
      ...prev,
      chatSidebarOpen: false,
      sidebarOpen: !prev.sidebarOpen,
    }));
  };

  const closeSidebar = () => {
    setUISettings((prev) => ({
      ...prev,
      sidebarOpen: false,
    }));
  };

  const toggleChatSidebar = () => {
    setUISettings((prev) => ({
      ...prev,
      chatSidebarOpen: !prev.chatSidebarOpen,
      clientColor: !prev.chatSidebarOpen
        ? darkMode ? "#FFFFFF" : "#000000"
        : uiSettings.isColorDark ? "#FFFFFF" : "#000000",
    }));
  };

  const closeChatSidebar = () => {
    setUISettings((prev) => ({
      ...prev,
      chatSidebarOpen: false,
      clientColor: !prev.chatSidebarOpen
        ? darkMode ? "#FFFFFF" : "#000000"
        : uiSettings.isColorDark ? "#FFFFFF" : "#000000",
    }));
  };

  const addFolder = (folder) => {
    setUISettings((prev) => ({
      ...prev,
      folders: [...prev.folders, folder],
    }));
  };

  const setFolders = (folders) => {
    setUISettings((prev) => ({
      ...prev,
      folders: folders,
    }));
  };

  return (
    <UIContext.Provider
      value={{
        uiSettings,
        setUISettings,
        toggleChatSidebar,
        toggleSidebar,
        closeSidebar,
        closeChatSidebar,
        addFolder,
        setFolders,
        setIsColorDark,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
