import React, { useState, useEffect, useRef, useCallback } from "react";
import { useApiWithAuth } from "@hooks/useApiWithAuth";
import Post from "./Post";
import { Box, useMediaQuery, Dialog, DialogContent, Typography, IconButton, CircularProgress } from "@mui/material";
import { useUser } from "@context/UserContext";
import { Close as CloseIcon } from "@mui/icons-material";
import UploadPost from "./UploadPost";
import { useAuth } from "@/context/AuthContext";
import { Skeleton } from "@mui/material";

// Utility debounce function to optimize play/pause logic
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const PostsList = () => {
  const { apiCall } = useApiWithAuth();
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const videoRefs = useRef({});
  const observerRef = useRef(null);
  const loadingRef = useRef(null);

  const { user, isAdmin } = useUser();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchPosts = async (pageNum = 1) => {
    if (!hasMore && pageNum !== 1) return;

    try {
      setIsLoadingMore(pageNum !== 1);
      const postResponse = await apiCall(
        "GET",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/posts?email=${user.email}&page=${pageNum}&limit=5`
      );

      const newPosts = postResponse.posts;
      setPosts(prevPosts => {
        const updatedPosts = pageNum === 1 ? newPosts : [...prevPosts, ...newPosts];
        return updatedPosts.filter((post, index, self) =>
          index === self.findIndex((t) => t.id === post.id)
        );
      });
      setHasMore(postResponse.pagination.hasMore);
      setPage(pageNum);
    } catch (err) {
      console.error("Error fetching posts:", err);
      setError("Failed to fetch posts.");
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !isLoadingMore) {
      fetchPosts(page + 1);
    }
  }, [hasMore, page, isLoadingMore]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    observerRef.current = new IntersectionObserver(handleObserver, option);
    if (loadingRef.current) observerRef.current.observe(loadingRef.current);
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [handleObserver]);

  const handleDelete = useCallback((postId) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  }, []);

  // Observer callback to pause non-visible videos and play the visible one
  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      const videoElement = entry.target;
      if (entry.isIntersecting) {
        videoElement.play().catch((error) => console.error("Error playing video:", error));
      } else {
        videoElement.pause();
        videoElement.currentTime = 0; // Stop streaming in the background
      }
    });
  };

  // Set up IntersectionObserver
  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleIntersect, {
      threshold: 0.75, // Start playing when 75% visible
    });

    // Change this part
    Object.values(videoRefs.current).forEach((videoRef) => {
      if (videoRef.current) {
        observerRef.current.observe(videoRef.current);
      }
    });

    return () => {
      observerRef.current.disconnect(); // Clean up observer on unmount
    };
  }, [posts]);

  const handleArchive = (postId) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
  };

  // Handle invalid media URLs
  const isValidMediaUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const ids = posts.map((post) => post.id);
    const hasDuplicates = ids.length !== new Set(ids).size;
    if (hasDuplicates) {
      console.error("Duplicate post IDs found:", ids);
    } else {
      console.log("All post IDs are unique:", ids);
    }
  }, [posts]);

  const renderSkeleton = () => (
    <Box
      className="post-item-skeleton"
      sx={{
        scrollSnapAlign: 'center',
        width: isDesktop ? '400px' : '100%',
        marginBottom: isDesktop ? '50px' : '0',
      }}
    >
      <Box
        sx={{
          position: "relative",
          scrollSnapAlign: "center",
          overflow: "hidden",
          opacity: 1,
          transition: "opacity .3s ease-in-out",
          borderRadius: isDesktop ? 20 : 0,
          overflow: 'hidden',
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={isDesktop ? '711px' : '100dvh'}
            animation="wave"
            sx={{ objectFit: "cover" }}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box
      className="posts-container"
      sx={{
        overflowY: isDesktop ? 'unset' : 'scroll',
        display: isDesktop ? 'flex' : 'block',
        flexDirection: isDesktop ? 'column' : 'unset',
        alignItems: isDesktop ? 'center' : 'unset',
        scrollSnapType: isDesktop ? 'none' : 'y mandatory',
        width: isDesktop ? '400px' : '100vw',
        height: isDesktop ? 'auto' : '100dvh',
        "& .post-item": {
          marginBottom: isDesktop ? '30px' : '0',
        },
      }}
    >
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ marginBottom: '0px' }}>
              Upload Your Post
            </Typography>
            <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 10 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <UploadPost user={user} handleClose={handleClose} refreshPosts={() => fetchPosts(1)} />
        </DialogContent>
      </Dialog>

      {isLoading ? (
        Array.from(new Array(5)).map((_, index) => (
          <Box key={index}>{renderSkeleton()}</Box>
        ))
      ) : (
        posts.map((post, index) => {
          if (!videoRefs.current[post.id]) {
            videoRefs.current[post.id] = React.createRef();
          }
          return (
            <Box
              className="post-item"
              key={`${post.id}-${index}`}
              sx={{
                scrollSnapAlign: 'center',
              }}
            >
              <Post
                title={post.title}
                liked={post.liked}
                description={post.description}
                postId={post.id}
                userUserId={post.user_id}
                email={user.email}
                postOwnerEmail={post.email}
                media_url={isValidMediaUrl(post.media_url) ? post.media_url : ""}
                profileImage={post.profile_image}
                name={post.name}
                timestamp={post.created_at}
                initialLikes={post.likes_count}
                mediaType={post.media_type}
                videoRef={videoRefs.current[post.id]}
                onArchive={() => handleArchive(post.id)}
                onDelete={handleDelete}
                handleClickOpen={handleClickOpen}
                user={user}
                isAdmin={isAdmin}
              />
            </Box>
          );
        })
      )}

      {isLoadingMore && (
        <Box sx={{ mt: 2, mb: 2 }}>
          {renderSkeleton()}
        </Box>
      )}

      {hasMore && <Box ref={loadingRef} style={{ height: 20 }} />}

      {!hasMore && posts.length > 0 && (
        <Typography sx={{ textAlign: 'center', p: 2 }}>No more posts to load</Typography>
      )}

      {error && <Typography sx={{ textAlign: 'center', p: 2, color: 'error.main' }}>{error}</Typography>}
    </Box>
  );
};

export default PostsList;