import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ConfluenceModal = ({ 
  confluenceModalOpen, 
  setConfluenceModalOpen, 
  selectedAssistant 
}) => {
  const [sources, setSources] = useState([]); // Sources fetched from API
  const [newSource, setNewSource] = useState(''); // New source input

  // Simulate API call to fetch assistant sources (replace with actual API call)
  const fetchSourcesForAssistant = async (assistantId) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // Simulated data (replace with actual data from API)
        resolve([
          'Source 1',
          'Source 2',
          'Source 3'
        ]);
      }, 1000);
    });
  };

  // Fetch sources when modal opens and selectedAssistant is set
  useEffect(() => {
    if (confluenceModalOpen && selectedAssistant) {
      const fetchSources = async () => {
        const fetchedSources = await fetchSourcesForAssistant(selectedAssistant.id);
        setSources(fetchedSources);
      };

      fetchSources();
    }
  }, [confluenceModalOpen, selectedAssistant]);

  // Handle adding a new source
  const handleAddSource = () => {
    if (newSource.trim()) {
      setSources((prevSources) => [...prevSources, newSource]);
      setNewSource(''); // Clear the input after adding
    }
  };

  // Handle deleting a source
  const handleDeleteSource = (index) => {
    setSources((prevSources) => prevSources.filter((_, i) => i !== index));
  };

  // Handle modal close
  const handleClose = () => {
    setConfluenceModalOpen(false);
  };

  // Handle save action
  const handleSave = () => {
    console.log("Sources to save:", sources);
    // Implement save logic here (likely send updated sources to API)
    handleClose();
  };

  return (
    <Modal open={confluenceModalOpen} onClose={handleClose}>
      <Box sx={{ 
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        {/* Assistant Title */}
        <Typography variant="h6" gutterBottom>
          {selectedAssistant?.name}
        </Typography>

        {/* Add New Source Input */}
        <TextField
          fullWidth
          label="Add New Source"
          value={newSource}
          onChange={(e) => setNewSource(e.target.value)}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddSource} sx={{ mb: 2 }}>
          Add Source
        </Button>

        {/* List of Current Sources */}
        {sources.length > 0 ? (
          <List>
            {sources.map((source, index) => (
              <ListItem key={index}>
                <ListItemText primary={source} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSource(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No sources found for this assistant.
          </Typography>
        )}

        {/* Cancel and Save Buttons */}
        <Box mt={4} display="flex" justifyContent="end">
          <Button variant="outlined" color="secondary" sx={{mx:1}} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" sx={{mx:1}} onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfluenceModal;
