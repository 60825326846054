import React from "react";
import {
  Box,
  Container,
  Typography,
  Snackbar,
  IconButton,
  Card,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useParams, useLocation } from "react-router-dom";
import { Close as CloseIcon } from "@mui/icons-material";
import Header from "@/components/chatgpt/Header";
import { MessageList } from "@/components/chatgpt/MessageList";
import InputArea from "@/components/chatgpt/InputArea";
import FilePreview from "@/components/chatgpt/FilePreview";
import useChatLogic from "@/hooks/useChatLogic";
import useFileHandling from "@/hooks/useFileHandling";
import { useThemeContext } from "@context/ThemeContext";
import ShareLinkDialog from "@/components/common/ShareChatLinkDialog";
import { useMsal } from "@azure/msal-react";

const ChatGPT = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { darkMode } = useThemeContext();
  const { clientId, chatId } = useParams();
  const location = useLocation();
  const taskItem = location.state?.taskItem || null;
  const { accounts } = useMsal();

  const {
    selectedFile,
    setSelectedFile,
    handleRemoveFile,
    showFilePreview,
    fileInputRef,
    setShowFilePreview,
    isFileTypeAllowed,
  } = useFileHandling();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (isUsingAssistant && !isFileTypeAllowed(file)) {
        setSnackbarMessage(
          "This file type is not allowed. Please select a valid file type.",
        );
        setSnackbarOpen(true);
      } else {
        setSelectedFile(file);
        setShowFilePreview(true);
      }
    }
    // Clear the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    // Reset the input value to ensure onChange fires for the same file
    e.target.value = "";
  };

  const {
    messages,
    input,
    handleInputChange,
    handleSendMessage,
    handleNewChat,
    handleOpenShareDialog,
    handleCloseShareLinkDialog,
    status,
    chatName,
    currentClientObject,
    assistantLoading,
    loadingMessage,
    permissionLevel,
    loading,
    shareLinkDialogOpen,
    isUsingAssistant,
    isMessageStreaming,
    setUseAzureContext,
    useAzureContext,
  } = useChatLogic(clientId, chatId, taskItem, accounts, {
    selectedFile,
    handleFileChange,
    handleRemoveFile,
    showFilePreview,
    fileInputRef,
    setShowFilePreview,
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleCardClick = (prompt) => {
    handleInputChange({ target: { value: prompt } });
    setTimeout(() => {
      const sendButton = document.querySelector("#send-button");
      if (sendButton) {
        sendButton.click();
      } else {
        console.error("Send button not found.");
      }
    }, 10);
  };

  return (
    <Box
      className="fade-in"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 0px)",
      }}
    >
      <Header
        chatName={loading ? " " : chatName}
        currentClientObject={currentClientObject}
        darkMode={currentClientObject?.isColorDark ?? false}
        handleNewChat={handleNewChat}
        opacity={loading ? 0 : 1}
        handleOpenShareDialog={handleOpenShareDialog}
        chatId={chatId}
      />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "25%",
          maxHeight: "150px",
          background: darkMode
            ? "linear-gradient(to top, #121212 50%, rgba(0, 0, 0, 0))"
            : "linear-gradient(to top, #ffffff 50%, rgba(255, 255, 255, 0))",
          pointerEvents: "none",
          zIndex: 1,
        }}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
          marginTop: "60px",
          opacity: loading ? 0 : 1,
          transition: "all .2s ease-in-out",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          ></Box>
        ) : messages.length === 0 ? (
          <Box
            className="fade-in"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
              padding: 0,
              marginTop: 10,
              height: "50vh",
              //marginLeft: !isSmallScreen ? "-30px" : 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: isSmallScreen ? "90%" : "75%",
                maxWidth: "100%",
                height: "auto",
                padding: "0px",
              }}
            >
              {taskItem ? (
                <Box
                  className="fade-in"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {taskItem.thumbnailUrl && (
                    <Avatar
                      src={taskItem.thumbnailUrl}
                      style={{
                        width: "75px",
                        height: "75px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: 2,
                      textAlign: "center",
                      fontWeight: "bold",
                      userSelect: "none",
                    }}
                  >
                    {taskItem.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      textAlign: "center",
                      color: theme.palette.mode === "dark" ? "#bbb" : "#666",
                      userSelect: "none",
                    }}
                  >
                    By: {taskItem.creator}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 2,
                      textAlign: "center",
                      opacity: 0.8,
                      userSelect: "none",
                    }}
                  >
                    {taskItem.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 2,
                      textAlign: "center",
                      opacity: 0.8,
                      userSelect: "none",
                    }}
                  >
                    {taskItem.instructions}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 2,
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "stretch",
                    }}
                  >
                    {taskItem.prompts &&
                      taskItem.prompts.map((prompt, index) => (
                        <Card
                          key={index}
                          onClick={() => handleCardClick(prompt)}
                          sx={{
                            boxShadow: "none",
                            m: 1,
                            maxWidth: "250px",
                            minWidth: "150px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "30px",
                            backgroundColor: "transparent",
                            border: `1px solid ${theme.palette.border.main}`,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body2" color="secondary">
                            {prompt}
                          </Typography>
                        </Card>
                      ))}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {currentClientObject && (
                    <Box
                      className="fade-in"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        variant="rounded"
                        sx={{ width: 75, height: 75, borderRadius: "6px" }}
                        src={currentClientObject.logo}
                      />
                      <Typography
                        variant="h5"
                        sx={{
                          marginTop: 2,
                          textAlign: "center",
                          fontWeight: "bold",
                          userSelect: "none",
                        }}
                      >
                        {currentClientObject
                          ? `${currentClientObject.name}${currentClientObject.name !== 'ChatGPT' ? ' ChatGPT' : ''}`
                          : "Nucleus ChatGPT"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: 1,
                          textAlign: "center",
                          color:
                            theme.palette.mode === "dark" ? "#bbb" : "#666",
                          userSelect: "none",
                        }}
                      >
                        Start a new chat below
                      </Typography>
                    </Box>
                  )}
                  {/*<Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 2,
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "stretch",
                      }}
                    >
                       {[
                        "How can I help you today?",
                        "What would you like to know?",
                        "Ask me anything!",
                      ].map((prompt, index) => (
                        <Card
                          key={index}
                          onClick={() => handleCardClick(prompt)}
                          sx={{
                            boxShadow: "none",
                            m: 1,
                            maxWidth: "250px",
                            minWidth: "150px",
                            padding: "10px 20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "30px",
                            backgroundColor: "transparent",
                            border: `1px solid ${theme.palette.border.main}`,
                            cursor: "pointer",
                          }}
                        >
                          <Typography variant="body2" color="secondary">
                            {prompt}
                          </Typography>
                        </Card>
                      ))}
                    </Box> */}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <MessageList
            messages={messages}
            darkMode={currentClientObject?.isColorDark ?? false}
            isSmallScreen={isSmallScreen}
            assistantLoading={assistantLoading}
            loadingMessage={loadingMessage}
            isMessageStreaming={isMessageStreaming}
            chatId={chatId}
          />
        )}

        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: isSmallScreen ? 0 : "60px",
            right: 0,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 2,
              paddingRight: 2,
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: 700,
              left: "50%",
              transform: "translateX(-50%)",
              flexDirection: "column",
            }}
          >
            {showFilePreview && selectedFile && (
              <FilePreview file={selectedFile} onRemove={handleRemoveFile} />
            )}
            <InputArea
              input={input}
              handleInputChange={handleInputChange}
              handleSendMessage={handleSendMessage}
              handleFileChange={handleFileChange}
              darkMode={darkMode}
              status={status}
              fileInputRef={fileInputRef}
              permissionLevel={permissionLevel}
              useAzureContext={useAzureContext}
              setUseAzureContext={setUseAzureContext}
            />
          </Box>
          <Typography
            fontSize={13}
            color="textSecondary"
            sx={{
              alignItems: "center",
              textAlign: "center",
              padding: "10px",
              userSelect: "none",
            }}
          >
            All content is secure within our data model.
          </Typography>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <ShareLinkDialog
        open={shareLinkDialogOpen}
        onClose={handleCloseShareLinkDialog}
        chatId={chatId}
        clientId={clientId}
      />
    </Box>
  );
};
export default ChatGPT;
