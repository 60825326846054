import React from 'react';
import { useThemeContext } from '../../context/ThemeContext'; // Import the custom hook

const Divider = () => {
  const { darkMode } = useThemeContext();
  const borderColor = darkMode ? '#293038' : 'rgba(0, 0, 0, 0.12)';
  return <hr className="my-2" style={{ borderTop: `1px solid ${borderColor}` }} />;
};

export default Divider;
