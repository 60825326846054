import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";

const InboxDialog = ({ open, onClose, invitations, onInvitationResponse }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Chat Invitations</DialogTitle>
      <DialogContent>
        {invitations.length === 0 ? (
          <DialogContentText>No pending invitations.</DialogContentText>
        ) : (
          <List>
            {invitations.map((invitation) => (
              <ListItem key={invitation.id}>
                <ListItemText
                  primary={`Invitation from ${invitation.sender_email}`}
                  secondary={
                    <>
                      {`Chat ID: ${invitation.chat_id}`}
                      <br />
                      {`Chat Name: ${invitation.chat_name}`}
                      <br />
                      {`Client ID: ${invitation.client_id}`}
                      <br />
                      {`${formatDistanceToNow(new Date(invitation.created_at))} ago`}
                    </>
                  }
                />
                <Button
                  onClick={() => onInvitationResponse(invitation.token, true)}
                  color="primary"
                >
                  Accept
                </Button>
                <Button
                  onClick={() => onInvitationResponse(invitation.token, false)}
                  color="secondary"
                >
                  Decline
                </Button>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InboxDialog;
