// src/AuthContext.js
import React, { createContext, useContext } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { instance, accounts } = useMsal();

  const handleLogin = async () => {
    try {
      // Clear any existing interactions first
      if (instance.getActiveAccount() === null) {
        await instance.handleRedirectPromise();
        if (!instance.getAllAccounts().length) {
          await instance.loginRedirect(loginRequest);
        }
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  }, [accounts, instance]);

  const acquireToken = async (account = instance.getActiveAccount(), type = 'id', forceRefresh = false) => {
    await instance.initialize();
    try {
      const silentRequest = {
        scopes: ["User.Read", "User.ReadBasic.All"],
        account: account || instance.getActiveAccount(),
        forceRefresh: forceRefresh
      };

      const response = await instance.acquireTokenSilent(silentRequest);
  
      if (type === 'access') {
        return response.accessToken;
      }
  
      return response.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.warn("Silent token acquisition failed, acquiring token using redirect");
        await instance.acquireTokenRedirect({
          scopes: ["User.Read", "User.ReadBasic.All"],
        });
      } else {
        console.error("Token acquisition failed:", error);
        throw error;
      }
    }
  };
 
  return (
    <AuthContext.Provider value={{ acquireToken, handleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
