import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "21d36729-4ddd-4a95-a635-114a5a2152da",
    authority:
      "https://login.microsoftonline.com/5430cd13-2ad3-4008-9a33-9d08166aea70",
      redirectUri:
      process.env.NODE_ENV === "production"
        ? `${window.location.protocol}//${window.location.hostname}`
        : process.env.REACT_APP_REDIRECT_URI_LOCALHOST,
    navigateToLoginRequestUrl: false,
  },
  system: {
    loadFrameTimeout: 3000,
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         break;
    //       case LogLevel.Info:
    //         console.info(message);
    //         break;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         break;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         break;
    //     }
    //   },
    //   piiLoggingEnabled: false,
    //   logLevel: LogLevel.Verbose,
    // },
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "User.ReadBasic.All", "openid", "profile", "offline_access"],
};

export const tokenRequest = {
  scopes: ["User.Read", "User.ReadBasic.All", "openid", "profile", "offline_access"],
};

export const silentRequest = {
  scopes: ["User.Read", "User.ReadBasic.All", "openid", "profile", "offline_access"],
  forceRefresh: false,
};

export const checkTokenExpiry = (msalInstance) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const account = accounts[0];

    msalInstance.acquireTokenSilent({ ...silentRequest, account })
      .then(response => {
        console.log('Token acquired silently:', response.accessToken);
      })
      .catch(error => {
        console.error('Silent token acquisition failed:', error);

        if (error instanceof msal.InteractionRequiredAuthError) {
          console.log('Interactive login required.');
          msalInstance.acquireTokenPopup(tokenRequest)
            .then(response => {
              console.log('Token acquired after interaction:', response.accessToken);
            })
            .catch(innerError => {
              console.error('Interactive token acquisition failed:', innerError);
            });
        } else {
          console.error('Other error during silent token acquisition:', error);
        }
      });
  } else {
    console.warn('No accounts found, unable to acquire token.');
    msalInstance.loginRedirect(loginRequest);
  }
};

export const startTokenExpiryCheck = (msalInstance) => {
  setInterval(() => checkTokenExpiry(msalInstance), 300000);
};

export const sharePointRequest = {
  scopes: ["https://fusion92.sharepoint.com/.default"]
};

let msalInstance = null;

export const initializeMsal = () => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
    return msalInstance.initialize();
  }
  return Promise.resolve();
};

export const getMsalInstance = () => {
  if (!msalInstance) {
    throw new Error("MSAL instance has not been initialized. Call initializeMsal first.");
  }
  return msalInstance;
};

export const authenticateSharePoint = async (forceRefresh = false) => {
    const instance = getMsalInstance();
    try {
      const accounts = instance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
  
      const silentRequest = {
        scopes: ["https://fusion92.sharepoint.com/.default"],
        account: accounts[0],
        forceRefresh: forceRefresh
      };
  
      const silentResult = await instance.acquireTokenSilent(silentRequest);
      return {
        accessToken: silentResult.accessToken,
        tokenType: silentResult.tokenType // Usually "Bearer"
      };
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const interactiveResult = await instance.acquireTokenPopup(sharePointRequest);
          return {
            accessToken: interactiveResult.accessToken,
            tokenType: interactiveResult.tokenType
          };
        } catch (err) {
          console.error("Error during interactive authentication:", err);
          throw err;
        }
      } else {
        console.error("Error during silent authentication:", error);
        throw error;
      }
    }
  };