import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import WebIcon from "@mui/icons-material/Language";
import SyncIcon from "@mui/icons-material/Sync";
import SharePointIcon from "@mui/icons-material/Cloud";

const AddSourceMenu = ({
  anchorEl,
  open,
  onClose,
  onUploadDocuments,
  onImportWebsite,
  onSyncConfluence,
  onSyncSharePoint,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 8,
        sx: {
          padding: 2,
          borderRadius: 2,
          width: 350,
        },
      }}
    >
      <MenuItem
        onClick={onSyncSharePoint}
        sx={{ py: 2, borderBottom: 1, borderColor: "divider" }}
      >
        <ListItemIcon>
          <SharePointIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Sync Sites from SharePoint"
          secondary="Add SharePoint sites"
        />
      </MenuItem>
      <MenuItem
        onClick={onImportWebsite}
        sx={{ py: 2, borderBottom: 1, borderColor: "divider" }}
      >
        <ListItemIcon>
          <WebIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Import a Website"
          secondary="Import and sync a website's content"
        />
      </MenuItem>
      <MenuItem
        onClick={onSyncConfluence}
        sx={{ py: 2, borderBottom: 1, borderColor: "divider" }}
      >
        <ListItemIcon>
          <SyncIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Sync Spaces from Confluence"
          secondary="Add Confluence spaces"
        />
      </MenuItem>
    </Menu>
  );
};

export default AddSourceMenu;
