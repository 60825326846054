import React from "react";
import { Fab } from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";

const BugReportFab = ({ onClick }) => {
  return (
    <Fab
      color="secondary"
      aria-label="report bug"
      sx={{
        position: "fixed",
        bottom: 90,
        right: 20,
        zIndex: 5300,
      }}
      onClick={onClick}
    >
      <BugReportIcon />
    </Fab>
  );
};

export default BugReportFab;
