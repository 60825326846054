import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/styles/tailwind.css";
import { msalConfig } from "./msalConfig";
import store from "./store";
import '@mdi/font/css/materialdesignicons.min.css';

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize()
  .then(() => {
    root.render(
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    );
  })
  .catch(error => {
    console.error("MSAL Initialization Error:", error);
  });