import { useState, useRef } from "react";

const useFileHandling = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const fileInputRef = useRef(null);

  const allowedFileTypes = {
    ".c": "text/x-c",
    ".cs": "text/x-csharp",
    ".cpp": "text/x-c++",
    ".doc": "application/msword",
    ".docx":
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".html": "text/html",
    ".java": "text/x-java",
    ".json": "application/json",
    ".md": "text/markdown",
    ".pdf": "application/pdf",
    ".php": "text/x-php",
    ".pptx":
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".py": "text/x-python",
    ".rb": "text/x-ruby",
    ".tex": "text/x-tex",
    ".txt": "text/plain",
    ".css": "text/css",
    ".js": "text/javascript",
    ".sh": "application/x-sh",
    ".ts": "application/typescript",
    ".xls": "application/vnd.ms-excel",
    ".xlsx":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".csv": "text/csv",
  };

  const isFileTypeAllowed = (file) => {
    const fileExtension = "." + file.name.split(".").pop().toLowerCase();
    const fileMimeType = file.type;

    return Object.entries(allowedFileTypes).some(
      ([ext, mime]) =>
        (fileExtension === ext && fileMimeType === mime) ||
        fileMimeType === mime,
    );
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setShowFilePreview(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return {
    selectedFile,
    showFilePreview,
    fileInputRef,
    handleRemoveFile,
    isFileTypeAllowed,
    setShowFilePreview,
    setSelectedFile,
    isFileTypeAllowed,
  };
};

export default useFileHandling;
