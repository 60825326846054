import React from 'react';
import { Box, Chip, Card, CardContent, CardMedia, Typography, Link, CardActionArea, Skeleton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CustomAvatar from "./CustomAvatar";
import { useThemeContext } from '../../context/ThemeContext';

const PriorityCard = ({ brand, title, body, updatedAt, owner, url, profileImage, imageUrls, loading, phase }) => {
  const { darkMode, theme } = useThemeContext();

  return (
    <Box className="fade-in" sx={{ width: '100%', height: '100%' }}>
      <Card 
        className='fade-in'
        elevation={0}
        sx={{ 
          boxShadow: 0,
          border: `1px solid ${theme.palette.border.main}`,
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          borderRadius: theme.shape.borderRadius,
          transition: 'all 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'scale(1.03)',
            boxShadow: 10,
            borderRadius: 10,
          }
        }}
      >
        {loading ? (
          <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              {/* <Skeleton variant="rounded" width={50} height={50} /> */}
              <Skeleton variant="rectangular" width="60%" height={30} sx={{ ml: 0 }} />
            </Box>
            <hr style={{ 
                width: '100%', 
                border: 'none', 
                borderBottom: darkMode
                ? '1px dashed rgba(255,255,255,.25)'
                : '1px dashed rgba(0,0,0,.25)',
                margin: '10px 0' 
              }} />
            <Box sx={{ flexGrow: 1, minHeight: '61px', }}>
              <Skeleton variant="rectangular" width="100%" height={20} />
              <Skeleton variant="rectangular" width="100%" height={20} />
              <Skeleton variant="rectangular" width="75%" height={20} />
            </Box>


          </CardContent>
        ) : (
          <CardActionArea 
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }} 
            component="a" 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <CardContent sx={{ alignSelf: 'stretch', flexGrow: 1 }} >
              <Box display='flex' flexDirection='row' alignItems='center' >
                {/* <CustomAvatar 
                  className="fade-in" 
                  name={title} 
                  darkMode={darkMode} 
                  borderRadius={50}
                  width={50} 
                  height={50} 
                  src={imageUrls ? imageUrls : ""}
                /> */}
                <Typography variant="h6" mb={0} mt={0}>
                  {title}
                </Typography>
              </Box>
              <Box sx={{ mt: 'auto' }}>
                <hr style={{ 
                  width: '100%', 
                  border: 'none', 
                  borderBottom: darkMode
                  ? '1px dashed rgba(255,255,255,.25)'
                  : '1px dashed rgba(0,0,0,.25)',
                  margin: '10px 0' 
                }} />
               
              </Box>
              <Box sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                minHeight: '61px',
              }}>
                <Typography 
                  variant="body2" 
                  dangerouslySetInnerHTML={{ __html: body }} 
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    textOverflow: 'ellipsis',
                    flexGrow: 1
                  }}
                />
              </Box>
              
            </CardContent>
          </CardActionArea>
        )}
      </Card>
    </Box>
  );
};

export default PriorityCard;
