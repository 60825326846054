import React, { useState } from "react";
import {
  IconButton,
  Box,
  Typography,
  CircularProgress,
  Badge,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  DeleteOutline,
  Share as ShareIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useApiWithAuth } from "@hooks/useApiWithAuth";

const PostActions = ({
  postId,
  email,
  postOwnerEmail,
  initialLikes,
  liked: initialLiked,
  isArchived,
  onDelete,
  handleClickOpen,
  darkMode,
  postView = false,
  isAdmin,
}) => {
  const { apiCall } = useApiWithAuth();
  const [likes, setLikes] = useState(initialLikes || 0);
  const [liked, setLiked] = useState(initialLiked || false);
  const [archiving, setArchiving] = useState(false);
  const [archived, setArchived] = useState(isArchived || false);

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/social/post/${postId}`;

    try {
      await navigator.share({
        title: "Check out this post",
        text: "Check out this interesting post",
        url: shareUrl,
      });
    } catch (err) {
      console.error("Error sharing:", err);
    }
  };

  const handleLike = async () => {
    console.log("PostActions.js: handleLike: liked: ", liked);
    setLikes(likes + (liked ? -1 : 1));
    setLiked(!liked);
    try {
      await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/likes`,
        {
          postId,
          email,
        },
      );
    } catch (error) {
      console.error("Error liking the post:", error);
    }
  };

  const handleDelete = async () => {
    setArchiving(true);
    try {
      await apiCall(
        "POST",
        `${process.env.REACT_APP_API_MESSAGE_URL}/api/social/archivePost`,
        {
          postId,
          email,
        },
      );
      setArchived(true);
      onDelete(postId);
    } catch (error) {
      console.error("Error archiving the post:", error);
    } finally {
      setArchiving(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 0,
      }}
    >
      {!postView && (
        <IconButton
          onClick={handleClickOpen}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            boxShadow: 0,
            mb: 2,
          }}
        >
          <AddIcon fontSize="24px" />
        </IconButton>
      )}

      <IconButton onClick={handleLike}>
        {liked ? (
          <Favorite sx={{ fontSize: 30, color: "red" }} />
        ) : (
          <FavoriteBorder sx={{ fontSize: 30 }} />
        )}
      </IconButton>
      <Typography
        sx={{ mt: -0.5, userSelect: "none", cursor: "default" }}
        variant="body2"
        color="textPrimary"
      >
        {likes === 1 ? `${likes} Like` : `${likes} Likes`}
      </Typography>

      <IconButton onClick={handleShare} sx={{ mt: 2 }}>
        <ShareIcon sx={{ fontSize: 30 }} />
      </IconButton>

      {!archived && (isAdmin || postOwnerEmail === email) && (
        <IconButton
          onClick={handleDelete}
          disabled={archiving}
          sx={{
            mt: 2,
          }}
        >
          {archiving ? (
            <CircularProgress size={30} />
          ) : (
            <DeleteOutline sx={{ fontSize: 30, color: "textPrimary" }} />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default PostActions;
